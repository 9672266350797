var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"size":"xxl","show":true,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("change_customer.header_change_project_customer"))+" ")]),_c('template',{slot:"default"},[_c('FormulateForm',{attrs:{"form-errors":_vm.formErrors},model:{value:(_vm.payload),callback:function ($$v) {_vm.payload=$$v},expression:"payload"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('FormulateInput',{attrs:{"type":"select","options":_vm.filteredCustomers,"name":"customer_id","validation-name":_vm.$t('project.new_customer'),"label":_vm.$t('project.new_customer'),"placeholder":_vm.$t('project.placeholder_new_customer'),"validation":"required"}})],1),_c('div',{staticClass:"col-6 d-flex align-items-center justify-content-center"},[(parseInt(_vm.matchedDiscount) > 0)?[_vm._v(" "+_vm._s(_vm.$t( "change_customer.this_customer_has_discount_percent" ))+": "+_vm._s(_vm.$n(_vm.matchedDiscount))+"% ")]:[_vm._v(" "+_vm._s(_vm.$t( "change_customer.this_customer_has_no_discount" ))+" ")]],2)]),(_vm.project !== null && 'users' in _vm.project)?[_c('h3',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("change_customer.project_members"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "change_customer.help_text_review_prices_before_converting" ))+" ")]),(false)?_c('pre',[_vm._v(_vm._s(_vm.payload))]):_vm._e(),(_vm.payload.users.length > 0)?_c('div',_vm._l((_vm.payload.users),function(user,index){return _c('div',{key:user.id,staticClass:"row d-flex align-items-center change-customer-user-row"},[_c('div',{staticClass:"col-3",attrs:{"data-user-id":user.id}},[_vm._v(" "+_vm._s(user.name)+" "),(
                                    'users' in _vm.errors &&
                                    index in _vm.errors.users
                                )?_c('div',{staticClass:"alert alert-danger alert-outline p-2"},[_vm._v(" "+_vm._s(_vm.$t( "change_customer.error_check_entries_data_wrong" ))+" ")]):_vm._e()]),_c('div',{staticClass:"col-3"},[_c('FormulateInput',{attrs:{"type":"currency","currency":_vm.project.currency,"label":_vm.$t('project.default_hour_price'),"validation-name":_vm.$t('project.default_hour_price')},model:{value:(
                                    _vm.payload['users'][index][
                                        'default_hour_price'
                                    ]
                                ),callback:function ($$v) {_vm.$set(_vm.payload['users'][index], 
                                        'default_hour_price'
                                    , $$v)},expression:"\n                                    payload['users'][index][\n                                        'default_hour_price'\n                                    ]\n                                "}})],1),_c('div',{staticClass:"col-3"},[_c('FormulateInput',{attrs:{"type":"groupdecimal","append":"%","label":_vm.$t('project.discount_percent'),"validation-name":_vm.$t('project.discount_percent'),"validation":"required|number|between:-1,101"},model:{value:(
                                    _vm.payload['users'][index][
                                        'discount_percent'
                                    ]
                                ),callback:function ($$v) {_vm.$set(_vm.payload['users'][index], 
                                        'discount_percent'
                                    , $$v)},expression:"\n                                    payload['users'][index][\n                                        'discount_percent'\n                                    ]\n                                "}})],1),_c('div',{staticClass:"col-3"},[_c('FormulateInput',{attrs:{"type":"currency","currency":_vm.project.currency,"label":_vm.$t('project.effective_hour_price'),"validation-name":_vm.$t('project.effective_hour_price'),"disabled":true},model:{value:(
                                    _vm.payload['users'][index]['hour_price']
                                ),callback:function ($$v) {_vm.$set(_vm.payload['users'][index], 'hour_price', $$v)},expression:"\n                                    payload['users'][index]['hour_price']\n                                "}})],1)])}),0):_vm._e()]:_vm._e(),_c('div',{staticClass:"text-right mt-5"},[_c('FormulateErrors'),_c('base-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("change_customer.cancel")))]),_c('base-button',{attrs:{"type":"primary","disabled":isNaN(parseInt(_vm.payload.customer_id))},on:{"click":_vm.saveForm}},[_vm._v(_vm._s(_vm.$t("change_customer.change_customer")))])],1)],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }