<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import VReopenDayForm from "@/components/Forms/VReopenDayForm";
import VTaskForm from "@/components/Forms/VTaskForm";
import VTaskTable from "@/components/Task/VTaskTable";
import VSystemTask from "@/components/Task/VSystemTask";
import InnerDashCard from "@/components/Cards/InnerDashCard";
import { EventBus } from "@/libraries/EventBus";
import TaskService from "@/services/TaskService";
import DayAttrs from "@/libraries/DayAttrs";

export default {
    name: "VHoursToday",
    components: {
        InnerDashCard,
        VTaskTable,
        VTaskForm,
        VSystemTask,
        VReopenDayForm
    },
    data() {
        return {
            updatingFromEvent: false,
            showReopenDayModal: false,
            showTaskModal: false,
            selectedDate: null,
            loadingTop: false,
            loadingDay: false,
            dayMeta: {},
            tasks: [],
            highlightData: [],
            showTarget: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser"]),
        // Today for init on the form
        today: function() {
            return dayjs().format("YYYY-MM-DD");
        },
        // Warning for future date
        // futureDateSelected: function () {
        //     return dayjs(this.task.task_date).isAfter(this.today, "day");
        // },
        regularTasks: function() {
            if (this.tasks.length == 0) {
                return [];
            }

            let tasks = [];

            this.tasks.forEach(t => {
                if (t.task_type == 1) {
                    tasks.push(t);
                }
            });

            return tasks;
        },
        systemTasks: function() {
            if (this.tasks.length == 0) {
                return [];
            }

            let tasks = [];

            this.tasks.forEach(t => {
                if (t.task_type == 9) {
                    tasks.push(t);
                }
            });

            return tasks;
        }
    },
    watch: {
        selectedDate: async function(nDate) {
            await this.fetchDay(nDate);
            this.$router
                .replace({
                    name: "Dashboard",
                    query: { showDate: nDate }
                })
                .catch(() => {});
            // ^^^^^^^^^^^ catch -> not fab, but hids the exception Navigation Duplicate

            // Sync with qregs
            if (!this.updatingFromEvent) {
                console.log(
                    "VHoursToday Task date change: EMIT",
                    nDate,
                    this.updatingFromEvent
                );
                EventBus.$emit("qreg-date-change", nDate);
            }
            this.updatingFromEvent = false;
        }
    },
    async created() {
        if (this.$route.query.showDate) {
            this.selectedDate = this.$route.query.showDate;
        } else {
            this.selectedDate = this.today;
        }
        // selectedDate -> Watch -> fetchDay() called from there.
        //await this.fetchDay(this.selectedDate);
        EventBus.$on("task-created", this.updateDay);
        EventBus.$on("task-deleted", this.updateDay);
        EventBus.$on("task-edited", this.updateDay);
        EventBus.$on("qreg-date-change", this.fixQregDate);
    },
    beforeDestroy() {
        EventBus.$off("qreg-date-change");
        EventBus.$off("task-created");
        EventBus.$off("task-deleted");
        EventBus.$off("task-edited");
    },
    methods: {
        /**
         * Update from the event bus
         */
        fixQregDate(date) {
            this.updatingFromEvent = true;
            this.selectedDate = date;
        },
        goalCount(total, goal) {
            return parseInt(goal) - parseInt(total);
        },
        updateDay() {
            this.fetchDay(this.selectedDate);
        },
        async fetchDay(date) {
            try {
                console.log("fetchDay", date);
                this.loadingTop = true;
                const r = await TaskService.GET_dayStats(date);
                this.dayMeta = r.data.meta;
                this.tasks = r.data.data;
            } catch (e) {
                console.log("fetchDay exception", e);
            }

            this.loadingTop = false;
        },
        /**
         * Fetch attrs for the calendar
         */
        async fetchAttrs(a) {
            this.highlightData = await DayAttrs.eventHandler(a);
        },
        /**
         * Save a task
         */
        closeSaveTaskModal(task) {
            this.showTaskModal = false;
            let idx = this.tasks.findIndex(t => {
                return t.id == task.id;
            });
            this.$set(this.tasks, idx, task);
            this.fetchDay(this.selectedDate);
            EventBus.$emit("task-edited", task.id);
        },
        closeSaveReopenModal() {
            this.showReopenDayModal = false;
            this.fetchDay(this.selectedDate);
        },
        /**
         * Request reopening of a given day
         */
        async reopenDay() {
            console.log("Reopen");
            this.showReopenDayModal = true;
        },
        async closeEmptyDay() {
            let precheck;

            try {
                const r = await TaskService.GET_precheckGoalForClosing(
                    this.selectedDate
                );

                precheck = r.data.data;

                this.$swal({
                    icon: precheck.can_close ? "warning" : "error",
                    iconHtml: precheck.can_close
                        ? null
                        : '<i class="fas fa-exclamation"></i>',
                    text: precheck.can_close
                        ? this.$t("dashboard.do_you_want_to_close_the_day")
                        : this.$t(
                              "dashboard.do_you_want_to_close_the_day_and_you_are_X_from_your_goal",
                              {
                                  X: this.$options.filters.toTime(
                                      precheck.missing_time
                                  )
                              }
                          ),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            /// COnfirm
                            const r = await TaskService.PUT_closeDay(
                                this.selectedDate
                            );
                            await this.fetchDay(this.selectedDate);
                            EventBus.$emit("refresh-dashboard");
                        } catch (e) {
                            console.log("closeDay exception", e);
                        }
                    }
                });
            } catch (e) {
                console.log("closeDay(): precheck exception", e);
            }
        },
        /**
         * Close a given day
         */
        async closeDay() {
            let precheck;

            try {
                const r = await TaskService.GET_precheckGoalForClosing(
                    this.selectedDate
                );

                precheck = r.data.data;

                this.$swal({
                    icon: precheck.can_close ? "warning" : "error",
                    iconHtml: precheck.can_close
                        ? null
                        : '<i class="fas fa-exclamation"></i>',
                    text: precheck.can_close
                        ? this.$t("dashboard.do_you_want_to_close_the_day")
                        : this.$t(
                              "dashboard.do_you_want_to_close_the_day_and_you_are_X_from_your_goal",
                              {
                                  X: this.$options.filters.toTime(
                                      precheck.missing_time
                                  )
                              }
                          ),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            /// COnfirm
                            const r = await TaskService.PUT_closeDay(
                                this.selectedDate
                            );
                            await this.fetchDay(this.selectedDate);
                            EventBus.$emit("refresh-dashboard");
                        } catch (e) {
                            console.log("closeDay exception", e);
                        }
                    }
                });
            } catch (e) {
                console.log("closeDay(): precheck exception", e);
            }
        },
        isFuture(date) {
            return dayjs(date).isAfter(this.today, "day");
        }
    }
};
</script>

<template>
    <div class="v-hours-today row">
        <div class="col-12">
            <card body-classes="px-0">
                <div class="px-4">
                    <div class="d-flex align-items-center mb-3">
                        <h3 class="mb-0">
                            <i class="far fa-clock"></i>
                            {{ $t("dashboard.hour_details") }}
                        </h3>
                        <div class="ml-3">
                            <date-picker
                                class=""
                                @update:from-page="fetchAttrs"
                                v-model="selectedDate"
                                :model-config="{
                                    type: 'string',
                                    mask: 'YYYY-MM-DD'
                                }"
                                :is-required="true"
                                :attributes="highlightData"
                                :popover="{
                                    positionFixed: true,
                                    visibility: 'focus',
                                    keepVisibleOnInput: false,
                                    transition: 'none',
                                    showDelay: 1,
                                    hideDelay: 1
                                }"
                            >
                                <template v-slot="{ togglePopover }">
                                    <div
                                        class="v-hours-today__datepicker m-click"
                                        @click="
                                            togglePopover({
                                                placement: 'bottom'
                                            })
                                        "
                                    >
                                        {{
                                            $d(new Date(selectedDate), "short")
                                        }}
                                        <i class="ml-1 fas fa-caret-down"></i>
                                    </div>
                                </template>
                            </date-picker>
                        </div>
                        <div class="v-dash-close-btn" v-if="false">
                            <i class="far fa-times fa-lg"></i>
                        </div>

                        <div
                            class="ml-4 text-uppercase"
                            :class="
                                dayMeta.workday == 0
                                    ? 'text-warning'
                                    : 'text-primary'
                            "
                        >
                            <i
                                class="far fa-fw mr-2"
                                :class="
                                    dayMeta.workday
                                        ? 'far fa-lock-open'
                                        : 'far fa-lock'
                                "
                            ></i>

                            <span v-if="dayMeta.workday" class="text-primary">
                                <template v-if="dayMeta.workday == 1">{{
                                    $t("dashboard.partially_open")
                                }}</template>
                                <template v-if="dayMeta.workday == 2">{{
                                    $t("dashboard.open")
                                }}</template>
                            </span>

                            <span v-else class="text-warning">{{
                                $t("dashboard.closed")
                            }}</span>
                        </div>
                        <div class="ml-auto d-flex">
                            <base-button
                                v-if="
                                    tasks.length > 0 &&
                                        dayMeta.workday != 0 &&
                                        !isFuture(selectedDate)
                                "
                                @click="closeDay"
                                :outline="true"
                                type="warning"
                                size="sm"
                                ><i class="far fa-lock"></i>
                                {{ $t("dashboard.close_day") }}</base-button
                            >
                            <base-button
                                v-else-if="
                                    tasks.length === 0 &&
                                        dayMeta.workday != 0 &&
                                        !isFuture(selectedDate)
                                "
                                @click="closeEmptyDay"
                                :outline="true"
                                type="danger"
                                size="sm"
                                ><i class="far fa-lock"></i>
                                {{
                                    $t("dashboard.close_empty_day")
                                }}</base-button
                            >
                            <base-button
                                v-if="dayMeta.workday == 0"
                                :disabled="dayMeta.reopen"
                                @click="reopenDay"
                                :outline="true"
                                :type="dayMeta.reopen ? 'gray' : 'danger'"
                                size="sm"
                            >
                                <template v-if="dayMeta.reopen"
                                    ><i class="far fa-envelope"></i>
                                    {{
                                        $t("dashboard.reopen_request_sent")
                                    }}</template
                                >
                                <template v-else
                                    ><i class="far fa-lock-open-alt"></i>
                                    {{ $t("dashboard.request_open") }}</template
                                ></base-button
                            >

                            <base-button
                                v-if="true"
                                @click="showTaskModal = true"
                                :outline="true"
                                type="primary"
                                size="sm"
                                ><i class="far fa-plus"></i>
                                {{
                                    $t("dashboard.register_activity")
                                }}</base-button
                            >
                        </div>
                    </div>

                    <v-loader :loading="true" v-if="loadingTop" />
                    <div class="row" v-else>
                        <div
                            class="col-12 col-md-6 col-lg-3"
                            @mouseenter="showTarget = true"
                            @mouseleave="showTarget = false"
                        >
                            <inner-dash-card
                                :title="$t('dashboard.statbox_total_time')"
                                icon="far fa-stopwatch"
                                ><template slot="default"
                                    ><span
                                        class=""
                                        :class="{
                                            'text-warning': dayMeta.to_goal > 0,
                                            'text-default': dayMeta.to_goal <= 0
                                        }"
                                        >{{ dayMeta.total_time | toTime }}</span
                                    ></template
                                >
                                <template slot="action">
                                    <template
                                        v-if="parseInt(dayMeta.period_goal) > 0"
                                    >
                                        <span
                                            v-if="!showTarget"
                                            class=""
                                            :class="{
                                                'text-warning':
                                                    dayMeta.to_goal > 0,
                                                'text-success':
                                                    dayMeta.to_goal <= 0
                                            }"
                                            >{{ $t("dashboard.goal") }}:

                                            <template
                                                v-if="dayMeta.to_goal == 0"
                                            >
                                                {{ 0 | toTime }}
                                            </template>
                                            <template
                                                v-else-if="dayMeta.to_goal < 0"
                                            >
                                                +{{ dayMeta.to_goal | toTime }}
                                            </template>
                                            <template v-else>
                                                -{{ dayMeta.to_goal | toTime }}
                                            </template>
                                        </span>
                                        <span v-else
                                            >{{ $t("dashboard.daily_goal") }}:

                                            {{
                                                dayMeta.period_goal | toTime
                                            }}</span
                                        ></template
                                    >
                                </template></inner-dash-card
                            >
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="
                                    $t(
                                        'dashboard.statbox_customer_related_time'
                                    )
                                "
                                icon="far fa-building"
                                ><template slot="default"
                                    ><span class="text-default">{{
                                        dayMeta.total_ext_time | toTime
                                    }}</span></template
                                >
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{ $n(dayMeta.total_ext_time_perc) }}%
                                        {{ $t("dashboard.of_total") }}</span
                                    >
                                </template></inner-dash-card
                            >
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="$t('dashboard.statbox_internal_time')"
                                icon="far fa-house"
                                ><template slot="default"
                                    ><span class="text-default">{{
                                        dayMeta.total_int_time | toTime
                                    }}</span></template
                                >
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{ $n(dayMeta.total_int_time_perc) }}%
                                        {{ $t("dashboard.of_total") }}</span
                                    >
                                </template></inner-dash-card
                            >
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="$t('dashboard.other_time')"
                                icon="far fa-user-slash"
                            >
                                <template slot="default">
                                    <span class="text-default">{{
                                        dayMeta.total_other_time | toTime
                                    }}</span>
                                </template>
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{
                                            $n(dayMeta.total_other_time_perc)
                                        }}% {{ $t("dashboard.of_total") }}</span
                                    >
                                </template>
                            </inner-dash-card>
                        </div>
                    </div>
                </div>

                <template v-if="systemTasks.length > 0">
                    <div class="row ml-2 mr-2 mb-4 d-flex">
                        <div
                            class="col mb-2"
                            v-for="st in systemTasks"
                            :key="st.id"
                        >
                            <v-system-task
                                :context="st"
                                :meta="dayMeta"
                                @refresh-meta="fetchDay(selectedDate)"
                            />
                        </div>
                    </div>
                </template>

                <v-task-table
                    :tasks="regularTasks"
                    @refresh-meta="fetchDay(selectedDate)"
                />
            </card>
        </div>
        <portal to="shortcut-modal">
            <v-task-form
                v-if="showTaskModal"
                :context="{ empty: true }"
                @close="showTaskModal = false"
                @save="closeSaveTaskModal"
                :prefill-task-date="selectedDate"
            />
            <v-reopen-day-form
                v-if="showReopenDayModal"
                :selected-date="selectedDate"
                @close="showReopenDayModal = false"
                @save="closeSaveReopenModal"
            />
        </portal>
    </div>
</template>

<style lang="scss"></style>
