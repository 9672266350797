<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VInfoCard from "@/components/Cards/VInfoCard";
import TravelService from "@/services/TravelService";
import InvoiceService from "@/services/InvoiceService";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import Cloner from "@/libraries/Cloner";

import Messages from "@/mixins/Messages";
import DateRangeMixin from "@/mixins/DateRangeMixin";

export default {
    name: "VInvoiceActivityLineForm",
    props: {
        invoiceId: {
            required: true,
            description: "Invoice ID"
        },
        projectId: {
            required: true,
            description: "Project ID - to get travel entries"
        },
        currency: {
            type: String,
            required: true,
            description: "Currency"
        },
        hasMilestones: {
            type: Boolean,
            default: false,
            description: "Whether the project has enabled milestones"
        }
    },
    components: { VInfoCard, DateRangePicker },
    mixins: [Messages, DateRangeMixin],
    data() {
        return {
            filterMilestone: "",
            milestones: [],
            debug: "",
            loading: false,
            creating: false,
            errors: null,
            dateRange: {
                startDate: null,
                endDate: null
            },
            lineConfig: [
                {
                    label: this.$t("invoice_line.option_detailed_travel"),
                    value: "detailed"
                },
                {
                    label: this.$t("invoice_line.option_squashed_travel"),
                    value: "squashed"
                }
            ],
            km_price: null,
            travels: [],
            selectedTravels: [],
            options: {
                line_config: "detailed", // true -> 1 line, false -> task per line
                include_squash_details: false,
                travel_fields: {
                    milestone: false,
                    invoice_text: true,
                    travel_date: true,
                    kms: true,
                    user: false
                }
            },
            invoiceLine: {
                qty: null,
                price: null,
                price_subtotal: null,
                invoice_id: null,
                discount_perc: null,
                invoice_line: null,
                meta_key: "travel"
            }
        };
    },
    computed: {
        filteredMilestones() {
            let m = [];
            m.push({
                value: "",
                label: this.$t("invoice_line.filter_all_no_milestones")
            });
            m.push(...this.milestones);
            return m;
        },
        line_config: function() {
            return this.options.line_config;
        },
        /**
         * Sum of selected task time
         */
        selectedKms: function() {
            if (this.selectedTravels.length == 0) {
                return 0;
            }

            let kms = 0;
            this.selectedTravels.forEach(idx => {
                kms += this.travels[idx].kms;
            });

            return kms;
        },
        /**
         * Sum of selected task prices
         */
        selectedAmount: function() {
            if (this.selectedTravels.length == 0) {
                return 0;
            }

            let sum = 0;
            this.selectedTravels.forEach(idx => {
                sum += this.travels[idx].travel_price * 100;
            });

            return sum / 100;
        },
        /**
         * checkbox sel/unsel all
         */
        selectAll: {
            get: function() {
                return this.travels
                    ? this.selectedTravels.length == this.travels.length
                    : false;
            },

            set: function(v) {
                let selected = [];

                if (v) {
                    this.travels.forEach(function(v, index) {
                        selected.push(index);
                    });
                }

                this.selectedTravels = selected;
            }
        },
        qty: function() {
            return this.invoiceLine.qty;
        },
        price: function() {
            return this.invoiceLine.price;
        },
        discount_perc: function() {
            return this.invoiceLine.discount_perc;
        }
    },
    watch: {
        filterMilestone: function(n) {
            this.fetchTravels();
        },
        dateRange: function(n) {
            this.fetchTravels();
        },
        qty: function(n) {
            this.updateSubtotal();
        },
        price: function(n) {
            this.updateSubtotal();
        },
        discount_perc: function(n) {
            this.updateSubtotal();
        }
    },
    async created() {
        this.invoiceLine.invoice_id = this.invoiceId;

        if (this.hasMilestones) {
            await this.fetchMilestones();
            this.extendOptions();
        }
        await this.fetchTravels();
    },
    methods: {
        extendOptions() {
            this.lineConfig.push({
                label: this.$t("invoice_line.option_grouped_milestones"),
                value: "grouped_milestones"
            });
            this.options.travel_fields.milestone = true;
        },
        /**
         * Fetch milestones
         */
        async fetchMilestones() {
            try {
                const {
                    data
                } = await ProjectMilestoneService.GET_invoicingDropdown(
                    this.projectId
                );
                this.milestones = data.data;
            } catch (e) {
                console.log("fmi e", e);
            }
        },
        async saveForm() {
            let data = {
                options: Cloner.clone(this.options),
                invoice_id: this.invoiceId,
                travels: this.selectedTravelsToIds()
            };

            if (this.options.line_config == "squashed") {
                data["invoiceLine"] = Cloner.clone(this.invoiceLine);
            }

            console.log("Data", data);

            try {
                this.creating = true;
                console.log("saveForm", data);
                const r = await InvoiceService.POST_fromTravels(data);
                // We get back here just OK confirmation if everything is OK
                // as the result might be several lines -> invoice component will
                // re-render everything.
                this.$emit("save", null);
            } catch (e) {
                console.log("saveDetailed exception", e);
                if ("errors" in e.response.data) {
                    if ("invoiceLine" in e.response.data.errors) {
                        this.errors = e.response.data.errors.invoiceLine;
                    } else {
                        this.errors = null;
                    }
                }
            }

            console.log("DataAFTER", data);

            this.creating = false;
        },
        selectedTravelsToIds() {
            let ids = [];
            this.selectedTravels.forEach(elem => {
                ids.push(this.travels[elem].id);
            });

            return ids;
        },
        updateSubtotal() {
            let qty = this.invoiceLine.qty;
            let price = this.invoiceLine.price * 100;

            if (isNaN(qty) || isNaN(price)) {
                return;
            }

            if (this.invoiceLine.discount_perc > 0) {
                price = (price * (100 - this.invoiceLine.discount_perc)) / 100;
                //d = p - p*% = p(1 - %)
            }

            let subTotal = (qty * price) / 100;

            if (isNaN(subTotal)) {
                return;
            }

            subTotal = Math.round(subTotal * 100) / 100;

            console.log("Subtotal", subTotal);
            this.invoiceLine.price_subtotal = subTotal;
        },
        async fetchTravels() {
            try {
                this.loading = true;
                const r = await TravelService.GET({
                    project_id: this.projectId,
                    is_billable: 1,
                    is_invoiced: 0,
                    dateRange: this.serializeDateRange(this.dateRange),
                    force_all: 1,
                    ...(this.hasMilestones
                        ? { project_milestone_id: this.filterMilestone }
                        : {})
                });

                this.travels = r.data.data;
                this.selectedTravels = [];
            } catch (e) {
                console.log("fetchTasks exception", e);
            }
            this.loading = false;
        },
        /**
         * Copy selected amount to the invoiceLine form
         */
        copyAmount() {
            this.invoiceLine.price = this.selectedAmount / this.selectedKms;
            this.invoiceLine.qty = this.selectedKms;
        },
        /**
         * Reset date-range
         */
        resetRange() {
            this.dateRange = {
                startDate: null,
                endDate: null
            };
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        size="full"
        :show="true"
        :show-close="false"
        @close="$emit('close')"
        class="invoice-modal"
    >
        <template slot="header">
            <div class="align-items-center d-flex flex-grow-1">
                {{ $t("project.new_travel_invoice_line") }}

                <div class="ml-auto">
                    <base-button @click="$emit('close')" type="muted">{{
                        $t("project.cancel")
                    }}</base-button>
                    <base-button
                        :loading="creating"
                        type="primary"
                        @click="saveForm"
                        :disabled="selectedTravels.length == 0 || creating"
                        >{{ $t("project.save") }}
                        <i class="fas fa-chevron-right"></i
                    ></base-button>
                </div>
            </div>
        </template>
        <template slot="default">
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="form-control-label">{{
                                    $t("invoice_line.date_range")
                                }}</label>
                                <div>
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange('dateRange')
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                    <span
                                        class="text-muted small m-click m-link"
                                        @click="resetRange"
                                        ><i class="far fa-sync"></i>
                                        {{
                                            $t("invoice_line.reset_range")
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <FormulateInput
                                type="select"
                                :label="$t('invoice_line.invoice_view')"
                                :options="lineConfig"
                                v-model="options.line_config"
                            />
                        </div>
                    </div>
                    <!-- .row -->
                    <div class="row" v-if="hasMilestones">
                        <!-- Milestones -->
                        <div class="col">
                            {{ filterMilestone }}
                            <FormulateInput
                                type="select"
                                :label="$t('invoice_line.milestone')"
                                :options="filteredMilestones"
                                v-model="filterMilestone"
                            />
                        </div>
                    </div>
                </div>
                <!-- .col-6 -->
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <v-info-card
                                :title="
                                    $t('invoice_line.selected_travel_entries')
                                "
                            >
                                <template slot="default">{{
                                    selectedTravels.length
                                }}</template>
                                <template slot="action">{{
                                    $t("invoice_line.count")
                                }}</template>
                            </v-info-card>
                        </div>
                        <div class="col">
                            <v-info-card
                                :title="$t('invoice_line.selected_total_kms')"
                            >
                                <template slot="default">{{
                                    selectedKms
                                }}</template>
                                <template slot="action">{{
                                    $t("invoice_line.kilometers")
                                }}</template>
                            </v-info-card>
                        </div>
                        <div class="col">
                            <v-info-card
                                :title="$t('invoice_line.selected_amount')"
                            >
                                <template slot="default">{{
                                    $n(selectedAmount, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "code"
                                    })
                                }}</template>
                                <template
                                    slot="action"
                                    v-if="options.line_config == 'squashed'"
                                >
                                    <span @click="copyAmount" class="m-click">
                                        <i class="far fa-copy"></i>
                                        {{
                                            $t(
                                                "invoice_line.copy_amount_and_kms"
                                            )
                                        }}
                                    </span>
                                </template>
                            </v-info-card>
                        </div>
                    </div>
                </div>
                <!-- .col-6 -->
            </div>

            <hr />

            <template>
                <FormulateForm
                    v-if="options.line_config == 'squashed'"
                    v-model="invoiceLine"
                    @submit="saveForm"
                    :errors="errors"
                >
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <FormulateInput
                                type="multiline"
                                name="invoice_line"
                                :label="$t('invoice_line.description')"
                                validation="required"
                            />
                        </div>
                        <div class="col-12 col-md-1">
                            <FormulateInput
                                name="qty"
                                type="groupdecimal"
                                :label="$t('invoice_line.quantity')"
                                :precision="2"
                                align="right"
                                validation="required"
                            />
                        </div>
                        <div class="col-12 col-md-2">
                            <FormulateInput
                                name="price"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.unit_price')"
                                validation="required"
                            />
                        </div>

                        <div class="col-12 col-md-2">
                            <FormulateInput
                                name="discount_perc"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.discount_in_perc')"
                                validation="required"
                            />
                        </div>
                        <div class="col">
                            <FormulateInput
                                name="price_subtotal"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.total')"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </FormulateForm>

                <div class="row">
                    <div class="col-12">
                        <template
                            v-if="
                                options.line_config == 'squashed' ||
                                    options.line_config == 'grouped_milestones'
                            "
                        >
                            <input
                                type="checkbox"
                                v-model="options.include_squash_details"
                            />
                            <span class="text-bold ml-2">{{
                                $t(
                                    "invoice_line.include_activity_details_in_invoice_text"
                                )
                            }}</span>
                        </template>
                        <template v-else>
                            <span class="text-bold"
                                >{{
                                    $t(
                                        "invoice_line.configure_task_details_in_invoice_lines"
                                    )
                                }}
                            </span>
                        </template>
                        <div
                            class="d-flex justify-content-start mt-2"
                            v-if="
                                options.include_squash_details ||
                                    options.line_config == 'detailed'
                            "
                        >
                            <div
                                class="mr-4"
                                v-if="
                                    hasMilestones &&
                                        options.line_config !=
                                            'grouped_milestones'
                                "
                            >
                                <input
                                    type="checkbox"
                                    v-model="options.travel_fields.milestone"
                                />
                                {{ $t("invoice_line.milestone") }}
                            </div>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.travel_fields.invoice_text"
                                    :disabled="true && !hasMilestones"
                                />
                                {{ $t("invoice_line.invoice_text") }}
                            </div>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.travel_fields.travel_date"
                                />
                                {{ $t("invoice_line.date") }}
                            </div>
                            <div
                                class="mr-4"
                                v-if="options.line_config != 'detailed'"
                            >
                                <input
                                    type="checkbox"
                                    v-model="options.travel_fields.kms"
                                />
                                {{ $t("invoice_line.kms") }}
                            </div>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.travel_fields.user"
                                />
                                {{ $t("invoice_line.employee") }}
                            </div>
                        </div>
                        <hr class="mt-5" />
                    </div>
                </div>
            </template>

            <!-- <div class="row">
                <div class="col">
                    <pre style="font-size: 12px;">{{ debug }}</pre>

                </div>
            </div>  -->

            <v-loader :loading="loading" v-if="loading" />
            <template v-else>
                <h3 class="mt-3">
                    {{ $t("invoice_line.select_travel_activities") }}
                </h3>

                <div class="table-responsive mt-3" v-if="travels.length > 0">
                    <v-table class="table table-mg table-hover">
                        <v-thead>
                            <v-tr>
                                <v-th>
                                    <input
                                        type="checkbox"
                                        v-model="selectAll"
                                    />
                                </v-th>
                                <v-th>{{ $t("invoice_line.date") }}</v-th>
                                <v-th v-if="hasMilestones">{{
                                    $t("invoice_line.milestone")
                                }}</v-th>
                                <v-th>{{
                                    $t("invoice_line.invoice_text")
                                }}</v-th>
                                <v-th>{{ $t("invoice_line.user") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("invoice_line.km")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("invoice_line.amount")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr
                                v-for="(travel, index) in travels"
                                :key="travel.id"
                            >
                                <v-td>
                                    <input
                                        type="checkbox"
                                        :value="index"
                                        v-model="selectedTravels"
                                    />
                                </v-td>
                                <v-td>{{
                                    $d(new Date(travel.travel_date), "short")
                                }}</v-td>
                                <v-td v-if="hasMilestones">
                                    <template
                                        v-if="'project_milestone' in travel"
                                        >{{
                                            travel.project_milestone.name
                                        }}</template
                                    >
                                    <template v-else>---</template>
                                </v-td>
                                <v-td>
                                    {{ travel.invoice_text }}
                                </v-td>
                                <v-td>
                                    {{ travel.user.name }}
                                </v-td>
                                <v-td class="text-right">
                                    {{ $n(travel.kms) }} km
                                </v-td>

                                <v-td class="text-right">{{
                                    $n(travel.travel_price, {
                                        style: "currency",
                                        currency: travel.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
                <div class="alert alert-warning" v-else>
                    {{
                        $t(
                            "invoice_lines.no_travel_entries_found_in_the_selected_date_range"
                        )
                    }}
                </div>
            </template>
        </template>
    </modal>
</template>

<style></style>
