<script>
import VFilters from "@/components/Search/VFilters";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import AppCfg from "@/mixins/AppCfg";
import DateRangeMixin from "@/mixins/DateRangeMixin";

import InvoiceService from "@/services/InvoiceService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import ProjectService from "@/services/ProjectService";

export default {
    name: "VInvoices",
    mixins: [DateRangeMixin, AppCfg],
    components: { VFilters, DateRangePicker },
    data() {
        return {
            loading: false,
            invoices: [],
            meta: {},
            customers: [],
            cases: [],
            statuses: [],
            users: [],
        };
    },
    async created() {
        this.$aclGuard(this.$ACL.TIMEREG_MGMT);
        this.loading = true;
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchCustomers(true);
        await this.fetchUsers(true);
        await this.fetchProjects(true);
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["includes"] = "project";
                const r = await InvoiceService.GET_projectsOverview(params);
                this.invoices = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log("fetchData E", e);
            }

            this.loading = false;
        },
        async resetSearch() {
            this.apiSearchFilter = {
                search: "",
                status: [0, 2],
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };

            this.apiPaginator = {
                direction: "asc",
                limit: null,
                page: null,
                sort: "Invoices.invoice_date",
            };
        },
        /**
         * Fetch dropdown data
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {
                console.log("fetchCustomers E", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch projects
         */
        async fetchProjects(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GET_dropdown({
                    status: [1, 5],
                    project_model: [1, 2],
                });
                this.cases = r.data.data;
            } catch (e) {
                console.log("fetchProjects E", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers E", e);
            }

            this.loading = loading;
        },
    },
};
</script>

<template>
    <div class="v-reports-invoices">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <!-- .top-menu -->
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- VFILTERS -->
                        <div class="px-4">
                            <v-filters
                                :search="true"
                                :start-open="true"
                                v-model="apiSearchFilter.search"
                                @reset-search="resetSearch"
                            >
                                <template slot="default">
                                    <div class="col-12 col-md-4">
                                        <date-range-picker
                                            class="range-picker"
                                            ref="picker"
                                            opens="right"
                                            placeholder="Bananer"
                                            :timePicker="false"
                                            :showWeekNumbers="true"
                                            :autoApply="true"
                                            v-model="apiSearchFilter.dateRange"
                                            :linkedCalendars="true"
                                            :locale-data="
                                                getDateRangePickerLocale()
                                            "
                                            :ranges="getDateRangePickerRanges()"
                                        >
                                            <template #header>
                                                <manual-range
                                                    :date-range-manual="
                                                        dateRangeManual
                                                    "
                                                    :date-range-error="
                                                        dateRangeError
                                                    "
                                                    @exec="
                                                        execManualRange(
                                                            'apiSearchFilter',
                                                            'dateRange'
                                                        )
                                                    "
                                                />
                                            </template>
                                            <template
                                                v-slot:input="picker"
                                                style="min-width: 350px"
                                            >
                                                <template
                                                    v-if="picker.startDate"
                                                >
                                                    {{
                                                        $d(
                                                            picker.startDate,
                                                            "short"
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $d(
                                                            picker.endDate,
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                                <span
                                                    class="text-muted"
                                                    v-else
                                                    >{{
                                                        $t(
                                                            "invoices.placeholder_select_date_range"
                                                        )
                                                    }}</span
                                                >
                                            </template>
                                        </date-range-picker>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            :placeholder="
                                                $t('invoices.customer')
                                            "
                                            v-model="
                                                apiSearchFilter.customer_id
                                            "
                                            type="multiselect"
                                            :multiple="true"
                                            :options="customers"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            :placeholder="$t('invoices.case')"
                                            v-model="apiSearchFilter.project_id"
                                            type="multiselect"
                                            :multiple="true"
                                            :options="cases"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            :placeholder="
                                                $t('invoices.responsible_users')
                                            "
                                            v-model="apiSearchFilter.user_id"
                                            type="multiselect"
                                            :multiple="true"
                                            :options="users"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            :placeholder="$t('invoices.status')"
                                            v-model="apiSearchFilter.status"
                                            type="multiselect"
                                            :multiple="true"
                                            :options="
                                                appCfgRead(
                                                    'Invoices.status',
                                                    true
                                                )
                                            "
                                        />
                                    </div>
                                </template>
                            </v-filters>
                        </div>
                        <!-- .VFILTERS -->
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="invoices.length > 0">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Projects.title"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.project_title"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Invoices.created"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.created_date"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Invoices.status"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_status"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Invoices.invoice_header"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_header"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Invoices.invoice_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_deadline"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Invoices.total_net_amount"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_amount"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="i in invoices" :key="i.id">
                                            <v-td
                                                >{{ i.customer.name }}
                                                <div class="small text-muted">
                                                    {{ i.project.user_name }}
                                                </div>
                                            </v-td>
                                            <v-td
                                                >{{ i.project.title }}
                                                <div class="small text-muted">
                                                    {{
                                                        i.project
                                                            .project_model_name
                                                    }}
                                                </div>
                                            </v-td>
                                            <v-td>{{
                                                $d(new Date(i.created), "short")
                                            }}</v-td>
                                            <v-td>{{ i.status_name }}</v-td>
                                            <v-td
                                                ><v-empty
                                                    :text="i.invoice_header"
                                                />
                                                <div
                                                    class="small text-muted"
                                                    v-if="i.bkeeper_message"
                                                >
                                                    {{ i.bkeeper_message }}
                                                </div>
                                            </v-td>
                                            <v-td>
                                                <template v-if="i.invoice_date">
                                                    {{
                                                        $d(
                                                            new Date(
                                                                i.invoice_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                                <span v-else>---</span>
                                            </v-td>
                                            <v-td class="text-right">{{
                                                $n(i.total_net_amount, {
                                                    style: "currency",
                                                    currency: i.currency,
                                                    currencyDisplay: "code",
                                                })
                                            }}</v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="d-flex justify-content-center mt-4">
                                <div class="paginator">
                                    <base-pagination
                                        :page-count="
                                            meta.pagination.total_pages
                                        "
                                        :per-page="meta.pagination.per_page"
                                        :value="meta.pagination.current_page"
                                        :total="meta.pagination.total"
                                        @input="$handlePagination($event)"
                                    />
                                </div>
                            </div>
                        </template>
                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{
                                    $t("invoices.report_project_no_data_found")
                                }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>
