<script>
import { mapGetters } from "vuex";
import VFilters from "@/components/Search/VFilters";
import VTravelForm from "@/components/Forms/VTravelForm";
import TravelService from "@/services/TravelService";
import ProjectService from "@/services/ProjectService";

export default {
    name: "VTransport",
    props: {
        project_id: {
            description: "Project ID from the navigation"
        }
    },
    components: {
        VFilters,
        VTravelForm
    },
    data() {
        return {
            loading: false,
            project: null,
            travels: [],
            meta: {},
            travelModal: false,
            editContext: null,
            showNote: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"])
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);
        this.apiPaginator.sort = "travel_date";
        this.apiPaginator.direction = "desc";
        this.apiSearchFilter = { search: "" };
        this.$apiEnableWatcher();
        await this.fetchProject();
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                const r = await TravelService.GET(params);
                this.travels = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log("TRAVEL", e);
            }
            this.loading = false;
        },
        /**
         * Fetch the project (mainly for settings)
         */
        async fetchProject(loading = true) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
            this.loading = loading;
        },
        /**
         * Set editing context
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.travelModal = true;
        },
        closeSave() {
            this.travelModal = false;
            this.fetchData();
        },
        /**
         * Show note
         */
        doShowNote(ctx) {
            this.showNote = true;
            this.showNoteContext = ctx;
        },
        /**
         * HIde note
         */
        doHideNote() {
            this.showNoteContext = null;
            this.showNote = false;
        }
    }
};
</script>

<template>
    <div class="v-transport">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="$t('travels.search')"
                    >
                        <template slot="left">
                            <base-button
                                type="primary"
                                :outline="true"
                                @click="setContext({ empty: true })"
                            >
                                <i class="far fa-plus"></i>
                                {{ $t("travels.add") }}</base-button
                            >
                        </template>
                    </v-filters>
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="travels.length">
                            <div class="table-responsive">
                                <v-table
                                    class="table table-mg table-hover table-sm"
                                >
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="travel_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.travel_date")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Users.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.user")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                v-if="project.enable_milestones"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="ProjectMilestones.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.milestone")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoice_text"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "travel.invoice_text"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="notes"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "travel.internal_notes"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class=""
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="is_private_car"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.private_car")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class=""
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="is_billable"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.billable")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="kms"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.km")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right">{{
                                                $t("travel.amount")
                                            }}</v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="is_invoiced"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel.is_invoiced")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th class="text-right"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="t in travels" :key="t.id">
                                            <v-td>{{
                                                $d(
                                                    new Date(t.travel_date),
                                                    "short"
                                                )
                                            }}</v-td>

                                            <v-td>{{ t.user.name }}</v-td>
                                            <v-td
                                                v-if="project.enable_milestones"
                                            >
                                                <template
                                                    v-if="
                                                        'project_milestone' in t
                                                    "
                                                >
                                                    {{
                                                        t.project_milestone.name
                                                    }}
                                                </template>
                                                <template v-else>---</template>
                                            </v-td>
                                            <v-td>
                                                {{ t.invoice_text }}
                                            </v-td>
                                            <v-td class="text-center">
                                                <i
                                                    class="far fa-comment-alt-lines fa-lg m-click"
                                                    @click="doShowNote(t.notes)"
                                                    v-if="t.notes"
                                                ></i>
                                                <i
                                                    class="far fa-comment-alt-slash text-muted fa-lg"
                                                    v-else
                                                ></i>
                                            </v-td>
                                            <v-td>
                                                <template
                                                    v-if="t.is_private_car"
                                                    >{{
                                                        $t(
                                                            "travel.yes_private_car"
                                                        )
                                                    }}</template
                                                >
                                                <template v-else>{{
                                                    $t("travel.no_company_car")
                                                }}</template>
                                            </v-td>
                                            <v-td>
                                                <template
                                                    v-if="t.is_billable"
                                                    >{{
                                                        $t("travel.billable")
                                                    }}</template
                                                >
                                                <template v-else>{{
                                                    $t("travel.non_billable")
                                                }}</template>
                                            </v-td>
                                            <v-td class="text-right">
                                                {{ $n(t.kms) }} km
                                            </v-td>
                                            <v-td class="text-right">
                                                <template
                                                    v-if="t.travel_price > 0"
                                                >
                                                    <template
                                                        v-if="t.is_billable"
                                                    >
                                                        {{
                                                            $n(t.travel_price, {
                                                                style:
                                                                    "currency",
                                                                currency:
                                                                    project.currency,
                                                                currencyDisplay:
                                                                    "code"
                                                            })
                                                        }}
                                                        <div
                                                            class="small text-muted"
                                                        >
                                                            km =
                                                            {{
                                                                $n(
                                                                    t.base_price,
                                                                    {
                                                                        style:
                                                                            "currency",
                                                                        currency:
                                                                            project.currency,
                                                                        currencyDisplay:
                                                                            "code"
                                                                    }
                                                                )
                                                            }}
                                                        </div>
                                                    </template>
                                                    <template v-else
                                                        >---</template
                                                    >
                                                </template>
                                                <span
                                                    class="text-danger"
                                                    v-else
                                                >
                                                    <i
                                                        class="far fa-exclamation-triangle"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "travel.missing_km_price"
                                                        )
                                                    }}
                                                </span>
                                            </v-td>
                                            <v-td>
                                                {{ t.is_invoiced_name }}
                                            </v-td>
                                            <v-td
                                                class="text-right"
                                                v-if="
                                                    ACL_RW ||
                                                        t.user_id == getMyId
                                                "
                                            >
                                                <i
                                                    class="far fa-ban fa-lg"
                                                    v-if="t.is_invoiced == 1"
                                                ></i>
                                                <i
                                                    v-else
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="setContext(t)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <modal v-if="showNote" size="lg" :show="true" @close="doHideNote">
                <template slot="header">
                    {{ $t("travel.note_header") }}
                </template>
                <template slot="default">
                    {{ showNoteContext }}
                </template>
            </modal>
            <v-travel-form
                v-if="travelModal"
                :context="editContext"
                :project_id="project_id"
                :is-billable="project.travel_is_billable"
                @close="travelModal = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>

<style></style>
