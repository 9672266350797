<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

import ProjectService from "@/services/ProjectService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import TravelService from "@/services/TravelService";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VTravelForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        showProjects: {
            type: Boolean,
            default: false,
            description:
                "Whether to enable project/customer selector for the independent form",
        },
        project_id: {
            description: "Customer ID",
            default: null,
        },
        isBillable: {
            type: Number,
            default: 1,
            description: "Is billable flag",
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            users: [],
            customers: [],
            projects: [],

            travel: {
                id: null,
                user_id: null,
                customer_id: null,
                project_id: null,
                invoice_text: null,
                notes: null,
                kms: null,
                is_invoiced: null,
                travel_date: null,
                is_billable: null,
            },
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        disabledProject: function () {
            if (this.travel.customer_id > 0) {
                return false;
            } else {
                return true;
            }
        },
        inputProject: function () {
            return this.travel.project_id;
        },
        inputCustomer: function () {
            return this.travel.customer_id;
        },
    },
    watch: {
        travel: function (n) {
            console.log("CHANGE", n);
        },
        /**
         * Watch project_id to load billable setting
         */
        inputProject: async function (newVal) {
            console.log("InputProject change", newVal, this.showProjects);
            if (this.showProjects) {
                if (newVal > 0) {
                    let project = await this.fetchProject(newVal);
                    this.travel.is_billable = project.travel_is_billable;

                    console.log(
                        "fetched a project ID " +
                            project.id +
                            " with setting " +
                            project.travel_is_billable
                    );
                    console.log(this.travel);
                }
            }
        },
        /**
         * Watch customer_id to open up project select (and load projects)
         */
        inputCustomer: async function (newVal) {
            if (this.showProjects) {
                // Fetch projects for a given customer
                if (newVal > 0) {
                    // Fetch projects for a newly selected customer
                    // This method also fetches internal_customer flag value
                    await this.fetchProjects(this.travel.customer_id);
                    this.travel.project_id = "";
                }
            }
        },
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);
        this.loading = true;

        await this.fetchUsers(true);

        if (!this.context.empty) {
            console.log("Edit", this.travel);
            // Edit
            this.editing = true;
            this.travel = Cloner.pickClone(this.context, [
                "id",
                "user_id",
                "project_id",
                "invoice_text",
                "notes",
                "kms",
                "is_invoiced",
                "travel_date",
                "is_billable",
            ]);
        } else {
            // Create
            // Assign contract_Id
            console.log("Create", this.travel);
            this.travel.project_id = this.project_id;
            this.travel.user_id = this.getMyId;
            this.travel.is_billable = this.isBillable;
            this.travel.travel_date = dayjs().format("YYYY-MM-DD");
        }

        this.loading = false;
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await TravelService.PUT(this.travel);
                } else {
                    const r = await TravelService.POST(this.travel);
                }

                this.$notify({
                    message: this.$t("travel.notification_new_travel_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success",
                });
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Fetch all users for the dropdown
         */
        async fetchUsers(loading = false) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log(e);
            }
            this.loading = loading;
        },
        /**
         * Delete
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("travel.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true,
                }).then(async (confirmed) => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await TravelService.DELETE(
                                this.travel.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n"),
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error"),
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get customer dropdown
         */
        async fetchCustomers(loading = true) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch projects for a given customer and update internal_customer
         * flag
         */
        async fetchProjects(customer_id) {
            try {
                this.projects = [];
                const r = await ProjectService.GET_customerProjectsData(
                    customer_id,
                    { internal_time: 0 }
                );
                this.projects = r.data.data.projects;
            } catch (e) {
                console.log("fetchProjects & internal customer flag", e);
            }
        },
        /**
         * Fetch the project (mainly for settings)
         */
        async fetchProject(project_id) {
            try {
                const r = await ProjectService.GETS(project_id);
                return Cloner.clone(r.data.data);
            } catch (e) {
                console.log("fetchProject exception", e);
            }
            return null;
        },
    },
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("travel.edit") }}
            </template>
            <template v-else>
                {{ $t("travel.add") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm v-model="travel" @submit="saveForm" :errors="errors" :debounce="100">
                <template v-if="showProjects">
                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                type="fselect"
                                :options="customers"
                                name="customer_id"
                                :placeholder="$t('travel.placeholder_customer')"
                                validation="required"
                                :validation-name="$t('travel.customer')"
                            />
                        </div>

                        <div class="col-12">
                            <FormulateInput
                                :disabled="disabledProject"
                                type="fselect"
                                :options="projects"
                                name="project_id"
                                :no-options="$t('travel.no_cases_available')"
                                :placeholder="$t('travel.placeholder_project')"
                                :validation-name="$t('travel.case')"
                            />
                        </div>
                    </div>
                </template>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="select"
                            :options="users"
                            :label="$t('travel.user')"
                            :placeholder="$t('travel.placeholder_user')"
                            :disabled="!ACL_RW"
                            name="user_id"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <FormulateInput
                            type="date"
                            name="travel_date"
                            :label="$t('travel.travel_date')"
                            :validation-name="$t('travel.travel_date')"
                            validation="required"
                        />
                    </div>

                    <div class="col-12 col-md-6">
                        <FormulateInput
                            type="grouptext"
                            append="DKK"
                            name="kms"
                            label="Beløb"
                            :validation-name="$t('travel.kilometres')"
                            validation="required|number|min:0,value"
                        />
                    </div>


                </div>
                <div class="row">
                    <div class="col-12 col-md-6 travel-billable text-center">
                        <FormulateInput
                            type="fswitch"
                            name="is_billable"
                            :label="$t('travel.is_billable')"
                            :validation-name="$t('travel.is_billable')"
                        />
                    </div>
                    <div class="col-12 col-md-6 travel-billable text-center">
                        <FormulateInput
                            type="fswitch"
                            name="is_private"
                            label="Betalt med privat kort"
                            :validation-name="$t('travel.is_billable')"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            name="invoice_text"
                            :label="
                                travel.is_billable
                                    ? $t('travel.invoice_text')
                                    : $t('travel.travel_description')
                            "
                            :validation-name="$t('travel.invoice_text')"
                            validation="required|max:255,length"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="textarea"
                            name="notes"
                            :label="$t('travel.internal_notes')"
                            :validation-name="$t('travel.internal_notes')"
                        />
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        Her kan man uploade filer og se hvad er uploaded.
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button
                            v-if="
                                editing && (ACL_RW || travel.user_id == getMyId)
                            "
                            type="danger"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
