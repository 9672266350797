<script>
import ProjectService from "@/services/ProjectService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";
import Cloner from "@/libraries/Cloner";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VChangeCustomerForm",
    props: {
        projectId: {},
        canChangeCustomer: {}
    },
    data() {
        return {
            loading: true,
            customers: [],
            project: null,
            discounts: [],
            errors: {},
            payload: {
                project_id: "",
                customer_id: "",
                users: [],
            },
        };
    },
    computed: {
        /**
         * Return all customers except the current one
         */
        filteredCustomers() {
            if (this.customers.length === 0 || this.project === null) {
                return [];
            }

            let customers = [];

            this.customers.forEach((c) => {
                if (parseInt(c.value) !== parseInt(this.project.customer_id)) {
                    customers.push(c);
                }
            });

            return customers;
        },
        matchedDiscount() {
            if (
                this.discounts.length === 0 ||
                this.payload.customer_id === ""
            ) {
                return null;
            }

            const idx = this.discounts.findIndex((discount) => {
                if (
                    parseInt(this.payload.customer_id) === parseInt(discount.id)
                ) {
                    return true;
                } else {
                    return false;
                }
            });

            try {
                return this.discounts[idx]["discount"];
            } catch (e) {
                return null;
            }
        },
        payloadUsers() {
            return this.payload.users;
        },
        formErrors() {
            if ("error" in this.errors) {
                return [
                    this.$t(
                        "change_customer.error_transaction_change_unsuccessful_contact_support"
                    ),
                ];
            } else {
                return [];
            }
        },
    },
    watch: {
        payloadUsers: {
            deep: true,
            handler: function (newValue) {
                console.log("Recalculation");
                this.recalculatePrice();
            },
        },
    },
    async created() {
        await this.fetchProject(true);
        await this.fetchCustomers(true);
        await this.fetchDiscounts();
    },
    methods: {
        async saveForm() {
            if (this.canChangeCustomer) {
                await this.performSaveForm();
            } else {
                this.$swal({
                    icon: "warning",
                    text: this.$t(
                        "change_customer.notice_question_change_of_customer_with_invoicing_history_find_in_logs"
                    ),
                    showConfirmButton: true,
                    showCancelButton: true,
                }).then(async (confirmed) => {
                    if (confirmed.isConfirmed) {
                        await this.performSaveForm();
                    } // if
                });
            }
        },
        async performSaveForm() {
            let payload = Cloner.pickClone(this.payload, [
                "project_id",
                "customer_id",
                "users.[]",
            ]);

            try {
                const r = await ProjectService.PUT_changeCustomer(payload);
                this.$emit("close-refresh", {
                    project_id: this.projectId,
                });
            } catch (e) {
                console.log("E", e, e.response);
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Recalculate all form prices for each project member.
         */
        recalculatePrice() {
            this.payload.users.forEach((u, index) => {
                let num =
                    u.default_hour_price -
                    u.default_hour_price * (u.discount_percent / 100);
                u.hour_price = Number.parseFloat(num).toFixed(2);
            });
        },
        /**
         * Fetch the project + participants
         */
        async fetchProject(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.projectId, {
                    includes: "users",
                });
                this.project = r.data.data;

                this.payload.project_id = this.projectId;

                if (typeof this.project.users !== 'undefined') {
                    this.project.users.forEach((u) => {
                        this.payload.users.push({
                            user_id: u.id,
                            default_hour_price: u.default_hour_price,
                            hour_price: u.hour_price,
                            discount_percent: u.discount_percent,
                            name: u.name,
                        });
                    });
                }
            } catch (e) {
                console.log("fetchProject exception", e);
            }

            this.loading = loading;
        },
        /**
         * Get customers
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch discounts
         */
        async fetchDiscounts(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.GET_discounts();
                this.discounts = r.data.data;
            } catch (e) {
                console.log("Discounts exception", e);
            }

            this.loading = loading;
        },
    },
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        @close="$emit('close')"
        class=""
        :loading="loading"
    >
        <template slot="header">
            {{ $t("change_customer.header_change_project_customer") }}
        </template>

        <template slot="default">
            <FormulateForm v-model="payload" :form-errors="formErrors">
                <div class="row">
                    <div class="col-6">
                        <FormulateInput
                            type="select"
                            :options="filteredCustomers"
                            name="customer_id"
                            :validation-name="$t('project.new_customer')"
                            :label="$t('project.new_customer')"
                            :placeholder="
                                $t('project.placeholder_new_customer')
                            "
                            validation="required"
                        />
                    </div>
                    <div
                        class="col-6 d-flex align-items-center justify-content-center"
                    >
                        <template v-if="parseInt(matchedDiscount) > 0">
                            {{
                                $t(
                                    "change_customer.this_customer_has_discount_percent"
                                )
                            }}: {{ $n(matchedDiscount) }}%
                        </template>
                        <template v-else>
                            {{
                                $t(
                                    "change_customer.this_customer_has_no_discount"
                                )
                            }}
                        </template>
                    </div>
                </div>

                <template v-if="project !== null && 'users' in project">
                    <h3 class="mt-4">
                        {{ $t("change_customer.project_members") }}
                    </h3>
                    <p>
                        {{
                            $t(
                                "change_customer.help_text_review_prices_before_converting"
                            )
                        }}
                    </p>

                    <pre v-if="false">{{ payload }}</pre>

                    <div v-if="payload.users.length > 0">
                        <div
                            class="row d-flex align-items-center change-customer-user-row"
                            v-for="(user, index) in payload.users"
                            :key="user.id"
                        >
                            <div class="col-3" :data-user-id="user.id">
                                {{ user.name }}
                                <div
                                    class="alert alert-danger alert-outline p-2"
                                    v-if="
                                        'users' in errors &&
                                        index in errors.users
                                    "
                                >
                                    {{
                                        $t(
                                            "change_customer.error_check_entries_data_wrong"
                                        )
                                    }}
                                </div>
                            </div>

                            <div class="col-3">
                                <FormulateInput
                                    type="currency"
                                    :currency="project.currency"
                                    v-model="
                                        payload['users'][index][
                                            'default_hour_price'
                                        ]
                                    "
                                    :label="$t('project.default_hour_price')"
                                    :validation-name="
                                        $t('project.default_hour_price')
                                    "
                                />
                            </div>
                            <div class="col-3">
                                <FormulateInput
                                    type="groupdecimal"
                                    append="%"
                                    v-model="
                                        payload['users'][index][
                                            'discount_percent'
                                        ]
                                    "
                                    :label="$t('project.discount_percent')"
                                    :validation-name="
                                        $t('project.discount_percent')
                                    "
                                    validation="required|number|between:-1,101"
                                />
                            </div>
                            <div class="col-3">
                                <FormulateInput
                                    type="currency"
                                    :currency="project.currency"
                                    v-model="
                                        payload['users'][index]['hour_price']
                                    "
                                    :label="$t('project.effective_hour_price')"
                                    :validation-name="
                                        $t('project.effective_hour_price')
                                    "
                                    :disabled="true"
                                />
                            </div>
                        </div>
                    </div>
                </template>

                <div class="text-right mt-5">
                    <FormulateErrors />
                    <base-button type="secondary" @click="$emit('close')">{{
                        $t("change_customer.cancel")
                    }}</base-button>
                    <base-button
                        type="primary"
                        @click="saveForm"
                        :disabled="isNaN(parseInt(payload.customer_id))"
                        >{{
                            $t("change_customer.change_customer")
                        }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style lang="scss">
.change-customer-user-row {
    padding-top: 15px;
    border-top: 1px solid $border-color !important;
}

div.change-customer-user-row:last-child {
    border-bottom: 1px solid $border-color !important;
}
</style>
