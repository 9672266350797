<script>
import Cloner from "@/libraries/Cloner";
import InvoiceService from "@/services/InvoiceService";

export default {
    name: "VInvoiceHeaderForm",
    components: {},
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    data() {
        return {
            loading: false,
            errors: null,
            invoiceHead: {
                id: null,
                invoice_header: null,
                invoice_text1: null,
                bkeeper_message: null,
                invoice_date: null
            },
        };
    },
    async created() {
        this.invoiceHead = Cloner.pickClone(this.context, [
            "id",
            "invoice_header",
            "invoice_text1",
            "bkeeper_message",
            "invoice_date"
        ]);
    },
    methods: {
        async saveForm() {
            try {
                const r = await InvoiceService.PUT_updateInvoiceHead(
                    this.invoiceHead
                );
                this.$emit("save", r.data.data);
                this.$notify({
                    message: this.$t("project.notify_invoice_head_updated"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success",
                });
            } catch (e) {
                console.log("saveForm exception", e);
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="project-modal"
    >
        <template slot="header">
            {{ $t("project.edit_invoice_head") }}
        </template>
        <template slot="default" v-if="!loading">
            <FormulateForm
                v-model="invoiceHead"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="invoice_date"
                            type="date"
                            :label="$t('project.deadline_invoice_date')"
                            :validation-name="$t('project.deadline_invoice_date')"
                            :help="$t('project.help_invoice_deadline')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="invoice_header"
                            type="text"
                            :label="$t('project.invoice_header')"
                            :validation-name="$t('project.invoice_header')"
                            :help="$t('project.help_invoice_header')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="invoice_text1"
                            type="textarea"
                            :label="$t('project.invoice_text_1')"
                            :validation-name="$t('project.invoice_text_1')"
                            :help="$t('project.help_invoice_text_1')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="bkeeper_message"
                            type="textarea"
                            :label="$t('project.message_to_bookkeeper')"
                            :validation-name="
                                $t('project.message_to_bookkeeper')
                            "
                            :placeholder="$t('project.message_to_bookkeeper_placeholder')"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>