<script>
/**
 * Invoice bar - 4 boxes A B C D
 * displayed above invoice containing important numbers.
 */
import OuterDashCard from "@/components/Cards/OuterDashCard";

export default {
    name: "InvoiceBar",
    props: {
        project: {
            description: "Project object",
        },
        stats: {
            description: "Stats object",
        },
        loading: {
            type: Boolean,
            default: false,
            description: "Stats loading",
        },
    },
    components: { OuterDashCard },
};
</script>

<template>
    <div class="row stats">
        <!-- FIXED PRICE -->
        <div v-if="project.project_model == 1" class="col-12 col-md-6 col-lg-3">
            <outer-dash-card
                :title="$t('project.budget_balance')"
                icon="far fa-stopwatch"
                :small="true"
            >
                <template slot="default">
                    <v-loader :loading="loading" loader="mini" v-if="loading" />
                    <template v-else-if="stats">
                        <span
                            v-if="project.budget_amount > 0"
                            :class="
                                stats.invoiced.budget_perc_usage > 100
                                    ? 'text-danger'
                                    : ''
                            "
                        >
                            {{
                                $n(stats.invoiced.budget_balance, {
                                    style: "currency",
                                    currency: project.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </span>
                        <template v-else>---</template>
                    </template>
                </template>
                <template slot="action">
                    <span
                        v-if="project.budget_amount > 0"
                        :class="
                            stats.invoiced.budget_perc_usage > 100
                                ? 'text-danger'
                                : ''
                        "
                    >
                        {{
                            $t("project.N_of_total_budget", {
                                n: stats.invoiced.budget_perc_usage,
                            })
                        }}</span
                    >
                    <template v-else>---</template>
                </template>
            </outer-dash-card>
        </div>
        <!-- BILLABLE HOURS -->
        <div class="col-12 col-md-6 col-lg-3" v-if="project.project_model == 2">
            <outer-dash-card
                :title="$t('project.activities')"
                icon="far fa-stopwatch"
                :small="true"
            >
                <template slot="default">
                    <v-loader :loading="loading" loader="mini" v-if="loading" />
                    <template v-else-if="stats">
                        {{
                            $n(stats.awaiting.task, {
                                style: "currency",
                                currency: project.currency,
                                currencyDisplay: "code",
                            })
                        }}
                    </template>
                </template>
                <template slot="action">
                    <v-loader :loading="loading" loader="mini" v-if="loading" />
                    <template v-else-if="stats">
                        {{ $tc("project.N_open_tasks", stats.openTasks) }}
                    </template>
                </template>
            </outer-dash-card>
        </div>
        <!-- EXPENSES -->
        <div class="col-12 col-md-6 col-lg-3">
            <outer-dash-card
                :title="$t('project.expenses')"
                icon="far fa-receipt"
                :small="true"
            >
                <template slot="default">---</template>
                <template slot="action">{{
                    $t("project.billable_on_top_budget")
                }}</template>
            </outer-dash-card>
        </div>
        <!-- TRAVEL -->
        <div class="col-12 col-md-6 col-lg-3">
            <outer-dash-card
                :title="$t('project.transport')"
                icon="far fa-car"
                :small="true"
            >
                <template slot="default">
                    <v-loader :loading="loading" loader="mini" v-if="loading" />
                    <template v-else-if="stats">
                        {{
                            $n(stats.awaiting.travel, {
                                style: "currency",
                                currency: project.currency,
                                currencyDisplay: "code",
                            })
                        }}
                    </template>
                </template>
                <template slot="action">{{
                    $t("project.billable_on_top_budget")
                }}</template>
            </outer-dash-card>
        </div>
        <!-- TOTAL  -->
        <div class="col-12 col-md-6 col-lg-3">
            <outer-dash-card
                :title="$t('project.total_sum')"
                icon="far fa-file-invoice"
                :small="true"
            >
                <template slot="default">
                    <v-loader :loading="loading" loader="mini" v-if="loading" />
                    <template v-else-if="stats">
                        {{
                            $n(stats.awaiting.total, {
                                style: "currency",
                                currency: project.currency,
                                currencyDisplay: "code",
                            })
                        }}
                    </template>
                </template>
                <template slot="action"
                    ><i class="text-white far fa-sum"></i
                ></template>
            </outer-dash-card>
        </div>
    </div>
</template>

<style>
</style>