var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"budget-modal",attrs:{"show":true,"loading":_vm.loading,"size":"full"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t("project_milestones.edit"))+" ")]),_c('template',{slot:"default"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("project_milestones.edit_modal_help_text"))+" ")])]),_c('div',{staticClass:"col-12"},[_c('v-table',{staticClass:"table table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_c('input',{attrs:{"type":"checkbox"},on:{"click":_vm.selectAll}})]),_c('v-th',[_vm._v(_vm._s(_vm.$t("project_milestones.tbl_hdr_name")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "project_milestones.tbl_hdr_status_in_project" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "project_milestones.tbl_hdr_comment_in_project" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "project_milestones.tbl_hdr_comment_global" )))])],1)],1),_c('v-tbody',_vm._l((_vm.milestones),function(m){return _c('v-tr',{key:m.id,class:m.project !== null
                                    ? 'milestone-is-added'
                                    : 'milestone-is-not-added'},[_c('v-td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.selected_milestones[m.milestone_id]
                                    ),expression:"\n                                        selected_milestones[m.milestone_id]\n                                    "}],attrs:{"type":"checkbox","disabled":m.project !== null},domProps:{"checked":Array.isArray(
                                        _vm.selected_milestones[m.milestone_id]
                                    )?_vm._i(
                                        _vm.selected_milestones[m.milestone_id]
                                    ,null)>-1:(
                                        _vm.selected_milestones[m.milestone_id]
                                    )},on:{"change":function($event){var $$a=
                                        _vm.selected_milestones[m.milestone_id]
                                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selected_milestones, m.milestone_id, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selected_milestones, m.milestone_id, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selected_milestones, m.milestone_id, $$c)}}}})]),_c('v-td',[_vm._v(_vm._s(m.name))]),(m.project === null)?[_c('v-td',[_vm._v("---")]),_c('v-td',[_vm._v("---")]),_c('v-td',[_c('v-newlines',{attrs:{"text":m.comment}})],1)]:[_c('v-td',[_c('span',{class:{
                                            'text-success':
                                                m.project.status === 1,
                                            'text-danger':
                                                m.project.status !== 1
                                        }},[_vm._v(_vm._s(_vm.appCfgRead( "ProjectMilestones.status" )[m.project.status])+" ")])]),_c('v-td',[_c('v-newlines',{attrs:{"text":m.project.comment}})],1),_c('v-td',[_c('v-newlines',{attrs:{"text":m.comment}})],1)]],2)}),1)],1)],1)]),(_vm.errors !== null)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-danger text-center"},[_vm._v(_vm._s(_vm.errors.join("")))])])]):_vm._e(),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 text-right"},[_c('base-button',{attrs:{"type":"secondary","outline":true},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('base-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.saveForm}},[_vm._v(_vm._s(_vm.$t("save")))])],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }