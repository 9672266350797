<script>
import TaskService from "@/services/TaskService";

export default {
    props: {
        data: {}
    },
    data() {
        return {
            days: {},
            saving: false
        };
    },
    computed: {
        hasSelections() {
            if (Object.values(this.days).length === 0) {
                return false;
            }

            let check = false;
            Object.values(this.days).forEach(v => {
                if (v === true) {
                    check = true;
                }
            });

            return check;
        }
    },
    methods: {
        async closeAll() {
            this.saving = true;
            try {
                await TaskService.PUT_closeMultipleDays(this.days);
                this.$emit('close-refresh');
            } catch (e) {
                console.log("ex e", e);
            }
        },
        doAll() {
            console.log("DO ALL");
            this.data.forEach(k => {
                if (k.is_closed === false && k.total_time > 0) {
                    this.$set(
                        this.days,
                        k.task_date,
                        k.task_date in this.days
                            ? !this.days[k.task_date]
                            : true
                    );
                }
            });
        },
        
    }
};
</script>

<template>
    <modal
        size="lg"
        @close="$emit('close')"
        :show="true"
        class="currency-modal"
    >
        <template slot="header">{{
            $t("close_day.close_multiple_days")
        }}</template>
        <template slot="default">
            <v-table class="table table-striped">
                <v-thead>
                    <v-tr>
                        <v-th><input type="checkbox" @click="doAll"/></v-th>
                        <v-th class="text-center">{{
                            $t("close_day.date")
                        }}</v-th>
                        <v-th class="text-center">{{
                            $t("close_day.registered_time")
                        }}</v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="(day, idx) in data" :key="idx">
                        <template v-if="day.is_closed === false && day.total_time > 0">
                            <v-td
                                ><input
                                    type="checkbox"
                                    v-model="days[day.task_date]"
                            /></v-td>
                            <v-td class="text-center">{{
                                $d(new Date(day.task_date), "short")
                            }}</v-td>
                            <v-td class="text-center">{{
                                day.total_time | toTime
                            }}</v-td>
                        </template>
                    </v-tr>
                </v-tbody>
            </v-table>
            <div class="text-right mt-5">
                <base-button
                    @click="closeAll"
                    :disabled="!hasSelections"
                    :loading="saving"
                >
                    {{ $t("close_day.close_selected_days") }}
                </base-button>
            </div>
        </template>
    </modal>
</template>
