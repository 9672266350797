<script>

export default {
    name: "VWait",
    props: {       
        text: {
            type: String,
            required: true
        }                
    },
    data() {
        return {            
        }
    },       
};
</script>

<template>
    <modal size="lg" :show="true" :showClose="false" class="queue-modal">       

        <template slot="default">
            <div class="queue-modal__container">
                <h3 class="text-center">{{text}}</h3>
                <v-loader :loading="true" />                
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
    .queue-modal {
        &__container {
            h3 {
                margin-bottom: 30px; 
            }

            &_name {
                text-align: center;
                margin-top: 30px; 
                padding-bottom: 15px;
            }
        }
    }
</style>