<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VInfoCard from "@/components/Cards/VInfoCard";
import TaskService from "@/services/TaskService";
import InvoiceService from "@/services/InvoiceService";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import Cloner from "@/libraries/Cloner";

import Messages from "@/mixins/Messages";
import DateRangeMixin from "@/mixins/DateRangeMixin";

export default {
    name: "VInvoiceActivityLineForm",
    props: {
        invoiceId: {
            required: true,
            description: "Invoice ID"
        },
        projectId: {
            required: true,
            description: "Project ID - to get tasks"
        },
        currency: {
            type: String,
            required: true,
            description: "Currency"
        },
        hasMilestones: {
            type: Boolean,
            default: false,
            description: "Whether the project has enabled milestones"
        }
    },
    components: { VInfoCard, DateRangePicker },
    mixins: [Messages, DateRangeMixin],
    data() {
        return {
            filterMilestone: "",
            milestones: [],
            debug: "",
            loading: false,
            creating: false,
            errors: null,
            dateRange: {
                startDate: null,
                endDate: null
            },
            lineConfig: [
                {
                    label: this.$t("invoice_line.option_detailed"),
                    value: "detailed"
                },
                {
                    label: this.$t("invoice_line.option_squashed"),
                    value: "squashed"
                },
                {
                    label: this.$t("invoice_line.option_grouped"),
                    value: "grouped"
                }
            ],
            tasks: [],
            selectedTasks: [],
            options: {
                line_config: "detailed", // true -> 1 line, false -> task per line
                include_squash_details: false,
                include_time_details: false,
                task_fields: {
                    milestone: false,
                    activity: true,
                    details: true,
                    task_date: true,
                    time_reg: true,
                    user: false
                }
            },
            invoiceLine: {
                qty: 1,
                price: null,
                price_subtotal: null,
                invoice_id: null,
                discount_perc: null,
                invoice_line: null,
                meta_key: "task"
            }
        };
    },
    computed: {
        filteredMilestones() {
            let m = [];
            m.push({
                value: "",
                label: this.$t("invoice_line.filter_all_no_milestones")
            });
            m.push(...this.milestones);
            return m;
        },
        line_config: function() {
            return this.options.line_config;
        },
        /**
         * Sum of selected task time
         */
        selectedTime: function() {
            if (this.selectedTasks.length == 0) {
                return 0;
            }

            let time = 0;
            this.selectedTasks.forEach(idx => {
                time += this.tasks[idx].time_reg;
            });

            return time;
        },
        /**
         * Sum of selected task prices
         */
        selectedAmount: function() {
            if (this.selectedTasks.length == 0) {
                return 0;
            }

            let sum = 0;
            this.selectedTasks.forEach(idx => {
                sum += this.tasks[idx].task_price * 100;
            });

            return sum / 100;
        },
        /**
         * checkbox sel/unsel all
         */
        selectAll: {
            get: function() {
                return this.tasks
                    ? this.selectedTasks.length == this.tasks.length
                    : false;
            },

            set: function(v) {
                let selected = [];

                if (v) {
                    this.tasks.forEach(function(v, index) {
                        selected.push(index);
                    });
                }

                this.selectedTasks = selected;
            }
        },
        qty: function() {
            return this.invoiceLine.qty;
        },
        price: function() {
            return this.invoiceLine.price;
        },
        discount_perc: function() {
            return this.invoiceLine.discount_perc;
        }
    },
    watch: {
        filterMilestone: function(n) {
            this.fetchTasks();
        },
        dateRange: function(n) {
            this.fetchTasks();
        },
        qty: function(n) {
            this.updateSubtotal();
        },
        price: function(n) {
            this.updateSubtotal();
        },
        discount_perc: function(n) {
            this.updateSubtotal();
        }
    },
    async created() {
        this.invoiceLine.invoice_id = this.invoiceId;
        if (this.hasMilestones) {
            await this.fetchMilestones();
            this.extendOptions();
        }
        await this.fetchTasks();
    },
    methods: {
        extendOptions() {
            this.lineConfig.push({
                label: this.$t("invoice_line.option_grouped_milestones"),
                value: "grouped_milestones"
            });
            this.options.task_fields.milestone = true;
        },
        /**
         * Fetch milestones
         */
        async fetchMilestones() {
            try {
                const {
                    data
                } = await ProjectMilestoneService.GET_invoicingDropdown(
                    this.projectId
                );
                this.milestones = data.data;
            } catch (e) {
                console.log("fmi e", e);
            }
        },
        /**
         * Save the line
         */
        async saveForm() {
            let data = {
                options: Cloner.clone(this.options),
                invoice_id: this.invoiceId,
                tasks: this.selectedTasksToIds()
            };

            if (this.options.line_config == "squashed") {
                data["invoiceLine"] = Cloner.clone(this.invoiceLine);
            }

            console.log("Data", data);

            try {
                this.creating = true;
                const r = await InvoiceService.POST_fromActivities(data);
                // We get back here just OK confirmation if everything is OK
                // as the result might be several lines -> invoice component will
                // re-render everything.
                this.$emit("save", null);
            } catch (e) {
                console.log("saveDetailed exception", e);
                if ("errors" in e.response.data) {
                    if ("invoiceLine" in e.response.data.errors) {
                        this.errors = e.response.data.errors.invoiceLine;
                    } else {
                        this.errors = null;
                    }
                }
            }

            console.log("DataAFTER", data);

            this.creating = false;
        },
        selectedTasksToIds() {
            let ids = [];
            this.selectedTasks.forEach(elem => {
                ids.push(this.tasks[elem].id);
            });

            return ids;
        },
        updateSubtotal() {
            let qty = this.invoiceLine.qty;
            let price = this.invoiceLine.price * 100;

            if (isNaN(qty) || isNaN(price)) {
                return;
            }

            if (this.invoiceLine.discount_perc > 0) {
                price = (price * (100 - this.invoiceLine.discount_perc)) / 100;
                //d = p - p*% = p(1 - %)
            }

            let subTotal = (qty * price) / 100;

            if (isNaN(subTotal)) {
                return;
            }

            subTotal = Math.round(subTotal * 100) / 100;

            console.log("Subtotal", subTotal);
            this.invoiceLine.price_subtotal = subTotal;
        },
        async fetchTasks() {
            try {
                this.loading = true;
                const r = await TaskService.GET({
                    project_id: this.projectId,
                    is_billable: 1,
                    is_closed: 1,
                    is_invoiced: 0,
                    dateRange: this.serializeDateRange(this.dateRange),
                    force_all: 1,
                    ...(this.hasMilestones
                        ? { project_milestone_id: this.filterMilestone }
                        : {})
                });

                this.tasks = r.data.data;
                this.selectedTasks = [];
            } catch (e) {
                console.log("fetchTasks exception", e);
            }
            this.loading = false;
        },
        /**
         * Copy selected amount to the invoiceLine form
         */
        copyAmount() {
            console.log("copyAmount() " + this.selectedAmount);
            this.invoiceLine.price = this.selectedAmount;
        },
        /**
         * Reset date-range
         */
        resetRange() {
            this.dateRange = {
                startDate: null,
                endDate: null
            };
        }
    }
};
</script>

<template>
    <modal
        :loading="loading"
        size="full"
        :show="true"
        :show-close="false"
        @close="$emit('close')"
        class="invoice-modal"
    >
        <template slot="header">
            <div class="align-items-center d-flex flex-grow-1">
                {{ $t("project.new_activity_invoice_line") }}

                <div class="ml-auto">
                    <base-button @click="$emit('close')" type="muted">{{
                        $t("project.cancel")
                    }}</base-button>
                    <base-button
                        :loading="creating"
                        type="primary"
                        @click="saveForm"
                        :disabled="selectedTasks.length == 0 || creating"
                        >{{ $t("project.save") }}
                        <i class="fas fa-chevron-right"></i
                    ></base-button>
                </div>
            </div>
        </template>
        <template slot="default">
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="form-control-label">{{
                                    $t("invoice_line.date_range")
                                }}</label>
                                <div>
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange('dateRange')
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                    <span
                                        class="text-muted small m-click m-link"
                                        @click="resetRange"
                                        ><i class="far fa-sync"></i>
                                        {{
                                            $t("invoice_line.reset_range")
                                        }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <FormulateInput
                                type="select"
                                :label="$t('invoice_line.invoice_view')"
                                :options="lineConfig"
                                v-model="options.line_config"
                            />
                        </div>
                    </div>
                    <!-- .row -->
                    <div class="row" v-if="hasMilestones">
                        <!-- Milestones -->
                        <div class="col">
                            <FormulateInput
                                type="select"
                                :label="$t('invoice_line.milestone')"
                                :options="filteredMilestones"
                                v-model="filterMilestone"
                            />
                        </div>
                    </div>
                </div>
                <!-- .col-6 -->
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <v-info-card
                                :title="$t('invoice_line.selected_activities')"
                            >
                                <template slot="default">{{
                                    selectedTasks.length
                                }}</template>
                                <template slot="action">{{
                                    $t("invoice_line.count")
                                }}</template>
                            </v-info-card>
                        </div>
                        <div class="col">
                            <v-info-card
                                :title="$t('invoice_line.selected_time')"
                            >
                                <template slot="default">{{
                                    selectedTime | toTime
                                }}</template>
                                <template slot="action">{{
                                    $t("invoice_line.hours")
                                }}</template>
                            </v-info-card>
                        </div>
                        <div class="col">
                            <v-info-card
                                :title="$t('invoice_line.selected_amount')"
                            >
                                <template slot="default">{{
                                    $n(selectedAmount, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "code"
                                    })
                                }}</template>
                                <template
                                    slot="action"
                                    v-if="options.line_config == 'squashed'"
                                >
                                    <span @click="copyAmount" class="m-click">
                                        <i class="far fa-copy"></i>
                                        {{ $t("invoice_line.copy_amount") }}
                                    </span>
                                </template>
                            </v-info-card>
                        </div>
                    </div>
                </div>
                <!-- .col-6 -->
            </div>

            <hr />

            <template>
                <FormulateForm
                    v-if="options.line_config == 'squashed'"
                    v-model="invoiceLine"
                    @submit="saveForm"
                    :errors="errors"
                    keep-model-data
                >
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <FormulateInput
                                type="multiline"
                                name="invoice_line"
                                :label="$t('invoice_line.description')"
                                validation="required"
                            />
                        </div>
                        <div class="col-12 col-md-1">
                            <FormulateInput
                                name="qty"
                                type="groupdecimal"
                                :label="$t('invoice_line.quantity')"
                                :disabled="true"
                                value="1"
                                :precision="2"
                                align="right"
                                validation="required"
                            />
                        </div>
                        <div class="col-12 col-md-2">
                            <FormulateInput
                                name="price"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.unit_price')"
                                validation="required"
                            />
                        </div>

                        <div class="col-12 col-md-2">
                            <FormulateInput
                                name="discount_perc"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.discount_in_perc')"
                                validation="required"
                            />
                        </div>
                        <div class="col">
                            <FormulateInput
                                name="price_subtotal"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :label="$t('invoice_line.total')"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </FormulateForm>

                <div class="row">
                    <div class="col-12">
                        <template
                            v-if="
                                options.line_config == 'grouped' ||
                                    options.line_config == 'grouped_milestones'
                            "
                        >
                            <input
                                type="checkbox"
                                v-model="options.include_time_details"
                            />
                            <span class="text-bold ml-2">
                                <template v-if="hasMilestones">
                                    {{
                                        $t(
                                            "invoice_line.include_total_time_sum_on_each_line_milestone"
                                        )
                                    }}
                                </template>
                                <template v-else>{{
                                    $t(
                                        "invoice_line.include_total_time_sum_on_each_line"
                                    )
                                }}</template></span
                            >
                        </template>
                    </div>
                    <div class="col-12">
                        <template
                            v-if="
                                options.line_config == 'squashed' ||
                                    options.line_config == 'grouped' ||
                                    options.line_config == 'grouped_milestones'
                            "
                        >
                            <input
                                type="checkbox"
                                v-model="options.include_squash_details"
                            />
                            <span class="text-bold ml-2">{{
                                $t(
                                    "invoice_line.include_activity_details_in_invoice_text"
                                )
                            }}</span>
                        </template>
                        <template v-else>
                            <span class="text-bold"
                                >{{
                                    $t(
                                        "invoice_line.configure_task_details_in_invoice_lines"
                                    )
                                }}
                            </span>
                        </template>
                        <div
                            class="d-flex justify-content-start mt-2"
                            v-if="
                                options.include_squash_details ||
                                    options.line_config == 'detailed'
                            "
                        >
                            <template v-if="options.line_config != 'grouped'">
                                <div class="mr-4" v-if="hasMilestones && options.line_config != 'grouped_milestones'">
                                    <input
                                        type="checkbox"
                                        v-model="options.task_fields.milestone"
                                    />
                                    {{ $t("invoice_line.milestone_name") }}
                                </div>
                                <div class="mr-4">
                                    <input
                                        type="checkbox"
                                        v-model="options.task_fields.activity"
                                        :disabled="true && !hasMilestones"
                                    />
                                    {{ $t("invoice_line.activity_name") }}
                                </div>
                            </template>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.task_fields.details"
                                />
                                {{ $t("invoice_line.description") }}
                            </div>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.task_fields.task_date"
                                />
                                {{ $t("invoice_line.date") }}
                            </div>
                            <div
                                class="mr-4"
                                v-if="options.line_config != 'detailed'"
                            >
                                <input
                                    type="checkbox"
                                    v-model="options.task_fields.time_reg"
                                />
                                {{ $t("invoice_line.time_consumption") }}
                            </div>
                            <div class="mr-4">
                                <input
                                    type="checkbox"
                                    v-model="options.task_fields.user"
                                />
                                {{ $t("invoice_line.employee") }}
                            </div>
                        </div>
                        <hr class="mt-5" />
                    </div>
                </div>
            </template>

            <!-- <div class="row">
                <div class="col">
                    <pre style="font-size: 12px;">{{ debug }}</pre>

                </div>
            </div>  -->

            <v-loader :loading="loading" v-if="loading" />
            <template v-else>
                <h3 class="mt-3">{{ $t("invoice_line.select_activities") }}</h3>

                <div class="table-responsive mt-3" v-if="tasks.length > 0">
                    <v-table class="table table-mg table-hover">
                        <v-thead>
                            <v-tr>
                                <v-th>
                                    <input
                                        type="checkbox"
                                        v-model="selectAll"
                                    />
                                </v-th>
                                <v-th>{{ $t("invoice_line.date") }}</v-th>
                                <v-th v-if="hasMilestones">{{
                                    $t("invoice_line.milestone")
                                }}</v-th>
                                <v-th>{{ $t("invoice_line.activity") }}</v-th>
                                <v-th>{{ $t("invoice_line.user") }}</v-th>
                                <v-th>{{
                                    $t("invoice_line.extra_description")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("invoice_line.time_usage")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("invoice_line.hour_rate")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("invoice_line.amount")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="(task, index) in tasks" :key="task.id">
                                <v-td>
                                    <input
                                        type="checkbox"
                                        :value="index"
                                        v-model="selectedTasks"
                                    />
                                </v-td>
                                <v-td>{{
                                    $d(new Date(task.task_date), "short")
                                }}</v-td>
                                <v-td v-if="hasMilestones">
                                    <template
                                        v-if="'project_milestone' in task"
                                        >{{
                                            task.project_milestone.name
                                        }}</template
                                    >
                                    <template v-else>---</template>
                                </v-td>
                                <v-td>{{ task.activity.name }}</v-td>
                                <v-td>{{ task.user.name }}</v-td>
                                <v-td>
                                    <v-empty :text="task.details" />
                                </v-td>
                                <v-td class="text-right">{{
                                    task.time_reg | toTime
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(task.hour_price, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(task.task_price, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
                <div class="alert alert-warning" v-else>
                    {{
                        $t(
                            "invoice_lines.no_tasks_found_in_the_selected_date_range"
                        )
                    }}
                </div>
            </template>
        </template>
    </modal>
</template>

<style></style>
