<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VTaskForm from "@/components/Forms/VTaskForm";
import VFilters from "@/components/Search/VFilters";
import VQuickTask from "@/components/Projects/VQuickTask";
import VTaskStatus from "@/components/Task/Widgets/VTaskStatus";

import TaskService from "@/services/TaskService";
import UserService from "@/services/UserService";
import ActivityService from "@/services/ActivityService";
import ProjectService from "@/services/ProjectService";
import { EventBus } from "@/libraries/EventBus";
import DateRangeMixin from "@/mixins/DateRangeMixin";

/**
 * Show an individual project
 */

import Cloner from "@/libraries/Cloner";

export default {
    name: "ProjectViewer",
    props: {
        view: {
            type: String,
            default: "list"
        },
        project_id: {}
    },
    mixins: [DateRangeMixin],
    components: {
        VFilters,
        VTaskForm,
        VQuickTask,
        DateRangePicker,
        VTaskStatus
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser", "getIsAdmin"]),
        currentExportUrl: function() {
            let params = this.$buildParams();
            params["project_id"] = this.project_id;
            delete params.sort;
            delete params.direction;
            delete params.page;

            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/tasks/project-tasks-csv"
            );
            url.searchParams.append("csv", 1);
            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        params[param].forEach(function(value) {
                            url.searchParams.append(param + "[]", value);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        }
    },
    data() {
        return {
            loading: false, // loading tasks + meta
            initLoading: false, // one-shot loading on init
            showTaskModal: false,
            internalCustomer: false,
            project: null,
            meta: {},
            tasks: [],
            taskContext: { empty: true },
            users: [],
            customer_id: null,
            can_register: false,
            project_stats: null,
            activities: [],
            isBillable: [
                { label: this.$t("task.filter_billable"), value: "1" },
                { label: this.$t("task.filter_nonbillable"), value: "0" }
            ],
            taskStatus: [
                { label: this.$t("task.status_open"), value: "1" },
                { label: this.$t("task.status_closed"), value: "5" },
                { label: this.$t("task.status_invoiced"), value: "9" }
            ]
        };
    },
    async created() {
        this.initLoading = true;
        this.resetSearch();
        await this.fetchProject();

        // Check if YTD should be set on contract projects
        if (this.project.project_model == 3) {
            this.apiSearchFilter.dateRange = this.getDateRange_YTD();
        }
        this.$apiEnableWatcher();
        await this.fetchData();
        EventBus.$on("task-created", this.fetchData);
        this.initLoading = false;
    },
    methods: {
        fixParams() {
            let params = this.$buildParams();
            // Glue the project on
            params["project_id"] = this.project_id;
            // Get project meta sideloaded
            //params["project_meta"] = 1;
            // Get project stats sideloaded
            params["project_stats"] = 1;

            return params;
        },

        /**
         * Get tasks
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.fixParams();

                const r = await TaskService.GET(params);
                this.tasks = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {}
            this.loading = false;
        },
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
                this.internalCustomer = this.project.customer.internal_customer;
                this.customer_id = this.project.customer_id;
                this.can_register_tasks = this.project.can_register_tasks;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        /**
         * Insert a newly created quick-task at the top of the list
         */
        async insertTask(task) {
            task.is_new = true;
            this.tasks.splice(0, 0, task);
            // Refresh meta
            try {
                let params = this.fixParams();
                const r = await TaskService.GET_projectStats(params);
                //this.meta.project = r.data.data.project;
                this.meta.stats = r.data.data.stats;
            } catch (e) {}
        },
        /**
         * Reset all search parameters to startup defaults
         */
        resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };

            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "Tasks.task_date_ext"
            };
        },
        /**
         * Open up modal for editing & enable context
         */
        setTaskEditContext(task) {
            this.taskContext = task;
            this.showTaskModal = true;
        },
        /**
         * Delete the task
         * TBD TODO
         */
        async handleDeleteTask(task) {
            this.$swal({
                icon: "warning",
                text: this.$t("task.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.deleteTask(task);
                    this.$notify({
                        message: this.$t("task.notify_task_deleted"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success"
                    });

                    let idx = this.tasks.findIndex(el => {
                        return el.id == task.id ? true : false;
                    });

                    this.tasks.splice(idx, 1);
                }
            });
        },
        async deleteTask(task) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await TaskService.DELETE(task.id);
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, n => {
                            return n.join(".\n");
                        })
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error")
                    });
                }
            }
            this.loading = false;
        },
        /**
         * Close task modal
         */
        closeTaskModal() {
            this.showTaskModal = false;
        },
        /**
         * Close task modal and update the task object. Remove
         * if project_id changed.
         */
        closeSaveTaskModal(task) {
            // Find the task
            let idx = this.tasks.findIndex(el => {
                return el.id == task.id ? true : false;
            });

            // Either replace the task
            // or remove from the table (project_id change)
            if (task.project_id == this.project_id) {
                this.$set(this.tasks, idx, task);
            } else {
                this.tasks.splice(idx, 1);
            }

            this.showTaskModal = false;
        },
        async fetchSearchData() {
            await this.fetchUsers();
            await this.fetchActivities();
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}
        },
        /**
         * TBD: should be done user dependent
         */
        async fetchActivities(loading = true) {
            try {
                const r = await ActivityService.dropdown();
                this.activities = r.data.data;
            } catch (e) {}
        },
        /**
         * Show/hide delete option in the table
         */
        canDelete(task) {
            if (this.ACL_RW == false || task.user_id != this.getMyId) {
                return false;
            }

            if (task.is_locked || task.is_invoiced) {
                return false;
            }

            return true;
        }
    }
};
</script>

<template>
    <div class="v-project-viewer">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <h3 class="mb-3">{{ $t("project.quick_registration") }}</h3>
                    <v-loader :loading="initLoading" v-if="initLoading" />
                    <v-quick-task
                        v-else
                        @save="insertTask"
                        :internal-customer="internalCustomer > 0 ? true : false"
                        :customer-id="customer_id"
                        :project-id="project_id"
                        :allow-create="can_register_tasks"
                    />
                </div>
                <div class="col-12">
                    <h3 class="mb-3">{{ $t("project.time_usage") }}</h3>
                </div>
                <div class="col-12">
                    <v-loader :loading="initLoading" v-if="initLoading" />
                    <card body-classes="px-0" v-else>
                        <div class="mb-3 mt-1 px-3">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                :start-open="
                                    project.project_model == 3 ? true : false
                                "
                                @filters-open="fetchSearchData"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="left">
                                    <a
                                        :href="currentExportUrl"
                                        class="btn btn-primary"
                                        target="_blank"
                                        >{{ $t("projects.download_as_csv") }}</a
                                    >
                                </template>
                                <template slot="right">
                                    <v-loader
                                        :loading="initLoading"
                                        v-if="initLoading"
                                    />
                                    <div
                                        class="project-stats"
                                        v-else-if="meta.stats"
                                    >
                                        <div
                                            class="project-stats__box"
                                            v-if="!internalCustomer"
                                        >
                                            <div class="box-title">
                                                {{
                                                    $t("project.billable_hours")
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    meta.stats.billable_time
                                                        | toTime
                                                }}
                                            </div>
                                        </div>
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "project.non_billable_hours"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    meta.stats
                                                        .non_billable_time
                                                        | toTime
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="project-stats__box"
                                            v-if="!internalCustomer"
                                        >
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "project.billable_amount"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    $n(meta.stats.used_amount, {
                                                        style: "currency",
                                                        currency:
                                                            project.currency,
                                                        currencyDisplay: "code"
                                                    })
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="project-stats__box"
                                            v-if="project.budget_amount > 0"
                                        >
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "project.budget_percent_used"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    $n(
                                                        project.perc_budget_usage
                                                    )
                                                }}%
                                            </div>
                                        </div>
                                        <div
                                            class="project-stats__box"
                                            v-if="project.budget_amount > 0"
                                        >
                                            <div class="box-title">
                                                {{
                                                    $t("project.budget_balance")
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{
                                                    $n(
                                                        project.remaining_budget_amount,
                                                        {
                                                            style: "currency",
                                                            currency:
                                                                project.currency,
                                                            currencyDisplay:
                                                                "code"
                                                        }
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of right slot -> stats -->
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.user_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_user'
                                            )
                                        "
                                        type="select"
                                        :options="users"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.activity_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_activity'
                                            )
                                        "
                                        type="select"
                                        :options="activities"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.status"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_status'
                                            )
                                        "
                                        type="select"
                                        :options="taskStatus"
                                    />
                                </div>
                                <div
                                    class="col-12 col-md-4"
                                    v-if="!internalCustomer"
                                >
                                    <FormulateInput
                                        v-model="apiSearchFilter.is_billable"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_billable'
                                            )
                                        "
                                        type="select"
                                        :options="isBillable"
                                    />
                                </div>
                            </v-filters>
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="tasks.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.task_date_ext"
                                                @sort="$handleSorting($event)"
                                                >{{ $t("task.date") }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-center">
                                            <v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.is_closed"
                                                @sort="$handleSorting($event)"
                                                ><i
                                                    class="far fa-lock"
                                                ></i></v-sorter
                                        ></v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Activities.name"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.activity")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th v-if="project.enable_milestones"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="ProjectMilestones.name"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.milestone")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Users.name"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.responsible")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-center"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.details"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.description")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            class="text-center"
                                            v-if="!internalCustomer"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.is_billable"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.billable")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-right"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.time_reg"
                                                @sort="$handleSorting($event)"
                                                >{{ $t("task.time") }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            class="text-right"
                                            v-if="!internalCustomer"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="Tasks.task_price"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("task.amount")
                                                }}</v-sorter
                                            ></v-th
                                        >

                                        <v-th></v-th>
                                        <v-th></v-th>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="t in tasks"
                                            :key="t.id"
                                            :class="{
                                                'effect-new-task': t.is_new
                                            }"
                                        >
                                            <v-td>{{
                                                $d(
                                                    new Date(t.task_date),
                                                    "short"
                                                )
                                            }}</v-td>
                                            <v-td class="text-center">
                                                <v-task-status
                                                    :status="t.status"
                                                />
                                            </v-td>
                                            <v-td> {{ t.activity.name }}</v-td>
                                            <v-td
                                                v-if="project.enable_milestones"
                                                ><template
                                                    v-if="
                                                        'project_milestone' in t
                                                    "
                                                    >{{
                                                        t.project_milestone.name
                                                    }}</template
                                                ><template v-else
                                                    >---</template
                                                ></v-td
                                            >
                                            <v-td>{{ t.user.name }}</v-td>
                                            <v-td class="text-center">
                                                <i
                                                    class="fa-sticky-note m-click"
                                                    @click="
                                                        setTaskEditContext(t)
                                                    "
                                                    :class="{
                                                        'fas text-primary':
                                                            t.details,
                                                        'far text-muted': !t.details
                                                    }"
                                                ></i>
                                            </v-td>
                                            <v-td
                                                class="text-center"
                                                v-if="!internalCustomer"
                                                ><template
                                                    v-if="t.is_billable"
                                                    >{{
                                                        $t("task.yes")
                                                    }}</template
                                                >
                                                <template v-else>{{
                                                    $t("task.no")
                                                }}</template></v-td
                                            >
                                            <v-td class="text-right">{{
                                                t.time_reg | toTime
                                            }}</v-td>
                                            <v-td
                                                class="text-right"
                                                v-if="!internalCustomer"
                                            >
                                                <template v-if="t.is_billable">
                                                    {{
                                                        $n(t.task_price, {
                                                            style: "currency",
                                                            currency:
                                                                t.currency,
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}</template
                                                >
                                                <template v-else>---</template>
                                            </v-td>

                                            <v-td>
                                                <i
                                                    @click="
                                                        setTaskEditContext(t)
                                                    "
                                                    class="far fa-fw m-click"
                                                    :class="
                                                        !t.internal_details
                                                            ? 'fa-comment-alt-slash text-muted'
                                                            : 'fa-comment-alt-lines'
                                                    "
                                                ></i>
                                                <i
                                                    class="far fa-fw fa-files"
                                                    :class="
                                                        !t.internal_details
                                                            ? 'text-muted'
                                                            : ''
                                                    "
                                                ></i>
                                            </v-td>
                                            <v-td>
                                                <base-dropdown
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="
                                                            setTaskEditContext(
                                                                t
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_open_modal"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if="canDelete(t)"
                                                        @click="
                                                            handleDeleteTask(t)
                                                        "
                                                        class="dropdown-item text-danger"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "task.action_delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="shortcut-modal">
            <v-task-form
                v-if="showTaskModal"
                :context="taskContext"
                @close="closeTaskModal"
                @save="closeSaveTaskModal"
            />
        </portal>
    </div>
</template>

<style></style>
