import i18n from "@/i18n";

export default {
    activities: {        
        sys_sickness: {
            icon: "fa-clinic-medical",
            text: i18n.t("task.systask_box_sickness_text"),
            showLock: true,
            class: "sickness",
        },
        sys_holidays: {
            icon: "fa-umbrella-beach",
            text: i18n.t("task.systask_box_holidays_text"),
            showLock: true,
            class: "holidays",
        },
        sys_free: {
            icon: "fa-glass-citrus",
            text: i18n.t("task.systask_box_free_days_text"),
            showLock: true,
            class: "holidays",
        },
        sys_child_sick: {
            icon: "fa-clinic-medical",
            text: i18n.t("task.systask_box_child_sick"),
            showLock: true,
            class: "sickness",
        },
        sys_leave: {
            icon: "fa-leaf",
            text: i18n.t("task.systask_box_leave"),
            showLock: true,
            class: "holidays",
        },
        sys_doc: {
            icon: "fa-hand-holding-medical",
            text: i18n.t("task.systask_box_doctor_visits"),
            showLock: true,
            class: "holidays",
        },
        sys_absence: {
            icon: "fa-times-square",
            text: i18n.t("task.systask_absence"),
            showLock: true,
            class: "holidays",
        },
    },
};
