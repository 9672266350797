<script>
import { mapGetters } from "vuex";
import VTaskViewerModal from "@/components/Task/VTaskViewerModal";
import VFilters from "@/components/Search/VFilters";
import ReopenDayService from "@/services/ReopenDayService";
import UserService from "@/services/UserService";
import AppCfg from "@/mixins/AppCfg";
import Messages from "@/mixins/Messages";

export default {
    name: "VAllTasks",
    components: { VFilters, VTaskViewerModal },
    mixins: [AppCfg, Messages],
    data() {
        return {
            loading: false,
            initLoading: false, // for v-filters
            admins: [],
            meta: {},
            status: [],
            reqs: [],
            stats: true,
            showViewerModal: false,
            showContext: null,
        };
    },
    computed: mapGetters("auth", ["getMyId"]),
    async created() {
        this.$aclGuard(this.$ACL.TIMEREG_MGMT);

        this.initLoading = true;
        this.status = this.appCfgRead("ReopenDays.status", true);
        await this.fetchAdmins();
        this.resetSearch();
        this.$apiEnableWatcher();

        this.fetchData();
        this.initLoading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ReopenDayService.GET(params);
                this.reqs = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
        async fetchAdmins() {
            try {
                const r = await UserService.dropdownAdmins();
                this.admins = r.data.data;
            } catch (e) {
                console.log("fetchAdmins exception", e);
            }
        },
        async resetSearch() {
            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "created",
            };

            this.apiSearchFilter = {
                admin_id: "" + this.getMyId,
                status: "1",
            };
        },
        showTasks(req) {
            this.showContext = req;
            this.showViewerModal = true;
        },
        async actionAccept(req) {
            await this.saveReqStatus(2, req.id);
            this.$msgSuccess(this.$t('reopen.notify_accept_request_registered'));            
        },
        async actionReject(req) {
            await this.saveReqStatus(3, req.id);
            this.$msgSuccess(this.$t('reopen.notify_reject_request_registered'));
        },
        async saveReqStatus(status, id) {
            try {
                let data = {
                    id: id,
                    status: status,
                    admin_id: this.getMyId,
                };
                const r = await ReopenDayService.PUT(data);
                let req = r.data.data;
                let idx = this.reqs.findIndex((r) => {
                    return r.id == req.id;
                });

                this.meta.stats.active = parseInt(this.meta.stats.active) - 1;
                this.$set(this.reqs, idx, req);
            } catch (e) {}
        },
    },
};
</script>

<template>
    <div class="v-task-viewer">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4" v-if="true">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="right">
                                    <v-loader
                                        :loading="initLoading"
                                        v-if="initLoading"
                                    />
                                    <div
                                        class="project-stats"
                                        v-else-if="stats"
                                    >
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t("reopen.active_requests")
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{ meta.stats.active }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of right slot -> stats -->

                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.admin_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_administrator'
                                            )
                                        "
                                        type="select"
                                        :options="admins"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.status"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_status'
                                            )
                                        "
                                        type="select"
                                        :options="status"
                                    />
                                </div>
                            </v-filters>
                        </div>

                        <!-- .V-FILTERS -->
                        <template v-if="!loading">
                            <template v-if="reqs.length">
                                <div class="table-responsive">
                                    <v-table class="table table-sm table-mg">
                                        <v-thead>
                                            <v-tr>
                                                <v-th
                                                    ><v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="created"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t("reopen.created")
                                                        }}</v-sorter
                                                    ></v-th
                                                >
                                                <v-th
                                                    ><v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="day_date"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "reopen.date_for_opening"
                                                            )
                                                        }}</v-sorter
                                                    ></v-th
                                                >
                                                <v-th
                                                    ><v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="users.name"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t(
                                                                "reopen.employee"
                                                            )
                                                        }}</v-sorter
                                                    ></v-th
                                                >
                                                <v-th
                                                    ><v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="reason"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t("reopen.reason")
                                                        }}</v-sorter
                                                    ></v-th
                                                >
                                                <v-th
                                                    ><v-sorter
                                                        :paginator="
                                                            apiPaginator
                                                        "
                                                        name="status"
                                                        @sort="
                                                            $handleSorting(
                                                                $event
                                                            )
                                                        "
                                                        >{{
                                                            $t("reopen.status")
                                                        }}</v-sorter
                                                    ></v-th
                                                >
                                                <v-th></v-th>
                                            </v-tr>
                                        </v-thead>

                                        <v-tbody>
                                            <v-tr
                                                v-for="req in reqs"
                                                :key="req.id"
                                            >
                                                <v-td>{{
                                                    $d(
                                                        new Date(req.created),
                                                        "short"
                                                    )
                                                }}</v-td>
                                                <v-td
                                                    >{{
                                                        $d(
                                                            new Date(
                                                                req.day_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </v-td>
                                                <v-td
                                                    >{{ req.user.name }}
                                                </v-td>
                                                <v-td
                                                    ><v-newlines
                                                        :text="req.reason"
                                                /></v-td>
                                                <v-td
                                                    >{{ req.status_name }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{ req.admin.name }}
                                                    </div>
                                                </v-td>
                                                <v-td class="text-right">
                                                    <base-dropdown
                                                        :menu-on-right="true"
                                                        :has-toggle="false"
                                                    >
                                                        <template
                                                            slot="title-container"
                                                            ><i
                                                                class="far fa-ellipsis-h fa-lg"
                                                            ></i
                                                        ></template>
                                                        <div
                                                            @click="
                                                                showTasks(req)
                                                            "
                                                            class="dropdown-item"
                                                        >
                                                            <i
                                                                class="far fa-fw fa-eye"
                                                            ></i>
                                                            {{
                                                                $t(
                                                                    "reopen.action_show_tasks"
                                                                )
                                                            }}
                                                        </div>
                                                        <div
                                                            v-if="
                                                                req.status == 1
                                                            "
                                                            class="dropdown-separator"
                                                        ></div>

                                                        <div
                                                            v-if="
                                                                req.status == 1
                                                            "
                                                            @click="
                                                                actionAccept(
                                                                    req
                                                                )
                                                            "
                                                            class="dropdown-item text-success"
                                                        >
                                                            <i
                                                                class="far fa-fw fa-lock-open"
                                                            ></i>
                                                            {{
                                                                $t(
                                                                    "reopen.action_accept_and_open_day"
                                                                )
                                                            }}
                                                        </div>

                                                        <div
                                                            v-if="
                                                                req.status == 1
                                                            "
                                                            class="dropdown-item text-danger"
                                                            @click="
                                                                actionReject(
                                                                    req
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="far fa-fw fa-vote-nay"
                                                            ></i>
                                                            {{
                                                                $t(
                                                                    "reopen.action_reject"
                                                                )
                                                            }}
                                                        </div>

                                                        <div
                                                            v-if="
                                                                false &&
                                                                req.status == 1
                                                            "
                                                            @click="
                                                                actionCancel(
                                                                    req
                                                                )
                                                            "
                                                            class="dropdown-item text-warning"
                                                        >
                                                            <i
                                                                class="far fa-fw fa-trash"
                                                            ></i>
                                                            {{
                                                                $t(
                                                                    "reopen.action_cancel"
                                                                )
                                                            }}
                                                        </div>
                                                    </base-dropdown>
                                                </v-td>
                                            </v-tr>
                                        </v-tbody>
                                    </v-table>
                                </div>

                                <div class="d-flex justify-content-center mt-4">
                                    <div class="paginator">
                                        <base-pagination
                                            :page-count="
                                                meta.pagination.total_pages
                                            "
                                            :per-page="meta.pagination.per_page"
                                            :value="
                                                meta.pagination.current_page
                                            "
                                            :total="meta.pagination.total"
                                            @input="$handlePagination($event)"
                                        />
                                    </div>
                                </div>
                            </template>
                            <div class="px-4 mt-4" v-else>
                                <div class="alert alert-warning px-4">{{$t('reopen.no_data_found')}}</div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-task-viewer-modal
                v-if="showViewerModal"
                :context="showContext"
                @close="showViewerModal = false"
            />
        </portal>
    </div>
</template>

<style>
</style>