<script>
import ConnectedTasks from "@/components/Projects/Invoicing/ConnectedTasks";
import ConnectedTravels from "@/components/Projects/Invoicing/ConnectedTravels";
import InvoiceService from "@/services/InvoiceService";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VInvoiceLineForm",
    props: {
        invoiceId: {
            description: "Invoice ID - only when creating",
        },
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
            description: "Context",
        },
    },
    components: { ConnectedTasks, ConnectedTravels },
    data() {
        return {
            loading: false,
            editing: false,
            invoiceLine: {
                id: null,
                invoice_id: null,
                line_number: null,
                economic_product: null,
                qty: null,
                price: null,
                price_subtotal: null,
                discount_perc: null,
                invoice_line: null,
                meta_key: null,
            },
            errors: null,
        };
    },
    computed: {
        qty: function () {
            return this.invoiceLine.qty;
        },
        price: function () {
            return this.invoiceLine.price;
        },
        discount_perc: function () {
            return this.invoiceLine.discount_perc;
        },
    },
    watch: {
        qty: function (n) {
            this.updateSubtotal();
        },
        price: function (n) {
            this.updateSubtotal();
        },
        discount_perc: function (n) {
            this.updateSubtotal();
        },
    },
    created() {
        if (!this.context.empty) {
            // Editing a line: it can be anything - task travel
            this.editing = true;
            this.invoiceLine = Cloner.pickClone(this.context, [
                "id",
                "invoice_id",
                "line_number",
                "economic_product",
                "qty",
                "price",
                "price_subtotal",
                "discount_perc",
                "invoice_line",
                "meta_key",
            ]);
        } else {
            // Creating a line
            this.invoiceLine.meta_key = "task";
            this.invoiceLine.invoice_id = this.invoiceId;
        }

        console.log("invoiceLine mode = " + this.editing);
    },
    methods: {
        /**
         * Update subtotals on the invoice line
         */
        updateSubtotal() {
            let qty = this.invoiceLine.qty;
            let price = this.invoiceLine.price * 100;

            if (this.invoiceLine.discount_perc > 0) {
                price = (price * (100 - this.invoiceLine.discount_perc)) / 100;
                //d = p - p*% = p(1 - %)
            }

            let subTotal = (qty * price) / 100;
            subTotal = Math.round(subTotal * 100) / 100;

            console.log("Subtotal", subTotal);
            this.invoiceLine.price_subtotal = subTotal;
        },
        /**
         * Save form and exit ;)
         */
        async saveForm() {
            try {
                let r;
                if (this.editing) {
                    r = await InvoiceService.PUT_editInvoiceLine(
                        this.invoiceLine
                    );
                } else {
                    r = await InvoiceService.POST_createInvoiceLine(
                        this.invoiceLine
                    );
                }

                this.$emit("save", r.data.data);
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        size="full"
        :show="true"
        :show-close="false"
        @close="$emit('close')"
        class="invoice-modal"
    >
        <template slot="header">
            <div class="align-items-center d-flex flex-grow-1">
                <template v-if="editing">
                    {{ $t("project.edit_invoice_line") }}
                </template>
                <template v-else>
                    {{ $t("project.new_invoice_line") }}
                </template>

                <div class="ml-auto">
                    <base-button @click="$emit('close')" type="muted">{{
                        $t("project.cancel")
                    }}</base-button>
                    <base-button type="primary" @click="saveForm"
                        >{{ $t("project.save") }}
                        <i class="fas fa-chevron-right"></i
                    ></base-button>
                </div>
            </div>
        </template>
        <template slot="default">
            <div class="row">
                <div class="col-12">
                    <FormulateForm
                        v-model="invoiceLine"
                        @submit="saveForm"
                        :errors="errors"
                    >
                        <div class="row">
                            <div class="col-3">
                                <FormulateInput
                                    type="multiline"
                                    name="invoice_line"
                                    :label="$t('invoice_line.description')"
                                    :validation-name="
                                        $t('invoice_line.description')
                                    "
                                    validation="required"
                                />
                            </div>
                            <div class="col-1">
                                <FormulateInput
                                    type="groupdecimal"
                                    name="qty"
                                    :precision="2"
                                    align="right"
                                    :label="$t('invoice_line.quantity')"
                                    :validation-name="
                                        $t('invoice_line.quantity')
                                    "
                                    :validation-messages="{
                                        isPositiveInteger: $t(
                                            'validation.number_must_be_a_positive_integer'
                                        ),
                                    }"
                                    validation="required|min:0.01"
                                />
                            </div>
                            <div class="col-2">
                                <FormulateInput
                                    type="groupdecimal"
                                    name="price"
                                    align="right"
                                    :precision="2"
                                    :label="$t('invoice_line.unit_price')"
                                    :validation-name="
                                        $t('invoice_line.unit_price')
                                    "
                                />
                            </div>
                            <div class="col-2">
                                <FormulateInput
                                    type="groupdecimal"
                                    :precision="2"
                                    name="discount_perc"
                                    align="right"
                                    :label="$t('invoice_line.discount')"
                                    :validation-name="
                                        $t('invoice_line.discount')
                                    "
                                    validation="min:0|max:100"
                                />
                            </div>
                            <div class="col">
                                <FormulateInput
                                    type="groupdecimal"
                                    :precision="2"
                                    name="price_subtotal"
                                    align="right"
                                    :label="$t('invoice_line.subtotal')"
                                    :validation-name="
                                        $t('invoice_line.subtotal')
                                    "
                                    :disabled="true"
                                />
                            </div>
                        </div>
                    </FormulateForm>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="editing">
                    <connected-travels :invoice-line-id="context.id" v-if="context.meta_key == 'travel'" />
                    <connected-tasks :invoice-line-id="context.id" v-else-if="context.meta_key == 'task'" />
                </div>
                <div class="col-12" v-if="false">
                    <pre v-if="true">{{ invoiceLine }}</pre>
                </div>
            </div>
        </template>
    </modal>
</template>

<style>
</style>