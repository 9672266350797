<script>
import dayjs from "dayjs";

export default {
    name: "VDueDateColor",
    props: {
        context: {
            description: "Due date",
        },
        active: {
            default: true,
        },
    },
    computed: {
        activeClass: function () {
            if (this.active) {
                return "date-task-active";
            } else {
                return "date-task-inactive";
            }
        },
        colorClass: function () {
            let dt = dayjs(this.context, "YYYY-MM-DD");
            let now = dayjs();

            if (dt.isSame(now, "date")) {
                return "date-today";
            } else if (dt.isBefore(now, "date")) {
                return "date-overdue";
            } else if (
                dt.isAfter(dayjs().add(1, "day"), "date") &&
                dt.isBefore(dayjs().add(4, "day"), "date")
            ) {
                return "date-soon";
            } else if (dt.isAfter(dayjs().add(4, "day"), "date")) {
                return "date-future";
            }

            return {};
        },
    },
};
</script>

<template>
    <span :class="[colorClass, activeClass]">
        <template v-if="context">
            <template v-if="$slots.default">
                <slot name="default" />
            </template>
            {{ $d(new Date(context), "short") }}
        </template>
        <template v-else>---</template>
    </span>
</template>


<style lang="scss">
.date-today {
    color: $warning;
}

.date-overdue {
    color: $danger;
}

.date-soon {
    color: $body-color;
}

.date-future {
    color: $body-color;
}
.date-task-inactive {
    color: $body-color !important;
}

@media (max-width: 992px) {
    .date-future {
        color: #aaabad;
    }
}
</style>