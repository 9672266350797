<script>
import TravelService from "@/services/TravelService";

export default {
    name: "ConnectedTravels",
    props: {
        invoiceLineId: {
            description: "Invoice line ID to check if any tasks are there",
        },
        emptyNotice: {
            type: Boolean,
            default: false,
            description: "Show empty notice if no related elements found",
        },
    },
    data() {
        return {
            loading: false,
            tasks: [],
            meta: null,
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await TravelService.GET({
                    invoice_line_id: this.invoiceLineId,
                });
                this.travels = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="connected-tasks">
        <v-loader :loading="loading" v-if="loading" />
        <div v-else-if="travels.length > 0">
            <h3>{{ $t("travel.travels_connected_to_an_invoice_line") }}</h3>
            <div class="table-responsive">
                <v-table class="table table-mg table-sm">
                    <v-thead>
                        <v-tr>
                            <v-th>{{ $t("travel.date") }}</v-th>
                            <v-th>{{ $t("travel.user") }}</v-th>
                            <v-th>{{ $t("travel.invoice_text") }}</v-th>
                            <v-th class="text-right">{{
                                $t("travel.distance")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travel.amount")
                            }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="travel in travels" :key="travel.id">
                            <v-td>{{
                                $d(new Date(travel.travel_date), "short")
                            }}</v-td>
                            <v-td>{{ travel.user.name }}</v-td>
                            <v-td
                                ><v-newlines :text="travel.invoice_text"
                            /></v-td>
                            <v-td class="text-right">{{ travel.kms }} km</v-td>

                            <v-td class="text-right">{{
                                $n(travel.travel_price, {
                                    style: "currency",
                                    currency: travel.currency,
                                    currencyDisplay: "code",
                                })
                            }}</v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </div>
            <div class="mt-5">
                <div class="paginator d-flex justify-content-center">
                    <base-pagination
                        :page-count="meta.pagination.total_pages"
                        :per-page="meta.pagination.per_page"
                        :value="meta.pagination.current_page"
                        :total="meta.pagination.total"
                        @input="$handlePagination($event)"
                    />
                </div>
            </div>
        </div>
        <div class="alert alert-info alert-outline" v-else-if="emptyNotice">
            {{
                $t(
                    "invoice.message_invoice_line_has_no_connected_elements_custom_line"
                )
            }}
        </div>
    </div>
</template>


<style>
</style>