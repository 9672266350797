<script>
import MultiStat from "@/components/Momentum/MultiStat";
import ProjectMixin from "@/mixins/ProjectMixin";

export default {
    name: "VInfoBar",
    props: {
        project: {
            description: "Project object",
        },
        acl: {
            description:
                "ACL from the parent - whether editing actions can be presented",
        },
    },
    mixins: [ProjectMixin],
    components: { MultiStat },
    data() {
        return {
            showContact: false,
            showCustomerContact: false,
        };
    },
};
</script>

<template>
    <div class="v-infobar" v-if="project">
        <card body-classes="">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex flex-row align-items-center">
                        <h4>{{ $t("project.description") }}</h4>
                        <div class="ml-auto small">
                            <b>{{ $t("project.invoice_setup") }}:</b>
                            {{ project.invoice_setup_text }}
                        </div>
                    </div>
                    <read-more
                        v-if="project.description"
                        :more-str="$t('read_more')"
                        :text="project.description"
                        :max-chars="250"
                    ></read-more>
                    <span class="text-muted" v-else>{{
                        $t("project.no_description")
                    }}</span>
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <!-- infoboxes -->
                        <!-- Case number -->
                        <div class="col-12 col-md-3 v-infobar__infobox">
                            <multi-stat :header="$t('project.case_type')">
                                {{ project.project_type_name }}
                                <template slot="footer"
                                    >{{ $t("project.case_no") }}
                                    {{ project.id }}</template
                                >
                            </multi-stat>
                        </div>
                        <!-- Budget -->
                        <div
                            v-if="project.project_model != 3"
                            class="col-12 col-md-3 v-infobar__infobox"
                        >
                            <multi-stat :header="$t('project.budget')">
                                <template v-if="project.budget_amount">
                                    {{
                                        $n(project.budget_amount, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}
                                    <template slot="footer">
                                        {{ $t("project.project_model") }}:
                                        {{ project.project_model_name }} ({{
                                            project.currency
                                        }})
                                    </template>
                                </template>
                                <template v-else>---</template>
                            </multi-stat>
                        </div>
                        <!-- Deadline -->
                        <div
                            v-if="project.project_model != 3"
                            class="col-12 col-md-3 v-infobar__infobox"
                        >
                            <multi-stat
                                :header="$t('project.deadline')"
                                :box-class="{
                                    'text-danger': project.days_to_deadline < 0,
                                }"
                            >
                                <template v-if="project.deadline_date">
                                    {{
                                        $d(
                                            new Date(project.deadline_date),
                                            "short"
                                        )
                                    }}
                                    <template slot="footer">
                                        <template
                                            v-if="project.days_to_deadline < 0"
                                        >
                                            {{
                                                $tc(
                                                    "project.X_days_past_deadline",
                                                    Math.abs(
                                                        project.days_to_deadline
                                                    )
                                                )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                $tc(
                                                    "project.deadline_in_X_days",
                                                    project.days_to_deadline
                                                )
                                            }}
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    ---
                                    <template slot="footer">---</template>
                                </template>
                            </multi-stat>
                        </div>
                        <!-- Status -->
                        <div class="col-12 col-md-3 v-infobar__infobox">
                            <multi-stat :header="$t('project.status')">
                                <span :class="statusColor">{{
                                    project.status_name
                                }}</span>

                                <template slot="footer">
                                    {{ $t("project.start_date") }}:
                                    <template v-if="project.from_date">
                                        {{
                                            $d(
                                                new Date(project.from_date),
                                                "short"
                                            )
                                        }}
                                    </template>
                                    <template v-else>---</template>
                                </template>
                            </multi-stat>
                        </div>

                        <!-- Responsible -->
                        <div class="col-12 col-md-3 v-infobar__infobox">
                            <multi-stat :header="$t('project.responsible')">
                                <span class="v-infobar__infobox--responsible">{{
                                    project.responsible_user.name
                                }}</span>
                                <template slot="footer">
                                    <span
                                        class="m-click text-primary"
                                        @click="showContact = true"
                                        >{{ $t("project.contact_info") }}</span
                                    >
                                </template>
                            </multi-stat>
                        </div>
                        <!-- Cust contact -->
                        <div
                            v-if="project.project_model != 3"
                            class="col-12 col-md-3 v-infobar__infobox"
                        >
                            <multi-stat
                                :header="$t('project.customer_contact')"
                            >
                                <template v-if="project.cust_contact_person">{{
                                    cust_contact_person
                                }}</template>
                                <template v-else>---</template>
                                <template slot="footer"
                                    ><span
                                        class="m-click text-primary"
                                        @click="showCustomerContact = true"
                                        >{{ $t("project.contact_info") }}</span
                                    ></template
                                >
                            </multi-stat>
                        </div>
                        <!-- KM price -->
                        <div class="col-12 col-md-3 v-infobar__infobox">
                            <multi-stat :header="$t('project.km_price')">
                                <template v-if="project.km_price > 0">
                                    {{
                                        $n(project.km_price, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }} </template
                                ><template
                                    v-else-if="project.use_tax_km_price"
                                    >{{ $t("project.use_tax_rates") }}</template
                                >
                                <template v-else>---</template>
                            </multi-stat>
                        </div>
                        <!-- Currency -->
                        <div class="col-12 col-md-3 v-infobar__infobox">
                            <multi-stat :header="$t('project.discount')">
                                <template v-if="project.discount">
                                    {{ $n(project.discount) }}%
                                </template>
                                <template v-else>---</template>
                            </multi-stat>
                        </div>
                    </div>
                </div>
            </div>
        </card>
        <portal to="modals">
            <modal v-if="showContact" :show="true" @close="showContact = false">
                <template slot="header">{{
                    $t("project.responsible_person_contact_information")
                }}</template>
                <template slot="default">
                    <div class="d-flex justify-content-around">
                        <v-avatar :image="project.responsible_user.avatar" />

                        <dl class="ml-4">
                            <dt>{{ $t("user.name") }}</dt>
                            <dd>{{ project.responsible_user.name }}</dd>

                            <dt>{{ $t("user.email") }}</dt>
                            <dd>{{ project.responsible_user.email }}</dd>
                        </dl>
                    </div>
                </template>
            </modal>
            <modal
                v-if="showCustomerContact"
                :show="true"
                @close="showCustomerContact = false"
            >
                <template slot="header">{{
                    $t("project.customer_contact_information")
                }}</template>
                <template slot="default">
                    <dl class="ml-4">
                        <dt>{{ $t("project.customer_contact_person") }}</dt>
                        <dd>
                            <template v-if="project.cust_contact_person">{{
                                project.cust_contact_person
                            }}</template>
                            <template v-else>---</template>
                        </dd>
                        <dt>{{ $t("project.customer_case_number") }}</dt>
                        <dd>
                            <template v-if="project.cust_case_number">{{
                                project.cust_case_number
                            }}</template>
                            <template v-else>---</template>
                        </dd>
                        <dt>{{ $t("project.customer_email") }}</dt>
                        <dd>
                            <template v-if="project.cust_email">{{
                                project.cust_email
                            }}</template>
                            <template v-else>---</template>
                        </dd>
                        <dt>{{ $t("project.customer_phone") }}</dt>
                        <dd>
                            <template v-if="project.cust_phone">{{
                                project.cust_phone
                            }}</template>
                            <template v-else>---</template>
                        </dd>
                    </dl>
                </template>
            </modal>
        </portal>
    </div>
</template>

<style lang="scss">
.v-infobar__infobox {
    margin-top: 25px;
    margin-bottom: 34px;
}

.info-liner {
    font-size: 15px;
}

.project-description {
    font-size: 15px;
    margin-bottom: 20px;
}

.status-label {
    font-weight: 600;
    color: $headings-color;
}
</style>
