<script>
import TaskService from "@/services/TaskService";

export default {
    props: {
        tasks: {},
        user: {},
        project: {}
    },
    data() {
        return {
            selections: [],
            selectAll: false,
            newPrice: "",
            updating: false
        };
    },
    computed: {
        canSave() {
            if (this.newPrice && this.selections.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        hasMultiplePrices() {
            const uniquePrices = [
                ...new Set(this.tasks.map(task => task.hour_price))
            ];

            // If there is more than one unique price, return true
            return uniquePrices.length > 1;
        }
    },
    watch: {
        selectAll: function(nV) {
            this.selections = [];
            if (nV === true) {
                this.tasks.forEach(t => {
                    this.selections.push(t.id);
                });
            }
        }
    },
    created() {},
    methods: {
        async updatePrices() {
            this.updating = true;
            let payload = {
                price: this.newPrice,
                user_id: this.user.id,
                tasks: this.selections
            };

            try {
                const { data } = await TaskService.PUT_updatePrices(payload);
                this.$emit("save-refresh");
            } catch (e) {
                console.log("up p e", e);
            }

            this.updating = false;
        }
    }
};
</script>

<template>
    <card body-classes="px-0" class="v-tabler">
        <div class="d-flex align-items-center pb-4">
            <div class="col-7">
                <h3 :class="{ 'text-danger': hasMultiplePrices }">
                    {{ user.name }}:
                    <span class="">{{
                        $n(user.hour_price, {
                            style: "currency",
                            currency: project.currency,
                            currencyDisplay: "code"
                        })
                    }}</span>
                </h3>
                <div v-if="hasMultiplePrices" class="text-danger text-bold">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{ $t("task.warning_has_multiple_prices") }}
                </div>
            </div>
            <div class="col-3">
                <FormulateInput
                    type="currency"
                    :currency="project.currency"
                    v-model="newPrice"
                    validation="required"
                    :placeholder="
                        $t(
                            'task.placeholder_select_tasks_and_enter_new_hour_rate'
                        )
                    "
                />
            </div>
            <div class="col">
                <base-button
                    class=""
                    :disabled="!canSave"
                    @click="updatePrices"
                    :loading="updating"
                    >{{ $t("task.update") }}</base-button
                >
            </div>
        </div>

        <v-table class="table table-mg table-sm table-manager table-bordered">
            <v-thead>
                <v-tr>
                    <v-th style="width: 60px"
                        ><input type="checkbox" v-model="selectAll"
                    /></v-th>
                    <v-th style="width: 130px">{{ $t("task.id") }}</v-th>
                    <v-th style="width: 130px">{{ $t("task.date") }}</v-th>
                    <v-th style="width: 100px" class="text-right">{{
                        $t("task.used_time")
                    }}</v-th>
                    <v-th style="width: 130px" class="text-right">{{
                        $t("task.hour_rate")
                    }}</v-th>
                    <v-th style="width: 130px" class="text-right">{{
                        $t("task.task_price")
                    }}</v-th>
                    <v-th style="">{{ $t("task.activity") }}</v-th>
                    <v-th style="" class="td-thin"></v-th>
                    <v-th style="" class="td-thin"></v-th>
                    <v-th style="" class="td-thin"></v-th>
                </v-tr>
            </v-thead>
            <v-tbody>
                <v-tr v-for="t in tasks" :key="t.id">
                    <v-td>
                        <input
                            type="checkbox"
                            v-model="selections"
                            :value="t.id"
                        />
                    </v-td>
                    <v-td>#{{ t.id }}</v-td>
                    <v-td>{{ $d(new Date(t.task_date), "short") }}</v-td>
                    <v-td class="text-right">{{ t.time_reg | toTime }}</v-td>

                    <v-td class="text-right">{{
                        $n(t.hour_price, {
                            style: "currency",
                            currency: t.currency,
                            currencyDisplay: "code"
                        })
                    }}</v-td>
                    <v-td class="text-right">{{
                        $n(t.task_price, {
                            style: "currency",
                            currency: t.currency,
                            currencyDisplay: "code"
                        })
                    }}</v-td>
                    <v-td>{{ t.activity.name }}</v-td>

                    <v-td class="td-thin">
                        <template v-if="t.is_internal">I</template>
                        <template v-else>B</template>
                    </v-td>
                    <v-td class="td-thin"
                        ><i
                            class="fa"
                            :class="t.is_closed ? 'fa-lock' : 'fa-unlock'"
                        ></i
                    ></v-td>
                    <v-td class="td-thin"
                        ><i
                            class="fa"
                            :class="t.is_billable ? 'fa-check' : 'fa-times'"
                        ></i
                    ></v-td>
                </v-tr>
            </v-tbody>
        </v-table>
    </card>
</template>

<style lang="scss">
.v-tabler {
    .form-group {
        margin-bottom: 0px !important;
    }
}
.table-manager {
    table-layout: fixed;

    .td-thin {
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center;
        width: 30px !important;
    }
}
</style>
