<script>
import VExpenseForm from "@/components/Forms/VExpenseForm";
export default {
    name: "VExpenses",
    props: {
        project_id: {
            description: "Project ID from the navigation",
        },
    },
    components: { VExpenseForm },
    data() {
        return {
            expenseModal: false,
            editContext: {empty: true},
        }
    },
    methods: {
        closeSave() {

        }
    }
};
</script>

<template>
    <div class="v-expenses">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col">
                    <v-wip @click.native="expenseModal = false" />
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-expense-form
                v-if="expenseModal"
                :context="editContext"
                :project_id="project_id"
                :is-billable="1"
                @close="expenseModal = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>

<style>
</style>
