<script>
import TaskService from "@/services/TaskService";
import VTabler from "@/views/Projects/Tabs/components/VTabler";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import ProjectService from "@/services/ProjectService";
import VFilters from "@/components/Search/VFilters";

export default {
    props: {
        project_id: {}
    },
    mixins: [DateRangeMixin],
    components: { VTabler, DateRangePicker, VFilters },
    data() {
        return {
            loading: true,
            tasks: [],
            project: {},
            users: {}
        };
    },
    async created() {
        this.resetSearch();
        await this.fetchProject();
        await this.fetchData();
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const { data } = await TaskService.GET_manager(
                    this.project_id,
                    params
                );
                this.tasks = data;
            } catch (e) {
                console.log("fe e", e);
            }

            this.loading = false;
        },
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id, {
                    includes: "users"
                });
                this.project = r.data.data;
                this.project.users.forEach(u => {
                    this.users[u.id] = u;
                });
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        async resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };
        }
    }
};
</script>

<template>
    <div>
        <div class="container-fluid">
            <base-header class="pb-1" type=""
                ><bread-bag></bread-bag
            ></base-header>
            <div class="row card-wrapper">
                <div class="col-12">
                    <card>
                        <v-filters
                            :search="false"
                            :enable-filters="true"
                            :start-open="true"
                            @reset-search="resetSearch"
                        >
                            <div class="col-4">
                                <date-range-picker
                                    class="range-picker"
                                    ref="picker"
                                    opens="right"
                                    :timePicker="false"
                                    :showWeekNumbers="true"
                                    :autoApply="true"
                                    v-model="apiSearchFilter.dateRange"
                                    :linkedCalendars="true"
                                    :locale-data="getDateRangePickerLocale()"
                                    :ranges="getDateRangePickerRanges()"
                                >
                                    <template
                                        v-slot:input="picker"
                                        style="min-width: 350px"
                                    >
                                        <template v-if="picker.startDate">
                                            {{ $d(picker.startDate, "short") }}
                                            -
                                            {{ $d(picker.endDate, "short") }}
                                        </template>
                                    </template>
                                </date-range-picker>
                            </div>
                        </v-filters>
                    </card>
                </div>

                <v-loader v-if="loading" :loading="loading" />
                <template v-else-if="!Array.isArray(tasks)">
                    <div class="col-12" v-for="(gt, idx) in tasks" :key="idx">
                        <v-tabler
                            :tasks="gt.tasks"
                            :user="users[gt.user_id]"
                            :project="project"
                            @save-refresh="fetchData"
                        />
                    </div>
                </template>
                <div class="col-12" v-else>
                    <card>
                        <div class="alert alert-warning">
                            {{ $t("task.no_tasks_were_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss"></style>
