<script>
import ConnectedTasks from "@/components/Projects/Invoicing/ConnectedTasks";
import ConnectedTravels from "@/components/Projects/Invoicing/ConnectedTravels";
import InvoiceService from "@/services/InvoiceService";
import Cloner from "@/libraries/Cloner";

export default {
    name: "LineViewer",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
            description: "Context",
        },
        invoice: {

        },

    },
    components: { ConnectedTasks, ConnectedTravels },
    data() {
        return {
            loading: false,
        };
    },
};
</script>

<template>
    <modal
        :loading="loading"
        size="full"
        :show="true"
        :show-close="true"
        @close="$emit('close')"
        class="invoice-modal"
    >
        <template slot="header">
            <div class="align-items-center d-flex flex-grow-1">
                {{ $t("project.view_invoice_line") }}
            </div>
        </template>
        <template slot="default">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <v-table class="table table-mg table-hover">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{$t('invoice.lnr')}}</v-th>
                                    <v-th>{{ $t("invoice_line.type") }}</v-th>
                                    <v-th>{{
                                        $t("invoice_line.description")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice_line.quantity")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice_line.unit_price")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice_line.discount")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoice_line.total")
                                    }}</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr>
                                    <v-td>{{ context.line_number }}</v-td>
                                    <v-td>
                                        {{ context.meta_key_name }}
                                    </v-td>
                                    <v-td
                                        ><v-newlines :text="context.invoice_line"
                                    /></v-td>
                                    <v-td class="text-right">{{
                                        $n(context.qty)
                                    }}</v-td>
                                    <v-td class="text-right">{{
                                        $n(context.price, {
                                            style: "currency",
                                            currency: invoice.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td>
                                    <v-td class="text-right"
                                        >{{ $n(context.discount_perc) }}%</v-td
                                    >
                                    <v-td class="text-right">{{
                                        $n(context.price_subtotal, {
                                            style: "currency",
                                            currency: invoice.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <connected-travels
                        :invoice-line-id="context.id"
                        v-if="context.meta_key == 'travel'"
                        empty-notice
                    />
                    <connected-tasks
                        :invoice-line-id="context.id"
                        v-else-if="context.meta_key == 'task'"
                        empty-notice
                    />

                </div>
                <div class="col-12" v-if="false">
                    <pre v-if="true">{{ invoiceLine }}</pre>
                </div>
            </div>
        </template>
    </modal>
</template>

<style>
</style>
