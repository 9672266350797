<script>
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VProjectMemberForm",

    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        project_id: {},
    },
    data() {
        return {
            editing: false,
            loading: false,
            innerLoading: false,
            errors: null,

            users: [],

            /* V-model */
            id: null, // junction model ID entry for editing
            user_id: null,
            default_hour_price: null,
            discount_percent: null,
            hour_price: null,
            currency: null,
            converted: null,
            // Project discount
            discount: null,
        };
    },
    watch: {
        user_id: function (val, oldVal) {
            if (val > 0) {
                if (this.editing) {
                } else {
                    this.fetchPrices();
                }
            }
        },
        discount_percent: function (val) {
            this.recalculatePrice();
        },
        default_hour_price: function (val, old) {
            if (val != old) {
                console.log("RECALC", val, old);
                this.recalculatePrice();
            }
        },
    },
    async created() {
        await this.fetchDiscount();
        await this.fetchUsers();

        //console.log("VProjectMember: " + this.project_id);
        // Insert context
        if (!this.context.empty) {
            // EDITING
            this.editing = true;
            this.users = [
                {
                    label: this.context.user.name,
                    value: this.context.user.id,
                },
            ];
            this.user_id = this.context.user_id + "";
            console.log(this.users, this.user_id, this.context);
            this.fetchAssignment(this.project_id, this.user_id);
        } else {
            // CREATE NEW!
            this.editing = false;
        }
    },

    methods: {
        async checkAcl() {},
        recalculatePrice() {
            let num =
                this.default_hour_price -
                this.default_hour_price * (this.discount_percent / 100);
            this.hour_price = Number.parseFloat(num).toFixed(2);
        },
        /**
         * Save/edit form action
         */
        async saveForm() {
            let payload = this.buildPayload();

            try {
                this.loading = true;

                if (this.editing) {
                    const r = await ProjectService.PUT_editAssignment(payload);
                } else {
                    const r = await ProjectService.POST_assignUser(payload);
                }

                this.$emit("close-refresh");
            } catch (e) {
                console.log("saveForm exception", e);
                this.errors = e.response.data.errors;
            }
            this.loading = false;
        },
        /**
         * Fetch project discount percent
         */
        async fetchDiscount(loading = true) {
            try {
                this.loading = true;
                const r = await ProjectService.GET_discount(this.project_id);
                this.discount = r.data.data.discount;
            } catch (e) {
                console.log("Exception discount", e);
            }
            this.loading = loading;
        },
        /**
         * Fetch assignment data directly from the database
         */
        async fetchAssignment(project_id, user_id) {
            try {
                this.loading = true;
                const r = await ProjectService.GET_fetchAssignment(
                    project_id,
                    user_id
                );
                console.log("RAW DATA", r);
                this.setFromPayload(r.data.data);
            } catch (e) {
                console.log("fetchAssignment exception", e);
            }
            this.loading = false;
        },
        /**
         * Set data from payload received from the assignment APi
         */
        setFromPayload(p) {
            console.log("setFromPayload", p);
            this.id = p.id;
            this.default_hour_price = p.default_hour_price;
            this.hour_price = p.hour_price;
            this.discount_percent = p.discount_percent;
        },
        /**
         * Build payload for sending out
         */
        buildPayload() {
            let payload = {
                user_id: this.user_id,
                project_id: this.project_id,
                default_hour_price: this.default_hour_price,
                hour_price: this.hour_price,
                discount_percent: this.discount_percent,
            };

            if (this.editing) {
                payload["id"] = this.id;
            }

            return payload;
        },
        async fetchUsers() {
            try {
                this.loading = true;
                const r = await ProjectService.GET_availableUsersDropdown(
                    this.project_id
                );
                this.users = r.data.data;
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        /**
         * Fetch default prices
         */
        async fetchPrices() {
            try {
                this.innerLoading = true;
                const r = await ProjectService.GET_defaultHourPrice(
                    this.project_id,
                    this.user_id
                );
                let d = r.data.data;
                this.default_hour_price = d.default_hour_price;

                console.log("DISCOUNT", this.discount);
                if (this.discount > 0) {
                    this.discount_percent = this.discount;
                } else {
                    this.discount_percent = 0;
                }

                this.currency = d.currency;
                this.converted = d.converted;
            } catch (e) {
                console.log("fetchPrices", e);
            }
            this.innerLoading = false;
        },
        /**
         * Fetch existing prices for a user that is part of the project
         */
        async fetchExistingPrices() {
            try {
                this.innerLoading = true;
            } catch (e) {
                console.log("FetchExisting", e);
            }
            this.innerLoading = false;
        },
    },
};
</script>

<template>
    <modal
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="currency-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("project.edit_member") }}
            </template>
            <template v-else>
                {{ $t("project.add_member") }}
            </template>
        </template>
        <template slot="default">
            <FormulateForm @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            v-model="user_id"
                            name="user_id"
                            type="multiselect"
                            :options="users"
                            :label="$t('project.user_name')"
                            :validation-name="$t('project.user_name')"
                            validation="required"
                            :help="
                                editing == false
                                    ? $t(
                                          'project.help_only_users_with_hour_price'
                                      )
                                    : ''
                            "
                        />
                    </div>
                </div>

                <template v-if="user_id > 0">
                    <v-loader :loading="innerLoading" v-if="innerLoading" />
                    <template v-else>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <FormulateInput
                                    type="currency"
                                    :currency="currency"
                                    name="default_hour_price"
                                    v-model="default_hour_price"
                                    :label="$t('project.default_hour_price')"
                                    :validation-name="
                                        $t('project.default_hour_price')
                                    "
                                    :help="
                                        converted
                                            ? $t(
                                                  'project.help_hour_price_is_currency_converted'
                                              )
                                            : ''
                                    "
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <FormulateInput
                                    type="groupdecimal"
                                    name="discount_percent"
                                    append="%"
                                    v-model="discount_percent"
                                    :label="$t('project.discount_percent')"
                                    :validation-name="
                                        $t('project.discount_percent')
                                    "
                                    validation="required|number|between:-1,101"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="text-warning" v-if="editing">
                                    <i class="far fa-exclamation-triangle"></i>
                                    {{
                                        $t(
                                            "project.text_when_editing_user_hour_rate_applies_in_the_future"
                                        )
                                    }}
                                </div>
                                <hr />
                            </div>
                            <div class="col-12 col-md-6">
                                <FormulateInput
                                    type="currency"
                                    :currency="currency"
                                    name="hour_price"
                                    v-model="hour_price"
                                    :label="$t('project.effective_hour_price')"
                                    :validation-name="
                                        $t('project.effective_hour_price')
                                    "
                                    :disabled="true"
                                    :help="
                                        $t('project.help_effective_hour_price')
                                    "
                                />
                            </div>
                        </div>

                        <div class="text-right mt-4">
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="$emit('close')"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button
                                type="primary"
                                :loading="loading"
                                native-type="submit"
                                >{{ $t("save") }}</base-button
                            >
                        </div>
                    </template>
                </template>
            </FormulateForm>
        </template>
    </modal>
</template>


<style>
</style>
