<script>
import { mapGetters } from "vuex";
import Cloner from "@/libraries/Cloner";
import MGConsts from "@/mixins/MGConsts";
import AppCfg from "@/mixins/AppCfg";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";

export default {
    name: "VEditProjectMilestoneForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    mixins: [MGConsts, AppCfg],
    data() {
        return {
            loading: true,
            errors: null,
            milestone: {
                id: null,
                name: null,
                comment: null,
                status: null
            }
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        this.milestone = Cloner.pickClone(this.context, [
            "id",
            "created",
            "modified",
            "name",
            "comment",
            "status"
        ]);

        this.loading = false;
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                const r = await ProjectMilestoneService.PUT(this.milestone);

                this.$notify({
                    message: this.$t(
                        "project_milestone.notification_milestone_updated"
                    ),
                    timeout: 8000,
                    icon: "far fa-check",
                    type: "success"
                });
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
    >
        <template slot="header">
            {{ $t("project_milestones.edit") }}
        </template>

        <template slot="default">
            <FormulateForm
                v-model="milestone"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="fselect"
                            :options="
                                appCfgRead('ProjectMilestones.status', true)
                            "
                            name="status"
                            :placeholder="
                                $t('project_milestone.placeholder_status')
                            "
                            validation="required"
                            :validation-name="$t('project_milestone.status')"
                            :help="$t('project_milestone.help_status')"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('project_milestone.name')"
                            :help="$t('project_milestone.help_name')"
                            :disabled="true"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="comment"
                            type="textarea"
                            :label="$t('project_milestone.internal_note')"
                            :help="$t('project_milestone.help_internal_note')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
