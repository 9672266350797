var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-hours-week row"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('h3',{staticClass:"mb-0"},[_c('i',{staticClass:"far fa-calendar-week"}),_vm._v(" "+_vm._s(_vm.$t("dashboard.weekly_overview"))+" ")]),_c('div',{staticClass:"ml-3"},[_c('date-picker',{staticClass:"v-range-picker",attrs:{"attributes":_vm.ranges,"show-weeknumbers":"","model-config":{
                                type: 'string',
                                mask: 'YYYY-MM-DD',
                            },"is-required":true,"popover":{
                                positionFixed: true,
                                visibility: 'focus',
                                keepVisibleOnInput: false,
                                transition: 'none',
                                showDelay: 1,
                                hideDelay: 1,
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"v-hours-week__datepicker m-click",on:{"click":function($event){return togglePopover({
                                            placement: 'bottom',
                                        })}}},[_vm._v(" "+_vm._s(_vm.$d( new Date(_vm.dateRange.startDate), "short" ))+" - "+_vm._s(_vm.$d( new Date(_vm.dateRange.endDate), "short" ))+" "),_c('i',{staticClass:"ml-1 fas fa-caret-down"})])]}}]),model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),(false)?_c('div',{staticClass:"v-dash-close-btn"},[_c('i',{staticClass:"far fa-times fa-lg"})]):_vm._e()]),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):_c('div',{staticClass:"row v-hours-week__statboxes"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-3",on:{"mouseenter":function($event){_vm.showTarget = true},"mouseleave":function($event){_vm.showTarget = false}}},[_c('inner-dash-card',{attrs:{"title":_vm.$t('dashboard.statbox_total_time'),"icon":"far fa-stopwatch"}},[_c('template',{slot:"default"},[_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm._f("toTime")(_vm.stats.total_time)))])]),_c('template',{slot:"action"},[(parseInt(_vm.stats.period_goal) > 0)?[(!_vm.showTarget)?_c('span',{class:{
                                            'text-warning':
                                                _vm.goalCount(
                                                    _vm.stats.total_time,
                                                    _vm.stats.period_goal
                                                ) > 0,
                                            'text-success':
                                                _vm.goalCount(
                                                    _vm.stats.total_time,
                                                    _vm.stats.period_goal
                                                ) <= 0,
                                        }},[_vm._v(" "+_vm._s(_vm.$t("dashboard.goal"))+": "),(
                                                _vm.goalCount(
                                                    _vm.stats.total_time,
                                                    _vm.stats.period_goal
                                                ) == 0
                                            )?[_vm._v(" "+_vm._s(_vm._f("toTime")(0))+" ")]:(
                                                _vm.goalCount(
                                                    _vm.stats.total_time,
                                                    _vm.stats.period_goal
                                                ) < 0
                                            )?[_vm._v(" +"+_vm._s(_vm._f("toTime")(_vm.goalCount( _vm.stats.total_time, _vm.stats.period_goal )))+" ")]:[_vm._v(" -"+_vm._s(_vm._f("toTime")(_vm.goalCount( _vm.stats.total_time, _vm.stats.period_goal )))+" ")]],2):_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.weekly_goal"))+": "+_vm._s(_vm._f("toTime")(_vm.stats.period_goal)))])]:_vm._e()],2)],2)],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},[_c('inner-dash-card',{attrs:{"title":_vm.$t(
                                    'dashboard.statbox_customer_related_time'
                                ),"icon":"far fa-building"}},[_c('template',{slot:"default"},[_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm._f("toTime")(_vm.stats.total_ext_time)))])]),_c('template',{slot:"action"},[_c('span',{staticClass:"text-muted m-click"},[_vm._v(_vm._s(_vm.$n(_vm.stats.total_ext_time_perc))+"% "+_vm._s(_vm.$t("dashboard.of_total")))])])],2)],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},[_c('inner-dash-card',{attrs:{"title":_vm.$t('dashboard.statbox_internal_time'),"icon":"far fa-house"}},[_c('template',{slot:"default"},[_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm._f("toTime")(_vm.stats.total_int_time)))])]),_c('template',{slot:"action"},[_c('span',{staticClass:"text-muted m-click"},[_vm._v(_vm._s(_vm.$n(_vm.stats.total_int_time_perc))+"% "+_vm._s(_vm.$t("dashboard.of_total")))])])],2)],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},[_c('inner-dash-card',{attrs:{"title":_vm.$t('dashboard.other_time'),"icon":"far fa-user-slash"}},[_c('template',{slot:"default"},[_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(_vm._f("toTime")(_vm.stats.total_other_time)))])]),_c('template',{slot:"action"},[_c('span',{staticClass:"text-muted m-click"},[_vm._v(_vm._s(_vm.$n(_vm.stats.total_other_time_perc))+"% "+_vm._s(_vm.$t("dashboard.of_total")))])])],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 v-hours-week__graph"},[_c('div',{staticStyle:{"display":"block"}},[_c('apexchart',{attrs:{"height":"400px","options":_vm.options,"series":_vm.series}})],1)])])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }