<script>
import InvoiceOverview from "@/components/Projects/InvoiceOverview";
import InnerDashCard from "@/components/Cards/InnerDashCard";
import VFilters from "@/components/Search/VFilters";
import CurrencyHumanizer from "@/util/MillionHumanizer";

import ProjectService from "@/services/ProjectService";
import CustomerService from "@/services/CustomerService";
import UserService from "@/services/UserService";

import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VAllCases",
    mixins: [AppCfg],
    components: { InvoiceOverview, VFilters, InnerDashCard },

    data() {
        return {
            loading: false,
            showColumnHelp: false,
            projects: [],
            customers: [],
            users: [],
            cases: [],
            disableProjects: false,
            disableCustomers: false,
            stats: {},
        };
    },
    computed: {
        w_customer_id: function () {
            return this.apiSearchFilter.customer_id;
        },
        w_project_id: function () {
            return this.apiSearchFilter.project_id;
        },
        currentExportUrl: function () {
            let params = this.$buildParams();
            delete params.sort;
            delete params.direction;
            delete params.page;

            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/projects/full-overview"
            );
            url.searchParams.append("csv", 1);
            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    console.log("|pa", param, params[param]);
                    if (Array.isArray(params[param])) {
                        params[param].forEach(function (value) {
                            url.searchParams.append(param + "[]", value);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        },
    },
    watch: {
        w_customer_id: function (nv) {
            if (String(nv) !== "") {
                this.apiSearchFilter.project_id = "";
                this.disableProjects = true;
                this.disableCustomers = false;
            } else {
                if (
                    String(this.apiSearchFilter.project_id) === "" &&
                    String(this.apiSearchFilter.customer_id) === ""
                ) {
                    this.disableProjects = false;
                    this.disableCustomers = false;
                }
            }
        },
        w_project_id: function (nv) {
            if (String(nv) !== "") {
                this.apiSearchFilter.customer_id = "";
                this.disableProjects = false;
                this.disableCustomers = true;
            } else {
                if (
                    String(this.apiSearchFilter.project_id) === "" &&
                    String(this.apiSearchFilter.customer_id) === ""
                ) {
                    this.disableProjects = false;
                    this.disableCustomers = false;
                }
            }
        },
    },
    async created() {
        this.loading = true;
        this.apiSearchFilter = this.initSearch();
        this.$apiEnableWatcher();
        await this.fetchCustomers(true);
        await this.fetchUsers(true);
        await this.fetchProjects(true);
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch projects
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ProjectService.GET_fullOverview(params);
                this.projects = r.data.data;
                this.stats = r.data.meta.stats;
            } catch (e) {
                console.log("Project exception", e);
            }
            this.loading = false;
        },
        initSearch() {
            return {
                search: "",
                customer_id: "",
                user_id: "",
                project_model: "",
                project_id: "",
            };
        },
        resetSearch() {
            this.$resetSearch();
            this.apiSearchFilter = this.initSearch();
        },
        /**
         * Fetch dropdown data
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {
                console.log("fetchCustomers E", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch projects
         */
        async fetchProjects(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GET_dropdown({
                    status: [1, 5],
                    project_model: [1, 2],
                });
                this.cases = r.data.data;
            } catch (e) {
                console.log("fetchProjects E", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch users
         */
        async fetchUsers(loading = true) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers E", e);
            }

            this.loading = loading;
        },

        humanize(number) {
            return CurrencyHumanizer(number, "DKK");
        },
    },
};
</script>

<template>
    <div class="v-projects">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <!-- Stats -->
                    <div class="row" v-else-if="stats">
                        <div class="col-12 col-md-3 col-lg-3">
                            <inner-dash-card
                                :title="$t('projects.statbox_invoiced')"
                                icon="far fa-money-bill"
                            >
                                <template slot="default">
                                    {{
                                        $n(stats.invoiced, {
                                            style: "currency",
                                            currency: "DKK",
                                            currencyDisplay: "code",
                                        })
                                    }}
                                </template>
                            </inner-dash-card>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <inner-dash-card
                                :title="$t('projects.statbox_ready_to_invoice')"
                                icon="far fa-funnel-dollar"
                            >
                                <template slot="default">
                                    {{
                                        $n(stats.ready, {
                                            style: "currency",
                                            currency: "DKK",
                                            currencyDisplay: "code",
                                        })
                                    }}
                                </template>
                            </inner-dash-card>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <inner-dash-card
                                :title="$t('projects.statbox_drafts')"
                                icon="far fa-funnel-dollar"
                            >
                                <template slot="default">
                                    {{
                                        $n(stats.draft, {
                                            style: "currency",
                                            currency: "DKK",
                                            currencyDisplay: "code",
                                        })
                                    }}
                                </template>
                            </inner-dash-card>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <inner-dash-card
                                :title="$t('projects.statbox_not_invoiced')"
                                icon="far fa-funnel-dollar"
                            >
                                <template slot="default">
                                    {{
                                        $n(stats.to_invoice, {
                                            style: "currency",
                                            currency: "DKK",
                                            currencyDisplay: "code",
                                        })
                                    }}
                                </template>
                            </inner-dash-card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        v-model="apiSearchFilter.search"
                        :start-open="true"
                        :search="true"
                        :search-placeholder="$t('projects.placeholder_search')"
                        @reset-search="resetSearch"
                    >
                        <template slot="right">
                            <a
                                :href="currentExportUrl"
                                class="btn btn-primary"
                                target="_blank"
                                >{{ $t("projects.download_as_csv") }}</a
                            ></template
                        >
                        <template slot="default">
                            <div class="col-lg-4 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.customer_id"
                                    :placeholder="$t('projects.customer')"
                                    type="multiselect"
                                    :multiple="true"
                                    :options="customers"
                                    :disabled="disableCustomers"
                                />
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.project_id"
                                    :placeholder="$t('projects.case')"
                                    type="multiselect"
                                    :multiple="true"
                                    :options="cases"
                                    :disabled="disableProjects"
                                />
                            </div>
                            <div class="col-lg-4 col-md-5 col-sm-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.user_id"
                                    :placeholder="$t('projects.responsible')"
                                    type="multiselect"
                                    :multiple="true"
                                    :options="users"
                                />
                            </div>
                            <div class="col-lg-4 col-md-5 col-sm-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.project_model"
                                    :placeholder="$t('projects.project_model')"
                                    type="select"
                                    :options="
                                        appCfgRead(
                                            'Projects.project_model',
                                            true
                                        )
                                    "
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>
            </div>
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <card body-classes="px-0" v-else-if="projects.length">
                        <div
                            class="px-4 mb-4 m-click text-primary"
                            @click="showColumnHelp = !showColumnHelp"
                        >
                            {{ $t("projects.read_more_about_columns") }}
                        </div>
                        <invoice-overview :projects="projects" />
                    </card>
                    <card body-classes="px-0" v-else>
                        <div class="pb-mg">
                            <div class="nice-info">
                                {{ $t("projects.no_projects") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <modal
                :show="true"
                @close="showColumnHelp = false"
                size="xl"
                v-if="showColumnHelp"
            >
                <template slot="header">
                    {{ $t("projects.header_column_help") }}
                </template>

                <template slot="default">
                    <ul class="help-content">
                        <li>{{ $t("projects.help_column_budget") }}</li>
                        <li>{{ $t("projects.help_column_invoiced") }}</li>
                        <li>{{ $t("projects.help_column_readytoinvoice") }}</li>
                        <li>{{ $t("projects.help_column_draft") }}</li>
                        <li>{{ $t("projects.help_column_notinvoiced") }}</li>
                    </ul>
                </template>
            </modal>
        </portal>
    </div>
</template>


<style lang="scss">
.v-filters {
    @media (max-width: 767.98px) {
        &__container {
            &__right {
                & > span {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.table-responsive {
    overflow-x: auto !important;
}

.column-help-text {
    color: $primary;
}

.help-content li {
    margin-bottom: 20px;
}
</style>
