<script>
import ReopenDayService from "@/services/ReopenDayService";
import VTaskTable from "@/components/Task/VTaskTable";

export default {
    name: 'VTaskViewerModal',
    props: {
        context: {
            description: "Reopen object"
        }
    },
    components: {VTaskTable},
    data() {
        return {
            loading: false,
            tasks: [],
        }
    },
    async created() {
        this.loading = true;
        await this.fetchData(this.context.id); 
        this.loading = false;
    },
    methods: {
        async fetchData(id) {
            try {
                const r = await ReopenDayService.GET_fetchTasks(id);
                this.tasks = r.data.data;
            } catch (e) {
                console.log("fetchData exception",e);
            }
        }
    }

}
</script>

<template>
  <modal
        :show="true"
        size="xxl"
        @close="$emit('close')"        
        :loading="loading"
    >
        <template slot="header">
            {{context.user.name}}: {{$t('reopen.view_day_details_for_date_D', {D: $d(new Date(context.day_date), 'short')})}}
        </template>

        <template slot="default">
            <p>
              <i class="far fa-exclamation-triangle text-bold text-warning"></i>  {{$t('reopen.text_only_not_invoiced_tasks_will_be_reopen')}}
            </p>
            <v-task-table :tasks="tasks" read-only />
        </template>
  </modal>
</template>

<style>

</style>