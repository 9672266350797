<script>
import VProject from "@/components/Projects/VProject";
import VFilters from "@/components/Search/VFilters";
import VProjectFilter from "@/components/Search/VProjectFilter";
import VDeptFilter from "@/components/Search/VDeptFilter";
import VProjectForm from "@/components/Forms/VProjectForm";

import CustomerService from "@/services/CustomerService";
import ProjectService from "@/services/ProjectService";
import CurrencyHumanizer from "@/util/MillionHumanizer";

import { mapGetters } from "vuex";

export default {
    name: "VIndex",
    components: {
        VProject,
        VProjectForm,
        VFilters,
        VProjectFilter,
        VDeptFilter,
    },
    watch: {
        $route: function (to) {
            // Watch for route changes. Check for "create" in query params.
            this.createCase(to);
        },
    },
    data() {
        return {
            loading: false,
            projects: [],
            meta: null,
            addProject: false,
            addSection: false,
            customers: [],

            stats: {
                budget: 0,
                budget_used: 0,
                perc_over_budget: 0,
                past_deadline: 0,
            },

            filterViews: {
                actawait: this.$t("projects.filter_active_and_awaiting"),
                act: this.$t("projects.filter_active"),
                awaiting: this.$t("projects.filter_awaiting"),
                fin: this.$t("projects.filter_finished"),
                ccl: this.$t("projects.filter_cancelled"),
                all: this.$t("projects.filter_all"),
            },

            sortViews: {
                null: this.$t("projects.sort_default"),
                "Customers.name": this.$t("projects.sort_customer"),
                "Projects.title": this.$t("projects.sort_title"),
                "Projects.deadline_date": this.$t("projects.sort_deadline"),
                "Projects.status": this.$t("projects.sort_status"),
                "Projects.budget_amount": this.$t("projects.sort_budget"),
            },
        };
    },
    computed: mapGetters("auth", ["getInvoiceSetup"]),
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        this.loading = true;
        this.fetchCustomers();
        this.apiFilterView = "actawait";
        this.apiSearchFilter = {
            search: "",
            favorites_user_id: null,
            invoice_setup: this.getInvoiceSetup,
        };
        this.$apiEnableWatcher();
        this.fetchData();
        this.loading = false;
        this.createCase(this.$route);
    },
    methods: {
        /**
         * Fetch projects
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ProjectService.GET(params);
                this.projects = r.data.data;
                this.meta = r.data.meta;
                this.stats = r.data.meta.stats;
            } catch (e) {
                console.log("Project exception", e);
            }
            this.loading = false;
        },
        closeSave() {
            this.addProject = false;
            this.fetchData();
        },
        createCase(to) {
            if ("create" in to.query) {
                // Remove query params from the URL
                this.$router.push(this.$route.path);

                this.addProject = true;
            }
        },
        /**
         * Fetch dropdown data
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        humanize(number) {
            return CurrencyHumanizer(number, "DKK");
        },
        /**
         * Change the favorite flag for a given user (identified by a session)
         */
        async changeFavorite(project_id, flag) {
            console.log("Change favorite: " + project_id + " / " + flag);
            try {
                const r = await ProjectService.changeFavorite(project_id, flag);
                let project = r.data.data;
                let foundKey = this.projects.findIndex(
                    (p) => p.id == project.id
                );
                this.$set(this.projects, foundKey, project);
            } catch (e) {}
        },
    },
};
</script>

<template>
    <div class="v-projects">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>

            <div class="row pt-5 stats">
                <div class="col-12 col-md-6 col-lg-3" v-if="false">
                    <nav-stats-card
                        :title="$t('projects.stat_budget')"
                        :value="humanize(stats.budget)"
                        type="default"
                        icon="fal fa-chart-line"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3" v-if="false">
                    <nav-stats-card
                        :title="$t('projects.stat_used_budget')"
                        :value="humanize(stats.budget_used)"
                        type="default"
                        icon="fal fa-chart-pie-alt"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3" v-if="false">
                    <nav-stats-card
                        :title="$t('projects.stat_over_budget')"
                        :value="stats.over_budget"
                        :type="stats.over_budget == 0 ? 'default' : 'danger'"
                        icon="fal fa-siren-on"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <nav-stats-card
                        :title="$t('projects.stat_admin_cases')"
                        :value="stats.pm_admin"
                        type="default"
                        icon="fal fa-file-signature"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <nav-stats-card
                        :title="$t('projects.stat_billable_cases')"
                        :value="stats.pm_billable"
                        type="default"
                        icon="fal fa-clipboard-list-check"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <nav-stats-card
                        :title="$t('projects.stat_fixed_price_cases')"
                        :value="stats.pm_fixed"
                        type="default"
                        icon="fal fa-clipboard-list-check"
                    />
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <nav-stats-card
                        :title="$t('projects.stat_past_deadline')"
                        :value="stats.past_deadline"
                        :type="stats.past_deadline == 0 ? 'default' : 'danger'"
                        icon="fal fa-calendar-exclamation"
                    />
                </div>
            </div>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12" v-if="false">
                    <base-button type="primary" @click="addSection = true"
                        >Create a section</base-button
                    >
                </div>
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        :filter-views="filterViews"
                        :filter-view="apiFilterView"
                        :filter-name="$t('projects.filter_grp_name')"
                        filter-icon="far fa-flag-alt"
                        @filter-view-change="$handleFilterView($event)"
                        :paginator="apiPaginator"
                        :sort-views="sortViews"
                        @sort="$handleSorting($event)"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="$t('projects.placeholder_search')"
                    >
                        <template slot="left">
                            <base-button
                                type="secondary"
                                @click="addProject = true"
                            >
                                <i class="far fa-plus"></i>
                                {{ $t("project.create_case") }}
                            </base-button>
                        </template>

                        <template slot="right">
                            <v-dept-filter v-model="apiSearchFilter" />
                            <v-project-filter
                                v-model="apiSearchFilter"
                                class="ml-4"
                            />
                        </template>
                    </v-filters>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="projects.length">
                        <v-project
                            v-for="project in projects"
                            :key="project.id"
                            :project="project"
                            @favorite="changeFavorite"
                        />

                        <div class="d-flex justify-content-center mt-4">
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    :total="meta.pagination.total"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </div>
                    </template>
                    <card body-classes="px-0" v-else>
                        <div class="pb-mg">
                            <div class="nice-info">
                                {{ $t("projects.no_projects") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-project-form
                v-if="addProject"
                @close="addProject = false"
                @close-refresh="closeSave"
                :context="{ empty: true }"
            />
        </portal>
    </div>
</template>


<style lang="scss">
.v-filters {
    @media (max-width: 767.98px) {
        &__container {
            &__right {
                & > span {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
