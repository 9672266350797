<script>
/**
 * Single invoice management
 */
import dayjs from "dayjs";
import InvoiceBar from "@/components/Projects/Invoicing/InvoiceBar";
import InvoiceBox from "@/components/Projects/Invoicing/InvoiceBox";
import LineViewer from "@/components/Projects/Invoicing/LineViewer";

// All invoice line components
import VInvoiceHeaderForm from "@/components/Forms/Projects/VInvoiceHeaderForm";
import VInvoiceLineForm from "@/components/Forms/Projects/VInvoiceLineForm";
import VInvoiceActivityLineForm from "@/components/Forms/Projects/VInvoiceActivityLineForm";
import VInvoiceTravelLineForm from "@/components/Forms/Projects/VInvoiceTravelLineForm";

// Services etc.
import ProjectService from "@/services/ProjectService";
import InvoiceService from "@/services/InvoiceService";
import Messages from "@/mixins/Messages";

export default {
    name: "VInvoice",
    props: {
        project_id: {},
        invoice_id: {}
    },
    mixins: [Messages],
    components: {
        InvoiceBar,
        InvoiceBox,
        VInvoiceLineForm,
        VInvoiceHeaderForm,
        VInvoiceActivityLineForm,
        VInvoiceTravelLineForm,
        LineViewer
    },
    data() {
        return {
            loading: false,
            statsLoading: false,
            project: null,
            customer: null,
            invoice: null,
            modalCustomLine: false,
            modalActivityLine: false,

            modalTravelLine: false,
            modalExpenseLine: false,
            modalInvoiceHead: false,
            modalLineViewer: false,
            lineContext: null
        };
    },
    computed: {
        invoiceEditable: function() {
            //return false;

            if (!this.invoice) {
                return false;
            }

            if (this.invoice.status == 0) {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        await this.fetchInvoice(true);
        await this.fetchInvoiceStats();
        await this.fetchProject();
    },
    methods: {
        isPastDate(date) {
            return dayjs().isAfter(date, "day");
        },
        async fetchInvoice(loading = false) {
            try {
                this.loading = true;
                const r = await InvoiceService.GETS(this.invoice_id);
                this.invoice = r.data.data;
            } catch (e) {
                console.log("fetchInvoice", e);
            }
            this.loading = loading;
        },
        showConnectedData(il) {
            console.log("showConnectedData()");
            this.setLineContext(il);
            this.modalLineViewer = true;
        },
        /**
         * Fetch vital stats
         */
        async fetchInvoiceStats() {
            try {
                this.statsLoading = true;
                const r = await InvoiceService.GET_projectBalances(
                    this.project_id
                );
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchInvoiceStats exception", e);
            }

            this.statsLoading = false;
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject() {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
                this.customer = this.project.customer;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
            this.loading = false;
        },
        /**
         * Edit invoice header
         */
        editInvoiceHead() {
            this.modalInvoiceHead = true;
        },
        saveInvoiceHead(invoice) {
            this.invoice = invoice;
            this.modalInvoiceHead = false;
        },
        /**
         * General method: set line context
         */
        setLineContext(ctx) {
            this.lineContext = ctx;
        },
        /**
         * General method: edit line
         */
        editLine(ctx) {
            this.setLineContext(ctx);
            this.modalCustomLine = true;
        },
        /**
         * FIXED PRICE: add custom line
         */
        addCustomLine() {
            console.log("-> addCustomLine()");
            this.setLineContext({ empty: true });

            this.modalCustomLine = true;
        },
        /**
         * FIXED PRICE: close modal
         */
        closeLineModal() {
            this.modalCustomLine = false;
        },

        /**
         * BILLABLE: add new line (activities)
         */
        addActivityLine() {
            console.log("-> addActivityLine()");
            this.setLineContext({ empty: true });
            this.modalActivityLine = true;
        },
        /**
         * BILLABLE: close modal
         */
        closeActivityModal() {
            this.modalActivityLine = false;
        },

        /**
         * meta_key = travel
         */
        addTravelLine() {
            // "travel";
            console.log("-> addTravelLine()");
            this.modalTravelLine = true;
        },
        closeTravelLine() {
            this.modalTravelLine = false;
        },
        /**
         * meta_key = expense
         */
        addExpenseLine() {
            console.log("-> addExpenseLine()");
        },

        /**
         * Line is saved -> reload the whole invoice, as we want
         * to have all totals recalculated.
         */
        async saveAndRefresh(invoiceLine) {
            // Reload invoice
            await this.fetchInvoice();
            // Close all modals: task travel expense
            this.closeLineModal();
            this.closeActivityModal();
            this.closeTravelLine();
        },
        async handleDeleteLine(invoiceLine) {
            this.$swal({
                icon: "warning",
                text: this.$t("invoice_line.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    if ((await this.deleteLine(invoiceLine)) == true) {
                        this.$msgSuccess(
                            this.$t("invoice_line.notify_invoice_line_deleted")
                        );
                        await this.fetchInvoice();
                        await this.fetchInvoiceStats();
                        this.closeLineModal();
                    }
                }
            });
        },
        async deleteLine(invoiceLine) {
            try {
                this.loading = true;
                const r = await InvoiceService.DELETE_invoiceLine(
                    invoiceLine.id
                );
                return true;
            } catch (e) {
                console.log("deleteLIne exception", e);
                this.$handleExceptionError(e, this.$t("error_cannot_delete"));
            }

            return false;
        },
        /**
         * Invoice status change handler (Swal)
         */
        async changeInvoiceStatus(toStatus) {
            if (this.invoice.status == toStatus) {
                return false;
            }

            this.$swal({
                icon: "warning",
                text:
                    toStatus == 0
                        ? this.$t("invoice.swal_change_status_to_draft")
                        : this.$t(
                              "invoice.swal_change_status_to_ready_to_invoice"
                          ),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.doStatusChange(toStatus);
                }
            });
        },
        /**
         * Invoice change status -> actual API call
         */
        async doStatusChange(toStatus) {
            try {
                this.loading = true;
                let r;

                if (toStatus == 0) {
                    r = await InvoiceService.PUT_setAsDraft(this.invoice.id);
                } else {
                    r = await InvoiceService.PUT_setAsReady(this.invoice.id);
                }

                this.invoice = r.data.data;
                this.$msgSuccess(
                    toStatus == 0
                        ? this.$t("invoice.notify_status_changed_to_draft")
                        : this.$t(
                              "invoice.notify_status_changed_to_ready_to_invoice"
                          )
                );
                await this.fetchInvoiceStats();
            } catch (e) {
                this.$handleExceptionError(
                    e,
                    this.$t("invoice.swal_cannot_change_status")
                );
            }
            this.loading = false;
        },
        async deleteDraft() {
            this.$swal({
                icon: "warning",
                text: this.$t("invoice.swal_confirm_invoice_draft_deletion"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.doDeleteInvoiceDraft();
                }
            });
        },
        async doDeleteInvoiceDraft() {
            try {
                this.loading = true;
                const r = await InvoiceService.DELETE(this.invoice_id);
                r.data.data;
                this.$msgSuccess(
                    this.$t("invoice.notify_invoice_draft_deleted")
                );
                this.$router.push({
                    name: "ProjectViewInvoicing",
                    params: {
                        project_id: this.invoice.project_id
                    }
                });
            } catch (e) {
                this.$handleExceptionError(e, this.$t("error_cannot_delete"));
            }
            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="v-p-invoice">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col">
                    <h3>{{ $t("projects.invoicing") }}</h3>
                    <div
                        class="project-invoicing-description"
                        v-if="!loading && project"
                    >
                        {{ project.invoicing_description }}
                    </div>
                </div>
                <div class="col">
                    <!-- top-right -> how much was invoiced in total -->
                    <v-loader :loading="loading" v-if="loading" />
                    <invoice-box
                        :project="project"
                        v-else-if="project"
                        :loading="statsLoading"
                        :stats="stats"
                    />
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3>
                        {{ $t("project.awaiting_invoicing") }}
                        <div class="small text-muted ml-2 d-inline">
                            ({{
                                $t("project.drafts_are_not_included_in_totals")
                            }})
                        </div>
                    </h3>
                </div>

                <div class="col-12 mt-2" v-if="true">
                    <!-- Invoice bar with major stats -->
                    <v-loader
                        :loading="loading"
                        v-if="loading && project === null"
                    />
                    <invoice-bar
                        v-else-if="project !== null"
                        :project="project"
                        :loading="statsLoading"
                        :stats="stats"
                    />
                </div>

                <div class="col-12 card-wrapper">
                    <card body-classes="px-0">
                        <template slot="header">
                            <div class="d-flex align-items-center">
                                <h3>
                                    <i class="far fa-file-invoice mr-2"></i>
                                    {{ $t("project.invoice_draft") }}
                                </h3>
                                <div class="ml-auto">
                                    <span
                                        v-if="invoice && invoice.status == 0"
                                        class="small mr-4 text-muted text-bold m-click hover-danger"
                                        @click="deleteDraft"
                                        >{{ $t("project.delete_draft") }}</span
                                    >

                                    <base-dropdown
                                        class="ml-auto"
                                        :menu-on-right="false"
                                        :has-toggle="false"
                                        v-if="invoice && invoice.status != 1"
                                    >
                                        <template slot="title-container">
                                            <base-button
                                                size="sm"
                                                type="primary"
                                                >{{ $t("project.status") }}:
                                                {{ invoice.status_name }}
                                                <i class="fas fa-caret-down"></i
                                            ></base-button>
                                        </template>
                                        <div
                                            class="dropdown-item"
                                            @click="changeInvoiceStatus(0)"
                                        >
                                            <i
                                                class="far fa-fw text-success"
                                                :class="{
                                                    'fa-check':
                                                        invoice.status == 0
                                                }"
                                            ></i>
                                            {{ $t("invoice.status_draft") }}
                                        </div>
                                        <div
                                            class="dropdown-item"
                                            @click="changeInvoiceStatus(2)"
                                            v-if="
                                                invoice &&
                                                    invoice.invoice_lines
                                                        .length > 0
                                            "
                                        >
                                            <i
                                                class="far fa-fw"
                                                :class="{
                                                    'fa-check':
                                                        invoice.status == 2
                                                }"
                                            ></i>
                                            {{
                                                $t(
                                                    "invoice.status_ready_to_invoice"
                                                )
                                            }}
                                        </div>
                                    </base-dropdown>
                                    <base-button
                                        size="sm"
                                        class="ml-auto"
                                        type="primary"
                                        :outline="true"
                                        @click="
                                            $router.push({
                                                name: 'ProjectViewInvoicing',
                                                params: {
                                                    project_id:
                                                        invoice.project_id
                                                }
                                            })
                                        "
                                    >
                                        <i class="far fa-chevron-left"></i>
                                        {{ $t("project.back_to_overview") }}
                                    </base-button>
                                </div>
                            </div>
                        </template>
                        <!-- invoice head -->
                        <v-loader :loading="loading" v-if="loading" />
                        <div class="row mt-4 px-4" v-else-if="invoice">
                            <div class="col-12 col-md-8">
                                <div class="row">
                                    <div class="col">
                                        <dl>
                                            <dt>
                                                {{
                                                    $t(
                                                        "project.invoice_heading"
                                                    )
                                                }}
                                            </dt>
                                            <dd>
                                                <v-empty
                                                    :text="
                                                        invoice.invoice_header
                                                    "
                                                />
                                            </dd>
                                        </dl>
                                    </div>
                                    <div class="col">
                                        <dl>
                                            <dt>
                                                {{
                                                    $t("project.invoice_text1")
                                                }}
                                            </dt>
                                            <dd>
                                                <v-empty
                                                    :text="
                                                        invoice.invoice_text1
                                                    "
                                                />
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <dl>
                                            <dt>
                                                {{
                                                    $t(
                                                        "project.message_to_bookkeeper"
                                                    )
                                                }}
                                            </dt>
                                            <dd>
                                                <v-empty
                                                    :text="
                                                        invoice.bkeeper_message
                                                    "
                                                />
                                            </dd>
                                        </dl>
                                    </div>
                                    <div class="col">
                                        <dl>
                                            <dt>
                                                {{
                                                    $t(
                                                        "project.deadline_invoice_date"
                                                    )
                                                }}
                                            </dt>
                                            <dd>
                                                <span
                                                    v-if="invoice.invoice_date"
                                                    :class="
                                                        isPastDate(
                                                            invoice.invoice_date
                                                        )
                                                            ? 'text-danger'
                                                            : ''
                                                    "
                                                >
                                                    <i
                                                        v-if="
                                                            isPastDate(
                                                                invoice.invoice_date
                                                            )
                                                        "
                                                        class="far fa-exclamation-triangle"
                                                    ></i>
                                                    {{
                                                        $d(
                                                            new Date(
                                                                invoice.invoice_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </span>
                                                <template v-else>
                                                    ({{
                                                        $t(
                                                            "invoice.use_day_of_invoicing"
                                                        )
                                                    }})
                                                </template>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="v-p-invoice__totals">
                                    <div class="v-p-invoice__totals--header">
                                        {{ $t("project.total") }}
                                    </div>
                                    <div
                                        class="v-p-invoice__totals--amount"
                                        v-if="invoice"
                                    >
                                        {{
                                            $n(invoice.total_net_amount, {
                                                style: "currency",
                                                currencyDisplay: "code",
                                                currency: invoice.currency
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- invoice lines -->
                        <div class="px-4 mt-5 d-flex align-items-center">
                            <h3>{{ $t("project.invoice_lines") }}</h3>

                            <base-button
                                size="sm"
                                class="ml-auto"
                                :outline="true"
                                type="primary"
                                @click="editInvoiceHead"
                                v-if="invoiceEditable"
                            >
                                {{ $t("project.edit_invoice_headers") }}
                                <i class="fas fa-pencil"></i>
                            </base-button>
                            <base-dropdown
                                class=""
                                :menu-on-right="true"
                                :has-toggle="false"
                                v-if="invoiceEditable"
                            >
                                <template slot="title-container">
                                    <base-button size="sm" type="primary"
                                        >{{ $t("project.new_invoice_line") }}
                                        <i class="fas fa-caret-down"></i
                                    ></base-button>
                                </template>
                                <div
                                    class="dropdown-item"
                                    @click="addCustomLine"
                                    v-if="project && project.project_model == 1"
                                >
                                    <i
                                        class="far fa-fw fa-money-check-edit"
                                    ></i>
                                    {{ $t("project.custom_amount") }}
                                </div>
                                <div
                                    class="dropdown-item"
                                    @click="addActivityLine"
                                    v-if="project && project.project_model == 2"
                                >
                                    <i class="far fa-fw fa-clock"></i>
                                    {{ $t("project.activities") }}
                                </div>

                                <div
                                    v-if="false"
                                    class="dropdown-item"
                                    @click="addExpenseLine"
                                >
                                    <i class="far fa-fw fa-receipt"></i>
                                    {{ $t("project.expenses") }}
                                </div>
                                <div
                                    @click="addTravelLine"
                                    class="dropdown-item"
                                >
                                    <i class="far fa-fw fa-car"></i>
                                    {{ $t("project.transport") }}
                                </div>
                            </base-dropdown>
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else>
                            <div
                                class="px-4 mt-4"
                                v-if="
                                    invoice && invoice.invoice_lines.length == 0
                                "
                            >
                                <div class="text-muted text-italic text-center">
                                    {{
                                        $t("project.no_invoice_lines_info_text")
                                    }}
                                </div>
                            </div>
                            <div class="table-responsive mt-3" v-else>
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th></v-th>
                                            <v-th v-if="false">{{
                                                $t("invoice_line.type")
                                            }}</v-th>
                                            <v-th>{{
                                                $t("invoice_line.description")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("invoice_line.quantity")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("invoice_line.unit_price")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("invoice_line.discount")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("invoice_line.total")
                                            }}</v-th>
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="il in invoice.invoice_lines"
                                            :key="il.id"
                                        >
                                            <v-td>{{ il.line_number }}</v-td>
                                            <v-td v-if="false">
                                                {{ il.meta_key_name }}
                                            </v-td>
                                            <v-td
                                                ><v-newlines
                                                    :text="il.invoice_line"
                                            /></v-td>
                                            <v-td class="text-right">{{
                                                $n(il.qty)
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(il.price, {
                                                    style: "currency",
                                                    currency: invoice.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right"
                                                >{{
                                                    $n(il.discount_perc)
                                                }}%</v-td
                                            >
                                            <v-td class="text-right">{{
                                                $n(il.price_subtotal, {
                                                    style: "currency",
                                                    currency: invoice.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">
                                                <base-dropdown
                                                    v-if="invoiceEditable"
                                                    class="ml-auto"
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                    >
                                                        <i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i>
                                                    </template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="editLine(il)"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "invoice_line.edit"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="
                                                            showConnectedData(
                                                                il
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-info-circle"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "invoice_line.show_details"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item text-danger"
                                                        @click="
                                                            handleDeleteLine(il)
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "invoice_line.delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>

                                                <i
                                                    v-else
                                                    @click="
                                                        showConnectedData(il)
                                                    "
                                                    class="far fa-eye fa-lg fa-fw m-click"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
            <!-- .row -->
        </div>
        <portal to="modals">
            <line-viewer
                v-if="modalLineViewer"
                :context="lineContext"
                :invoice="invoice"
                @close="modalLineViewer = false"
            />
            <v-invoice-travel-line-form
                v-if="modalTravelLine"
                :invoice-id="invoice_id"
                :project-id="project_id"
                :currency="invoice.currency"
                :has-milestones="project.enable_milestones === 1 ? true : false"
                @close="closeTravelLine"
                @save="saveAndRefresh"
            />
            <v-invoice-activity-line-form
                v-if="modalActivityLine"
                :invoice-id="invoice_id"
                :project-id="project_id"
                :currency="invoice.currency"
                :has-milestones="project.enable_milestones === 1 ? true : false"
                @close="closeActivityModal"
                @save="saveAndRefresh"
            />
            <v-invoice-line-form
                v-if="modalCustomLine"
                :context="lineContext"
                :invoice-id="invoice_id"
                @close="closeLineModal"
                @save="saveAndRefresh"
            />
            <v-invoice-header-form
                :context="invoice"
                v-if="modalInvoiceHead"
                @close="modalInvoiceHead = false"
                @save="saveInvoiceHead"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.hover-danger {
    &:hover {
        color: $danger !important;
    }
}
.v-p-invoice {
    &__totals {
        height: 100%;
        align-self: center;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: $gray-100;
        border: 6px solid $gray-100;
        border-radius: 6px;
        flex-direction: column;

        &--header {
            display: block;
            margin-bottom: 6px;
        }

        &--amount {
            font-size: 22px;
            font-weight: 600;
            display: block;
        }
    }
}
</style>
