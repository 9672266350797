<script>
import { mapGetters } from "vuex";
import InvoiceBar from "@/components/Projects/Invoicing/InvoiceBar";
import InvoiceBox from "@/components/Projects/Invoicing/InvoiceBox";
import VWait from "@/components/VWait";
import ShowChangesModal from "@/views/Projects/Tabs/components/ShowChangesModal";
import ProjectService from "@/services/ProjectService";
import InvoiceService from "@/services/InvoiceService";
import ProjectChangeService from "@/services/ProjectChangeService";

export default {
    name: "VInvoicing",
    components: { InvoiceBar, InvoiceBox, VWait, ShowChangesModal },
    props: {
        project_id: {
            description: "Project ID",
        },
    },
    data() {
        return {
            loading: false,
            statsLoading: false,
            stats: null,
            project: null,
            showChangesModal: false,
            customer: null,
            meta: null,
            invoices: [],
            creatingDraft: false,
            changes: [],
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        hasChanges() {
            if (this.changes.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    async created() {
        await this.fetchProject(true);
        await this.fetchChanges(true);

        if (
            !this.$aclGuardOR(
                this.$ACL.TIMEREG_MGMT,
                this.project.user_id == this.getMyId
            )
        ) {
            return;
        }

        await this.fetchInvoices(false);
        await this.fetchInvoiceStats();
    },
    methods: {
        async fetchChanges(loading = false) {
            try {
                this.loading = true;
                const { data } = await ProjectChangeService.GET_logs(
                    this.project_id
                );
                this.changes = data.data;
            } catch (e) {}

            this.loading = loading;
        },
        async fetchInvoices(loading = false) {
            try {
                this.loading = true;
                const r = await InvoiceService.GET_projectInvoices(
                    this.project_id
                );
                this.invoices = r.data.data;
            } catch (e) {
                console.log("fetchInvoices exception", e);
            }

            this.loading = loading;
        },
        /**
         * Fetch vital stats
         */
        async fetchInvoiceStats() {
            try {
                this.statsLoading = true;
                const r = await InvoiceService.GET_projectBalances(
                    this.project_id
                );
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchInvoiceStats exception", e);
            }

            this.statsLoading = false;
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
                this.customer = this.project.customer;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
            this.loading = loading;
        },
        /**
         * Create an invoice draft and redirect to it
         */
        async createNewInvoice() {
            try {
                this.creatingDraft = true;
                const r = await InvoiceService.POST_projectCreateDraft(
                    this.project.id
                );
                console.log(r.data);
                let invoice = r.data.data;
                console.log("ME", invoice.id);
                this.navigateInvoice(invoice.id);
            } catch (e) {
                console.log("createNewInvoice exception", e);
            }

            this.creatingDraft = false;
        },
        /**
         * Navigate to view/edit invoice
         */
        navigateInvoice(invoice_id) {
            this.$router.push({
                name: "ProjectViewInvoice",
                params: {
                    project_id: this.project_id,
                    invoice_id: invoice_id,
                },
            });
        },
    },
};
</script>

<template>
    <div>
        <v-wip v-if="false" />
        <div class="container-fluid" v-else>
            <div class="row card-wrapper">
                <div class="col">
                    <h3>{{ $t("projects.invoicing") }}</h3>
                    <div
                        class="project-invoicing-description"
                        v-if="!loading && project"
                    >
                        {{ project.invoicing_description }}
                    </div>
                </div>
                <div class="col">
                    <!-- top-right -> how much was invoiced in total -->
                    <v-loader :loading="loading" v-if="loading" />
                    <invoice-box
                        :project="project"
                        v-else-if="project"
                        :loading="statsLoading"
                        :stats="stats"
                    />
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <h3>
                        {{ $t("project.awaiting_invoicing") }}
                        <div class="small text-muted ml-2 d-inline">
                            ({{
                                $t("project.drafts_are_not_included_in_totals")
                            }})
                        </div>
                    </h3>
                </div>

                <div class="col-12 mt-2">
                    <v-loader
                        :loading="loading"
                        v-if="loading && project === null"
                    />
                    <invoice-bar
                        v-else-if="project !== null"
                        :project="project"
                        :loading="statsLoading"
                        :stats="stats"
                    />
                </div>
                <div class="col-12 card-wrapper" v-if="hasChanges">
                    <card body-classes="px-4">
                        <i class="fa fa-exclamation-triangle mr-2"></i>
                        {{
                            $tc(
                                "project.warning_this_project_changes_customer_click_to_check_#n_change",
                                changes.length
                            )
                        }}

                        <span
                            @click="showChangesModal = true"
                            class="m-click"
                            >{{ $t("project.click_here_to_see_more") }}</span
                        >
                    </card>
                </div>
                <div class="col-12 card-wrapper">
                    <card body-classes="px-0">
                        <div class="d-flex align-items-center px-4">
                            <h3>
                                <i class="far fa-file-invoice mr-2"></i>
                                {{ $t("project.invoice_history") }}
                            </h3>
                            <base-button
                                class="ml-auto"
                                type="primary"
                                @click="createNewInvoice"
                                ><i class="far fa-plus"></i>
                                {{ $t("project.new_invoice") }}
                            </base-button>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <div
                            class="table-responsive mt-4"
                            v-else-if="invoices.length > 0"
                        >
                            <v-table class="table table-mg table-hover">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("project.creation_date")
                                        }}</v-th>
                                        <v-th>{{ $t("project.status") }}</v-th>
                                        <v-th>{{
                                            $t("project.invoice_date")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("project.invoice_heading")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("project.amount")
                                        }}</v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="invoice in invoices"
                                        :key="invoice.id"
                                    >
                                        <v-td>{{
                                            $d(
                                                new Date(invoice.created),
                                                "short"
                                            )
                                        }}</v-td>
                                        <v-td
                                            >{{ invoice.status_name }}
                                            <div
                                                class="small"
                                                v-if="invoice.invoice_date"
                                            >
                                                {{
                                                    $d(
                                                        new Date(
                                                            invoice.invoice_date
                                                        ),
                                                        "short"
                                                    )
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td>
                                            <template
                                                v-if="invoice.invoice_date"
                                            >
                                                {{ invoice.invoice_date }}
                                                <div
                                                    v-if="invoice.status != 1"
                                                    class="small text-gray"
                                                >
                                                    {{
                                                        $t(
                                                            "project.deadline_invoice_date_table"
                                                        )
                                                    }}
                                                </div>
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td>
                                            <v-empty
                                                :text="invoice.invoice_header"
                                            />
                                        </v-td>
                                        <v-td class="text-right">{{
                                            $n(invoice.total_net_amount, {
                                                style: "currency",
                                                currency: invoice.currency,
                                                currencyDisplay: "code",
                                            })
                                        }}</v-td>
                                        <v-td class="text-right">
                                            <i
                                                v-if="invoice.status != 1"
                                                class="far fa-pencil m-click fa-lg fa-fw"
                                                @click="
                                                    navigateInvoice(invoice.id)
                                                "
                                            ></i>
                                            <i
                                                v-else
                                                class="far fa-eye m-click fa-lg fa-fw"
                                                @click="
                                                    navigateInvoice(invoice.id)
                                                "
                                            ></i>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                        <div class="px-4 mt-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("project.no_invoices_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <!-- .row -->
        </div>
        <portal to="modals">
            <v-wait
                v-if="creatingDraft"
                :text="$t('project.please_wait_draft_invoice_is_being_created')"
            />
            <show-changes-modal
                v-if="showChangesModal"
                :project-id="project_id"
                :changes="changes"
                @close="showChangesModal = false"
            />
        </portal>
    </div>
</template>

<style>
</style>
