<script>
import { mapGetters } from "vuex";

import VInfobar from "@/components/Projects/VInfobar";
import VProjectForm from "@/components/Forms/VProjectForm";
import VProjectMemberForm from "@/components/Forms/VProjectMemberForm";
import VChangeCustomerForm from "@/components/Forms/VChangeCustomerForm";
import VContractProjectForm from "@/components/Forms/VContractProjectForm";
import { EventBus } from "@/libraries/EventBus";

import ProjectService from "@/services/ProjectService";
import Messages from "@/mixins/Messages";

export default {
    name: "VSettings",
    props: {
        project_id: {
            description: "Project ID"
        }
    },
    mixins: [Messages],
    components: {
        VProjectMemberForm,
        VInfobar,
        VProjectForm,
        VContractProjectForm,
        VChangeCustomerForm
    },
    computed: mapGetters("auth", ["getMyId"]),
    data() {
        return {
            loadingProject: false,
            loadingUsers: false,
            loadingCanChangeCustomer: false,
            canChangeCustomer: false,
            showMemberModal: false,
            showProjectModal: false,
            showChangeCustomerModal: false,
            showContractProjectModal: false,
            project: null,
            editContext: null,
            canDelete: false
        };
    },
    async created() {
        this.loadingProject = true;
        this.loadingUsers = true;
        this.loadingCanChangeCustomer = true;
        await this.fetchProject();
        await this.fetchCanChangeCustomer();

        this.ACL_RW =
            this.project.user_id == this.getMyId ||
            this.$aclCan(this.$ACL.TIMEREG_MGMT);
        this.loadingProject = false;
        this.loadingUsers = false;
    },
    methods: {
        /**
         * ACL -> ACL_CAN or is the responsible
         */
        canEdit() {
            let acl_global = this.$aclCan(this.$ACL.TIMEREG_MGMT);
            let acl_local =
                this.project.responsible_user.id == this.getMyId ? true : false;

            return acl_global || acl_local;
        },
        /**
         * Check if customer change is possible
         */
        async fetchCanChangeCustomer() {
            try {
                this.loadingCanChangeCustomer = true;
                const { data } = await ProjectService.GET_canChangeCustomer(
                    this.project_id
                );
                this.canChangeCustomer = data.data.canChange;
            } catch (e) {
                console.log("fetchCanChangeCustomer e", e);
            }

            this.loadingCanChangeCustomer = false;
        },
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id, {
                    includes: "users"
                });
                this.project = r.data.data;
                this.canDelete = r.data.meta.canDelete;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        async closeSaveContractProject() {
            this.showContractProjectModal = false;
            this.loadingProject = true;
            await this.fetchProject();
            this.loadingProject = false;
            EventBus.$emit("project-refresh");
        },
        async closeSaveProject() {
            this.showProjectModal = false;
            this.loadingProject = true;
            await this.fetchProject();
            this.loadingProject = false;
            EventBus.$emit("project-refresh");
        },
        async closeSaveUser() {
            this.loadingUsers = true;
            await this.fetchProject();
            this.loadingUsers = false;
            this.showMemberModal = false;
        },
        setContext(ctx) {
            if (ctx == null) {
                this.editContext = { empty: true };
            } else {
                this.editContext = ctx;
            }

            this.showMemberModal = true;
        },
        async handleDeleteProject() {
            this.$swal({
                icon: "warning",
                text: this.$t("projects.swal_confirm_project_deletion"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.deleteProject();
                }
            });
        },
        async deleteProject() {
            try {
                const r = await ProjectService.DELETE(this.project_id);
                // It is deleted -> reroute
                this.$msgSuccess(
                    this.$t("projects.notify_project_has_been_deleted")
                );
                this.$router.push({ name: "ProjectsIndex" });
            } catch (e) {
                this.$handleExceptionError(
                    e,
                    this.$t("projects.swal_cannot_delete_project")
                );
            }
        },
        /**
         * Customer has been changed - do all cleanup and reroute
         */
        closeCustomerChanged({ project_id }) {
            this.$router.push({
                name: "ProjectViewTasks",
                params: { project_id: project_id }
            });
            this.$msgSuccess(
                this.$t("change_customer.notify_success_customer_changed")
            );
        }
    }
};
</script>

<template>
    <div class="v-team">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-6">
                    <h2>{{ $t("project.foundation_data") }}</h2>
                </div>
                <div class="col-6">
                    <div class="text-right mb-4">
                        <template v-if="loadingProject == false && project">
                            <base-button
                                v-if="ACL_RW && canDelete"
                                type="danger"
                                size="sm"
                                @click="handleDeleteProject"
                            >
                                <i class="far fa-trash"></i>
                                {{ $t("project.delete_project") }}
                            </base-button>

                            <base-button
                                v-if="project.project_model == 3"
                                size="sm"
                                type="secondary"
                                :disabled="!ACL_RW"
                                @click="showContractProjectModal = true"
                                ><i class="far fa-pencil"></i>
                                {{ $t("project.edit_project") }}</base-button
                            >
                            <base-button
                                v-else
                                size="sm"
                                type="secondary"
                                :disabled="!ACL_RW"
                                @click="showProjectModal = true"
                                ><i class="far fa-pencil"></i>
                                {{ $t("project.edit_project") }}</base-button
                            >
                        </template>
                    </div>
                </div>
                <div class="col-12" v-if="ACL_RW && canDelete">
                    <div class="alert alert-info alert-outline">
                        {{ $t("project.infotext_can_delete") }}
                    </div>
                </div>
                <div class="col-12">
                    <v-infobar :project="project" :acl="ACL_RW" />
                </div>
            </div>

            <v-loader :loading="loadingUsers" v-if="loadingUsers" />

            <template v-else>
                <div class="row card-wrapper mt-4">
                    <div class="col-6">
                        <h2>{{ $t("project.users_and_hour_rates") }}</h2>
                    </div>
                    <div class="col-6">
                        <div class="text-right mb-4">
                            <base-button
                                size="sm"
                                type="warning"
                                v-if="ACL_RW"
                                @click="
                                    $router.push({ name: 'ProjectViewPricer' })
                                "
                            >
                                {{ $t("project.price_manager") }}</base-button
                            >
                            <base-button
                                size="sm"
                                type="secondary"
                                :disabled="!ACL_RW"
                                @click="setContext(null)"
                                ><i class="far fa-plus"></i>
                                {{ $t("project.add_member") }}</base-button
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <template v-if="project.users">
                            <card body-classes="px-0">
                                <div class="table-responsive">
                                    <v-table class="table table-mg table-hover">
                                        <v-thead>
                                            <v-tr>
                                                <v-th></v-th>
                                                <v-th>{{
                                                    $t("project.user_name")
                                                }}</v-th>
                                                <v-th>{{
                                                    $t("project.user_email")
                                                }}</v-th>
                                                <v-th v-if="ACL_RW">{{
                                                    $t(
                                                        "project.user_discount_percent"
                                                    )
                                                }}</v-th>
                                                <v-th v-if="ACL_RW">{{
                                                    $t(
                                                        "project.user_hour_price"
                                                    )
                                                }}</v-th>
                                                <v-th v-if="ACL_RW"></v-th>
                                            </v-tr>
                                        </v-thead>
                                        <v-tbody>
                                            <v-tr
                                                v-for="u in project.users"
                                                :key="u.id"
                                            >
                                                <v-td
                                                    ><v-avatar :image="u.avatar"
                                                /></v-td>
                                                <v-td>{{ u.name }}</v-td>
                                                <v-td>{{ u.email }}</v-td>
                                                <v-td v-if="ACL_RW">
                                                    <template
                                                        v-if="
                                                            u.discount_percent >
                                                                0
                                                        "
                                                    >
                                                        {{
                                                            $n(
                                                                u.default_hour_price,
                                                                {
                                                                    style:
                                                                        "currency",
                                                                    currency:
                                                                        project.currency,
                                                                    currencyDisplay:
                                                                        "code"
                                                                }
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            $n(
                                                                u.discount_percent
                                                            )
                                                        }}%
                                                    </template>
                                                    <template v-else
                                                        >---</template
                                                    >
                                                </v-td>
                                                <v-td v-if="ACL_RW">
                                                    {{
                                                        $n(u.hour_price, {
                                                            style: "currency",
                                                            currency:
                                                                project.currency,
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}
                                                </v-td>
                                                <v-td v-if="ACL_RW">
                                                    <i
                                                        class="far fa-pencil m-click text-muted fa-lg"
                                                        @click="
                                                            setContext({
                                                                user_id: u.id,
                                                                user: u
                                                            })
                                                        "
                                                    >
                                                    </i>
                                                </v-td>
                                            </v-tr>
                                        </v-tbody>
                                    </v-table>
                                </div>
                            </card>
                        </template>

                        <template v-else>
                            <div class="row card-wrapper">
                                <div class="col">
                                    <div class="alert alert-warning">
                                        {{ $t("project.no_members") }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>

            <!-- Can change customer -->
            <v-loader
                :loading="loadingCanChangeCustomer"
                v-if="loadingCanChangeCustomer"
            />
            <template v-else>
                <div class="row card-wrapper mt-4">
                    <div class="col-12">
                        <h2>{{ $t("project.change_customer") }}</h2>
                    </div>
                    <div class="col-6 text-center">
                        <card>
                            <div class="mt-3">
                                <base-button
                                    type="primary"
                                    @click="showChangeCustomerModal = true"
                                    >{{
                                        $t("project.btn_change_customer")
                                    }}</base-button
                                >
                                <div
                                    v-if="!canChangeCustomer"
                                    class="mt-4 text-warning"
                                >
                                    <i class="fa fa-exclamation-triangle"></i>
                                    {{
                                        $t(
                                            "project.customer_change.invoice_change_notice"
                                        )
                                    }}
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </template>
        </div>
        <portal to="modals">
            <v-change-customer-form
                :project-id="project_id"
                :can-change-customer="canChangeCustomer"
                v-if="showChangeCustomerModal"
                @close="showChangeCustomerModal = false"
                @close-refresh="closeCustomerChanged"
            />
            <v-project-member-form
                v-if="showMemberModal"
                :project_id="project_id"
                @close="showMemberModal = false"
                @close-refresh="closeSaveUser"
                :context="editContext"
            />
            <v-contract-project-form
                v-if="showContractProjectModal"
                :context="project"
                @close="showContractProjectModal = false"
                @save="closeSaveContractProject"
            />
            <v-project-form
                v-if="showProjectModal"
                :context="project"
                @close="showProjectModal = false"
                @close-refresh="closeSaveProject"
            />
        </portal>
    </div>
</template>

<style></style>
