<script>
export default {
    name: "InvoiceOverview",
    props: {
        projects: {},
        showNames: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<template>
    <div class="table-responsive">
        <v-table class="table table-mg table-sm">
            <v-thead>
                <v-tr>
                    <v-th>{{ $t("projects.customer") }}</v-th>
                    <v-th>{{ $t("projects.title") }}</v-th>
                    <v-th class="text-right">{{ $t("projects.budgets") }}</v-th>
                    <v-th class="text-right">{{
                        $t("projects.invoiced")
                    }}</v-th>
                    <v-th class="text-right">{{
                        $t("projects.ready_to_invoice")
                    }}</v-th>
                    <v-th class="text-right">{{ $t("projects.drafts") }}</v-th>
                    <v-th class="text-right">{{
                        $t("projects.to_invoice")
                    }}</v-th>
                </v-tr>
            </v-thead>
            <v-tbody>
                <v-tr v-for="p in projects" :key="p.id">
                    <v-td
                        >{{ p.customer.name }}
                        <div class="small text-muted" v-if="showNames">
                            {{ p.responsible_user.name }}
                        </div>
                    </v-td>
                    <v-td
                        ><router-link
                            :to="{
                                name: 'ProjectViewInvoicing',
                                params: { project_id: p.id },
                            }"
                            >{{ p.title }} (#{{ p.id }})</router-link
                        >
                        <div class="small text-muted">
                            {{ p.project_model_name }}:
                            {{ p.project_type_name }}
                        </div>
                        <div class="small text-muted">
                            <i class="far fa-flag-alt"></i> {{ p.status_name }}
                        </div>
                    </v-td>
                    <v-td class="text-right">
                        <template v-if="p.budget_amount > 0">
                            {{
                                $n(p.budget_amount, {
                                    style: "currency",
                                    currency: p.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                        </template>
                        <template v-else>---</template>
                    </v-td>
                    <v-td class="text-right">
                        <template v-if="'invoiced' in p.invoice_stats">
                            {{
                                $n(p.invoice_stats.invoiced.total_sum, {
                                    style: "currency",
                                    currency: p.invoice_stats.invoiced.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                            <div class="small text-success">
                                {{
                                    $n(p.invoice_stats.invoiced.total_sum_dkk, {
                                        style: "currency",
                                        currency: "DKK",
                                        currencyDisplay: "code",
                                    })
                                }}
                            </div>
                            <div class="small text-muted">
                                {{
                                    $tc(
                                        "projects.#n_invoices",
                                        p.invoice_stats.invoiced.invoice_count
                                    )
                                }}
                            </div>
                        </template>
                        <template v-else>---</template>
                    </v-td>
                    <v-td class="text-right">
                        <template v-if="'ready' in p.invoice_stats">
                            {{
                                $n(p.invoice_stats.ready.total_sum, {
                                    style: "currency",
                                    currency: p.invoice_stats.ready.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                            <div class="small text-muted">
                                {{
                                    $tc(
                                        "projects.#n_invoices",
                                        p.invoice_stats.ready.invoice_count
                                    )
                                }}
                            </div>
                        </template>
                        <template v-else>---</template>
                    </v-td>

                    <v-td class="text-right">
                        <template v-if="'draft' in p.invoice_stats">
                            {{
                                $n(p.invoice_stats.draft.total_sum, {
                                    style: "currency",
                                    currency: p.invoice_stats.draft.currency,
                                    currencyDisplay: "code",
                                })
                            }}

                            <div class="small text-muted">
                                {{
                                    $tc(
                                        "projects.#n_invoices",
                                        p.invoice_stats.draft.invoice_count
                                    )
                                }}
                            </div>
                        </template>
                        <template v-else>---</template>
                    </v-td>

                    <v-td class="text-right">
                        <template v-if="p.project_model == 1">
                            <span
                                v-if="p.invoice_stats.to_invoice === false"
                                class="text-warning"
                            >
                                <i class="far fa-exclamation-triangle"></i>
                                {{ $t("projects.missing_budget_data") }}
                            </span>
                            <template v-else>
                                {{
                                    $n(p.invoice_stats.to_invoice, {
                                        style: "currency",
                                        currency: p.currency,
                                        currencyDisplay: "code",
                                    })
                                }}
                                <div class="small text-success">
                                    {{
                                        $n(p.invoice_stats.to_invoice_dkk, {
                                            style: "currency",
                                            currency: "DKK",
                                            currencyDisplay: "code",
                                        })
                                    }}
                                </div>
                            </template>
                        </template>
                        <template v-if="p.project_model == 2">
                            {{
                                $n(p.invoice_stats.to_invoice, {
                                    style: "currency",
                                    currency: p.currency,
                                    currencyDisplay: "code",
                                })
                            }}
                            <div class="small text-success">
                                {{
                                    $n(p.invoice_stats.to_invoice_dkk, {
                                        style: "currency",
                                        currency: "DKK",
                                        currencyDisplay: "code",
                                    })
                                }}
                            </div>
                        </template>
                    </v-td>
                </v-tr>
            </v-tbody>
        </v-table>
    </div>
</template>

<style>
</style>
