<script>
export default {
    name: "VInfoCard",
    props: {
        title: {
            type: String,
            default: "",
            required: true,
        },
    },
};
</script>

<template>
    <div class="info-boxes--box"
    v-on="$listeners">
        <div class="box-title">
            {{ title }}
        </div>
        <div class="box-data"><slot /></div>
        <div v-if="$slots.action" class="box-action">
            <slot name="action" />
        </div>
    </div>
</template>

<style>
</style>