var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-task-viewer"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[(true)?_c('div',{staticClass:"px-4"},[_c('v-filters',{attrs:{"search":false,"enable-filters":true},on:{"reset-search":_vm.resetSearch,"filter-view-change":function($event){return _vm.$handleFilterView($event)}}},[_c('template',{slot:"right"},[(_vm.initLoading)?_c('v-loader',{attrs:{"loading":_vm.initLoading}}):(_vm.stats)?_c('div',{staticClass:"project-stats"},[_c('div',{staticClass:"project-stats__box"},[_c('div',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t("reopen.active_requests"))+" ")]),_c('div',{staticClass:"box-data"},[_vm._v(" "+_vm._s(_vm.meta.stats.active)+" ")])])]):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                            'my_tasks.search_placeholder_administrator'
                                        ),"type":"select","options":_vm.admins},model:{value:(_vm.apiSearchFilter.admin_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "admin_id", $$v)},expression:"apiSearchFilter.admin_id"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                            'my_tasks.search_placeholder_status'
                                        ),"type":"select","options":_vm.status},model:{value:(_vm.apiSearchFilter.status),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "status", $$v)},expression:"apiSearchFilter.status"}})],1)],2)],1):_vm._e(),(!_vm.loading)?[(_vm.reqs.length)?[_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-sm table-mg"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"created"},on:{"sort":function($event){return _vm.$handleSorting(
                                                            $event
                                                        )}}},[_vm._v(_vm._s(_vm.$t("reopen.created")))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"day_date"},on:{"sort":function($event){return _vm.$handleSorting(
                                                            $event
                                                        )}}},[_vm._v(_vm._s(_vm.$t( "reopen.date_for_opening" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"users.name"},on:{"sort":function($event){return _vm.$handleSorting(
                                                            $event
                                                        )}}},[_vm._v(_vm._s(_vm.$t( "reopen.employee" )))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"reason"},on:{"sort":function($event){return _vm.$handleSorting(
                                                            $event
                                                        )}}},[_vm._v(_vm._s(_vm.$t("reopen.reason")))])],1),_c('v-th',[_c('v-sorter',{attrs:{"paginator":_vm.apiPaginator,"name":"status"},on:{"sort":function($event){return _vm.$handleSorting(
                                                            $event
                                                        )}}},[_vm._v(_vm._s(_vm.$t("reopen.status")))])],1),_c('v-th')],1)],1),_c('v-tbody',_vm._l((_vm.reqs),function(req){return _c('v-tr',{key:req.id},[_c('v-td',[_vm._v(_vm._s(_vm.$d( new Date(req.created), "short" )))]),_c('v-td',[_vm._v(_vm._s(_vm.$d( new Date( req.day_date ), "short" ))+" ")]),_c('v-td',[_vm._v(_vm._s(req.user.name)+" ")]),_c('v-td',[_c('v-newlines',{attrs:{"text":req.reason}})],1),_c('v-td',[_vm._v(_vm._s(req.status_name)+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(req.admin.name)+" ")])]),_c('v-td',{staticClass:"text-right"},[_c('base-dropdown',{attrs:{"menu-on-right":true,"has-toggle":false}},[_c('template',{slot:"title-container"},[_c('i',{staticClass:"far fa-ellipsis-h fa-lg"})]),_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.showTasks(req)}}},[_c('i',{staticClass:"far fa-fw fa-eye"}),_vm._v(" "+_vm._s(_vm.$t( "reopen.action_show_tasks" ))+" ")]),(
                                                            req.status == 1
                                                        )?_c('div',{staticClass:"dropdown-separator"}):_vm._e(),(
                                                            req.status == 1
                                                        )?_c('div',{staticClass:"dropdown-item text-success",on:{"click":function($event){return _vm.actionAccept(
                                                                req
                                                            )}}},[_c('i',{staticClass:"far fa-fw fa-lock-open"}),_vm._v(" "+_vm._s(_vm.$t( "reopen.action_accept_and_open_day" ))+" ")]):_vm._e(),(
                                                            req.status == 1
                                                        )?_c('div',{staticClass:"dropdown-item text-danger",on:{"click":function($event){return _vm.actionReject(
                                                                req
                                                            )}}},[_c('i',{staticClass:"far fa-fw fa-vote-nay"}),_vm._v(" "+_vm._s(_vm.$t( "reopen.action_reject" ))+" ")]):_vm._e(),(
                                                            false &&
                                                            req.status == 1
                                                        )?_c('div',{staticClass:"dropdown-item text-warning",on:{"click":function($event){return _vm.actionCancel(
                                                                req
                                                            )}}},[_c('i',{staticClass:"far fa-fw fa-trash"}),_vm._v(" "+_vm._s(_vm.$t( "reopen.action_cancel" ))+" ")]):_vm._e()],2)],1)],1)}),1)],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page,"total":_vm.meta.pagination.total},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)])]:_c('div',{staticClass:"px-4 mt-4"},[_c('div',{staticClass:"alert alert-warning px-4"},[_vm._v(_vm._s(_vm.$t('reopen.no_data_found')))])])]:_vm._e()],2)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showViewerModal)?_c('v-task-viewer-modal',{attrs:{"context":_vm.showContext},on:{"close":function($event){_vm.showViewerModal = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }