<script>
import ProjectViewer from "@/components/Projects/ProjectViewer";

export default {
    name: "VTasks",
    props: {
        project_id: {
            description: "Project ID",
        },
    },
    components: {
        ProjectViewer,
    },
    data() {
        return {
            loading: false,
        };
    },
    
};
</script>

<template>
    <div>        
        <project-viewer :project_id="project_id" />
    </div>
</template>

<style>
</style>