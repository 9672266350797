<script>
/**
 * Top-right box with two info-fields.
 */
import VInfoCard from "@/components/Cards/VInfoCard";

export default {
    name: "InvoiceBox",
    components: { VInfoCard },
    props: {
        project: {
            description: "Project object",
        },
        stats: {
            description: "Stats object",
        },
        loading: {
            type: Boolean,
            default: false,
            description: "Stats loading",
        },
    },
};
</script>

<template>
    <div class="info-boxes">
        <v-info-card :title="$t('project.settlement_model')">
            <template slot="default">{{ project.project_model_name }}</template>
        </v-info-card>
        <v-info-card :title="$t('project.total_invoiced')">
            <template slot="default">
                <v-loader :loading="loading" loader="mini" v-if="loading" />
                <template v-else-if="stats">
                    {{
                        $n(stats.invoiced.invoiced_total, {
                            style: "currency",
                            currency: project.currency,
                            currencyDisplay: "code",
                        })
                    }}
                </template>
            </template>
        </v-info-card>
    </div>
</template>

<style>
</style>