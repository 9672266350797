<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";

import TaskService from "@/services/TaskService";
import { EventBus } from "@/libraries/EventBus";

export default {
    components: { InnerDashCard },
    data() {
        return {
            loading: false,
            stats: {}
        };
    },
    computed: {
        targetWeek() {
            return this.stats.avg_week - this.stats.user.daily_time_goal;
        },
        targetMonth() {
            return this.stats.avg_month - this.stats.user.daily_time_goal;
        },
        targetLastMonth() {
            return this.stats.avg_last_month - this.stats.user.daily_time_goal;
        },
        targetLast3() {
            return this.stats.avg_last3 - this.stats.user.daily_time_goal;
        },
        targetYtd() {
            return this.stats.avg_ytd - this.stats.user.daily_time_goal;
        },
        dailyTarget() {
            return this.$options.filters.toTime(
                this.stats.user.daily_time_goal
            );
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await TaskService.GET_averageStats();
                this.stats = data.data;
            } catch (e) {}

            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="v-hours-week row">
        <div class="col-12">
            <card body-classes="px-0">
                <div class="px-4">
                    <div class="d-flex align-items-center mb-3">
                        <h3 class="mb-0">
                            <i class="far fa-tachometer-average"></i>
                            {{ $t("dashboard.long_term_stats_and_averages") }}
                        </h3>
                    </div>
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <div class="row v-hours-week__statboxes">
                            <div class="col-12 col-md">
                                <inner-dash-card
                                    :title="$t('dashboard.statbox_avg_wk')"
                                    icon="far fa-building"
                                    ><template slot="default"
                                        ><span class="text-default">{{
                                            stats.reg_week | toTime
                                        }}</span></template
                                    >
                                    <template slot="action">
                                        <span class="text-muted m-click"
                                            >{{
                                                $t(
                                                    "dashboard.statbox_avg_per_day"
                                                )
                                            }}
                                            {{ stats.avg_week | toTime }}
                                        </span>
                                    </template></inner-dash-card
                                >
                            </div>

                            <div class="col-12 col-md">
                                <inner-dash-card
                                    :title="$t('dashboard.statbox_avg_mon')"
                                    icon="far fa-building"
                                    ><template slot="default"
                                        ><span class="text-default">{{
                                            stats.reg_month | toTime
                                        }}</span></template
                                    >
                                    <template slot="action">
                                        <span class="text-muted m-click"
                                            >{{
                                                $t(
                                                    "dashboard.statbox_avg_per_day"
                                                )
                                            }}
                                            {{ stats.avg_month | toTime }}
                                        </span>
                                    </template></inner-dash-card
                                >
                            </div>

                            <div class="col-12 col-md">
                                <inner-dash-card
                                    :title="$t('dashboard.statbox_avg_lastmon')"
                                    icon="far fa-building"
                                    ><template slot="default"
                                        ><span class="text-default">{{
                                            stats.reg_last_month | toTime
                                        }}</span></template
                                    >
                                    <template slot="action">
                                        <span class="text-muted m-click"
                                            >{{
                                                $t(
                                                    "dashboard.statbox_avg_per_day"
                                                )
                                            }}
                                            {{ stats.avg_last_month | toTime }}
                                        </span>
                                    </template></inner-dash-card
                                >
                            </div>

                            <div class="col-12 col-md">
                                <inner-dash-card
                                    :title="$t('dashboard.statbox_avg_last3')"
                                    icon="far fa-building"
                                    ><template slot="default"
                                        ><span class="text-default">{{
                                            stats.reg_month | toTime
                                        }}</span></template
                                    >
                                    <template slot="action">
                                        <span class="text-muted m-click"
                                            >{{
                                                $t(
                                                    "dashboard.statbox_avg_per_day"
                                                )
                                            }}
                                            {{ stats.avg_month | toTime }}
                                        </span>
                                    </template></inner-dash-card
                                >
                            </div>

                            <div class="col-12 col-md">
                                <inner-dash-card
                                    :title="$t('dashboard.statbox_avg_ytd')"
                                    icon="far fa-building"
                                    ><template slot="default"
                                        ><span class="text-default">{{
                                            stats.reg_last3 | toTime
                                        }}</span></template
                                    >
                                    <template slot="action">
                                        <span class="text-muted m-click"
                                            >{{
                                                $t(
                                                    "dashboard.statbox_avg_per_day"
                                                )
                                            }}
                                            {{ stats.avg_last3 | toTime }}
                                        </span>
                                    </template></inner-dash-card
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mb-4">
                                {{
                                    $t(
                                        "dashboard.avg_per_day_compared_with_daily_goal_DAILY",
                                        { DAILY: dailyTarget }
                                    )
                                }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md text-center">
                                <i class="far fa-arrow-down fa-lg"></i>
                                <div
                                    class="text-center text-bold mt-3"
                                    :class="targetWeek < 0 ? 'text-danger' : ''"
                                >
                                    <span v-if="targetWeek < 0">-</span
                                    >{{ targetWeek | toTime }}
                                </div>
                            </div>
                            <div class="col-12 col-md text-center">
                                <i class="far fa-arrow-down fa-lg"></i>
                                <div
                                    class="text-center text-bold mt-3"
                                    :class="
                                        targetMonth < 0 ? 'text-danger' : ''
                                    "
                                >
                                    <span v-if="targetMonth < 0">-</span
                                    >{{ targetMonth | toTime }}
                                </div>
                            </div>
                            <div class="col-12 col-md text-center">
                                <i class="far fa-arrow-down fa-lg"></i>
                                <div
                                    class="text-center text-bold mt-3"
                                    :class="
                                        targetLastMonth < 0 ? 'text-danger' : ''
                                    "
                                >
                                    <span v-if="targetLastMonth < 0">-</span
                                    >{{ targetLastMonth | toTime }}
                                </div>
                            </div>
                            <div class="col-12 col-md text-center">
                                <i class="far fa-arrow-down fa-lg "></i>
                                <div
                                    class="text-center text-bold mt-3"
                                    :class="
                                        targetLast3 < 0 ? 'text-danger' : ''
                                    "
                                >
                                    <span v-if="targetLast3 < 0">-</span
                                    >{{ targetLast3 | toTime }}
                                </div>
                            </div>
                            <div class="col-12 col-md text-center">
                                <i class="far fa-arrow-down fa-lg"></i>
                                <div
                                    class="text-center text-bold mt-3"
                                    :class="targetYtd < 0 ? 'text-danger' : ''"
                                >
                                    <span v-if="targetYtd < 0">-</span
                                    >{{ targetYtd | toTime }}
                                </div>
                            </div>
                        </div>

                        <p class="text-bold mt-6">
                            {{ $t("dashboard.averages_notice") }}
                        </p>
                    </template>
                </div>
            </card>
        </div>
    </div>
</template>
