<script>
import { mapGetters } from "vuex";
import Cloner from "@/libraries/Cloner";
import MGConsts from "@/mixins/MGConsts";
import AppCfg from "@/mixins/AppCfg";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";

export default {
    name: "VEditProjectMilestoneForm",
    props: {
        projectId: {}
    },
    mixins: [MGConsts, AppCfg],
    data() {
        return {
            loading: true,
            errors: null,
            milestones: [],
            selected_milestones: {},
            immutable: {}
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);
        await this.fetchData();

        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await ProjectMilestoneService.GET_getForAdding(
                    this.projectId
                );
                this.milestones = data.data;
                this.milestones.forEach(e => {
                    if (e.project === null) {
                        this.$set(
                            this.selected_milestones,
                            e.milestone_id,
                            false
                        );
                    } else {
                        this.immutable[e.milestone_id] = true;
                        this.$set(
                            this.selected_milestones,
                            e.milestone_id,
                            true
                        );
                    }
                });
            } catch (e) {}

            this.loading = false;
        },
        /**
         * Save form
         */
        async saveForm() {
            try {
                const r = await ProjectMilestoneService.POST_addToProject(
                    this.projectId,
                    this.selected_milestones
                );

                this.$notify({
                    message: this.$t(
                        "project_milestone.notification_selected_milestones_added"
                    ),
                    timeout: 8000,
                    icon: "far fa-check",
                    type: "success"
                });
                this.$emit("close-refresh");
            } catch (e) {
                console.log("sf e", e);
                this.$notify({
                    message: this.$t(
                        "project_milestone.notification_milestone_not_added_error"
                    ),
                    timeout: 8000,
                    icon: "far fa-check",
                    type: "danger"
                });

                this.errors = e.response.data.errors;
            }
        },
        selectAll() {
            for (const [key, value] of Object.entries(this.selected_milestones)) {
                if (!(key in this.immutable)) {
                    this.selected_milestones[key] = !this.selected_milestones[key];
                }
            }
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
        size="full"
    >
        <template slot="header">
            {{ $t("project_milestones.edit") }}
        </template>

        <template slot="default">
            <div class="row">
                <div class="col-12">
                    <p>
                        {{ $t("project_milestones.edit_modal_help_text") }}
                    </p>
                </div>
                <div class="col-12">
                    <v-table class="table table-sm">
                        <v-thead>
                            <v-tr>
                                <v-th><input type="checkbox" @click="selectAll" /></v-th>
                                <v-th>{{
                                    $t("project_milestones.tbl_hdr_name")
                                }}</v-th>
                                <v-th>{{
                                    $t(
                                        "project_milestones.tbl_hdr_status_in_project"
                                    )
                                }}</v-th>
                                <v-th>{{
                                    $t(
                                        "project_milestones.tbl_hdr_comment_in_project"
                                    )
                                }}</v-th>
                                <v-th>{{
                                    $t(
                                        "project_milestones.tbl_hdr_comment_global"
                                    )
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr
                                v-for="m in milestones"
                                :key="m.id"
                                :class="
                                    m.project !== null
                                        ? 'milestone-is-added'
                                        : 'milestone-is-not-added'
                                "
                            >
                                <v-td
                                    ><input
                                        type="checkbox"
                                        v-model="
                                            selected_milestones[m.milestone_id]
                                        "
                                        :disabled="m.project !== null"
                                /></v-td>
                                <v-td>{{ m.name }}</v-td>
                                <template v-if="m.project === null">
                                    <v-td>---</v-td>
                                    <v-td>---</v-td>
                                    <v-td><v-newlines :text="m.comment"/></v-td>
                                </template>
                                <template v-else>
                                    <v-td>
                                        <span
                                            :class="{
                                                'text-success':
                                                    m.project.status === 1,
                                                'text-danger':
                                                    m.project.status !== 1
                                            }"
                                            >{{
                                                appCfgRead(
                                                    "ProjectMilestones.status"
                                                )[m.project.status]
                                            }}
                                        </span>
                                    </v-td>
                                    <v-td
                                        ><v-newlines :text="m.project.comment"
                                    /></v-td>
                                    <v-td><v-newlines :text="m.comment"/></v-td>
                                </template>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </div>

            <div class="row mt-4" v-if="errors !== null">
                <div class="col-12">
                    <div class="alert alert-danger text-center">{{ errors.join("") }}</div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 text-right">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        @click="saveForm"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
.milestone-is-added {
    td {
        color: $success !important;
        font-weight: bold;
    }
}

.milestone-is-not-added {
    td {
        color: #777 !important;
    }
}
</style>
