<script>
import VSystemActivityForm from "@/components/Forms/VSystemActivityForm";
import TaskService from "@/services/TaskService"; 
import { EventBus } from "@/libraries/EventBus";
import ActivityBag from "@/libraries/ActivityBag"; 

export default {
    name: "VSystemTask",
    props: {
        context: {
            description: "System task object",
        },
        meta: {
            description: "Day meta",
        },
    },
    components: { VSystemActivityForm },
    data() {
        return {
            showModal: false,
            editContext: null,            
        };
    },
    computed: {
        acts: function () {
            return ActivityBag.activities;
        },
        act: function () {
            return this.acts[this.context.activity.system_function];
        },
    },
    methods: {
        editTask() {
            this.editContext = this.context;
            this.showModal = true;
        },
        async taskSaved() {
            this.showModal = false;
            this.$emit('refresh-meta');
        },
       
        async handleDeleteTask() {
            this.$swal({
                icon: "warning",
                text: this.$t("task.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async (confirmed) => {
                if (confirmed.isConfirmed) {
                    await this.deleteTask();                    
                    this.$notify({
                        message: this.$t("task.notify_system_task_deleted"),
                        timeout: 5000,
                        icon: "far fa-check",
                        type: "success",
                    });               
                    
                    this.$emit('refresh-meta');                     
                }
            });
        },
        /**
         * Delete the task
         * TBD TODO
         */
        async deleteTask() {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await TaskService.DELETE(this.context.id);                
                EventBus.$emit("task-delete");
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: map(e.response.data.errors, (n) => {
                            return n.join(".\n");
                        }),
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error"),
                    });
                }
            }
        },
    },

};
</script>

<template>
    <div class="v-system-task" :class="`v-system-task--${act.class}`">
        <div class="v-system-task__container">
            <i class="far fa-fw mr-3" :class="act.icon"></i>
            {{ context.time_reg | toTime }}
            {{ act.text }}
            <i
                v-if="act.showLock"
                class="far fa-fw ml-auto"
                :class="{
                    'fa-lock text-warning': context.is_closed == 1,
                    'fa-lock-open text-success': context.is_closed == 0,
                }"
            ></i>
            <base-dropdown
                :menu-on-right="true"
                :has-toggle="false"
                v-if="context.is_closed == 0 && context.activity.system_function != 'sys_not_work'"
            >
                <template slot="title-container"
                    ><i class="ml-3 far fa-ellipsis-h fa-fw"></i
                ></template>
                <div class="dropdown-item" @click="editTask">
                    <i class="far fa-fw fa-pencil"></i>
                    {{ $t("task.action_open_modal") }}
                </div>
                <div class="dropdown-item text-danger" @click="handleDeleteTask">
                    <i class="far fa-fw fa-trash"></i>
                    {{ $t("task.action_delete") }}
                </div>
            </base-dropdown>
            <pre v-if="false">{{ context }}</pre>
        </div>
        <portal to="modals">
            <v-system-activity-form
                v-if="showModal"
                :context="editContext"
                @close="showModal = false"
                @save="taskSaved"
            />
        </portal>
    </div>
</template>

<style>
</style>