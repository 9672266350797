import Http from "@/libraries/Http";

export default {
    apiVersion: 'v1',
    RESTroute: 'project-changes',

    async GET_logs(project_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/logs/${project_id}`, {params: params});
    },


}
