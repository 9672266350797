<script>
import { mapGetters } from "vuex";

import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import InnerDashCard from "@/components/Cards/InnerDashCard";

import ProjectService from "@/services/ProjectService";
import DateRangeMixin from "@/mixins/DateRangeMixin";

export default {
    name: "VStats",
    components: { InnerDashCard },
    props: {
        project_id: {
            description: "Project ID",
        },
    },
    mixins: [DateRangeMixin],
    data() {
        return {
            loading: false,
            project: null,
            stats: null,
            cstats: null,
            years: [],
            year: "2021",
            showWeek: true,
            payment: null
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        hasData: function () {
            if (this.activityBreakdown.series.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        activityBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.activityBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.activity_name);
            });

            return data;
        },
        teamBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.teamBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.user_name);
            });

            return data;
        },
    },
    watch: {
        year: function (n) {
            this.fetchData();
        },
    },
    async created() {
        await this.fetchProject(true);
        await this.fetchData();
    },
    methods: {
        weekNo(tst) {
            return dayjs(tst).isoWeek();
        },
        resetRange() {
            this.dateRange = {
                startDate: null,
                endDate: null,
            };
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = {
                    year: this.year,
                };

                const r = await ProjectService.GET_contractStatistics(
                    this.project_id,
                    params
                );
                this.stats = r.data.data.stats;
                this.cstats = r.data.data.contract;
                this.years = r.data.data.years;
                this.payment = r.data.data.payment;
            } catch (e) {
                console.log("fetchData exception", e);
            }
            this.loading = false;
        },
        async fetchProject(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("Faker", e);
            }
            this.loading = loading;
        },
    },
};
</script>

<template>
    <div>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center flex-row">
                        <div class="v-stats-rp">
                            <FormulateInput
                                v-model="year"
                                type="select"
                                :options="years"
                                v-if="!loading && years.length > 0"
                            />
                        </div>
                        <!-- <div
                            class="text-muted small m-click m-link ml-3"
                            @click="resetRange"
                        >
                            <i class="far fa-sync"></i>
                            {{ $t("invoice_line.reset_range") }}
                        </div> -->
                    </div>
                </div>
                <div class="col-12" v-if="loading">
                    <v-loader :loading="true" />
                </div>

                <div class="col-12" v-else-if="!hasData">
                    <div class="alert alert-warning alert-outline">
                        {{ $t("inv_stats.no_data_found_try_different_period") }}
                    </div>
                </div>

                <div class="col-12" v-else>
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-2">
                            {{
                                $t(
                                    "inv_stats.activity_distribution_and_budget_statistics"
                                )
                            }}
                        </h3>

                        <p class="px-4 mb-4">{{$t('stats.admin_cases_lead_in_text_for_stats')}}</p>

                        <div class="px-4">
                            <!-- Stat for non-internal customers -->
                            <div
                                class="row v-hours-week__statboxes"
                                v-if="project.customer.internal_customer == 1"
                            >
                                <!-- Box -->
                                <pre v-if="false">{{ stats }}</pre>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_time')
                                        "
                                        icon="far fa-clock"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .non_billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <div class="row v-hours-week__statboxes" v-else>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="$t('inv_stats.billable_time')"
                                        icon="far fa-stopwatch"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_time')
                                        "
                                        icon="far fa-ban"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .non_billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="$t('inv_stats.billable_amount')"
                                        icon="far fa-sack"
                                        ><template slot="default"
                                            ><span
                                                class="text-default"
                                                >{{
                                                    $n(
                                                        stats.billableStats
                                                            .billable_amount,
                                                        {
                                                            style: "decimal",
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )
                                                }}</span
                                            ></template
                                        >
                                        <template slot="action">{{
                                            project.currency
                                        }}</template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_amount')
                                        "
                                        icon="far fa-ban"
                                        ><template slot="default"
                                            ><span
                                                class="text-default"
                                                >{{
                                                    $n(
                                                        stats.billableStats
                                                            .non_billable_amount,
                                                        {
                                                            style: "decimal",
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )
                                                }}</span
                                            ></template
                                        >
                                        <template slot="action">{{
                                            project.currency
                                        }}</template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <!-- 2. line of stats -->
                            <div class="row v-hours-week__statboxes">
                                <!-- Box -->
                                <div class="col-12 col-md-3">
                                    <inner-dash-card
                                        :title="
                                            $t('stats.calculated_fee_for_#year', {
                                                year: year,
                                            })
                                        "
                                        icon="far fa-calculator"
                                        ><template slot="default"
                                            ><span
                                                class="text-default"
                                                >{{
                                                    $n(payment.yearly_fee, {
                                                        style: "decimal",
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    })
                                                }}</span
                                            ></template
                                        >
                                        <template slot="action"
                                            >{{ payment.currency }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-3">
                                    <inner-dash-card
                                        :title="
                                            $t('stats.invoiced_in_#year', {
                                                year: year,
                                            })
                                        "
                                        icon="far fa-money-bill-wave"
                                        ><template slot="default"
                                            ><span
                                                class="text-default"
                                                >{{
                                                    $n(cstats.invoiced_sum, {
                                                        style: "decimal",
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    })
                                                }}</span
                                            ></template
                                        >
                                        <template slot="action"
                                            >{{ cstats.currency }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-3">
                                    <inner-dash-card
                                        :title="$t('stats.remaining_budget')"
                                        icon="far fa-sack-dollar"
                                        ><template slot="default"
                                            ><span
                                                class="text-default"
                                                >{{
                                                    $n(cstats.remaining, {
                                                        style: "decimal",
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    })
                                                }}</span
                                            ></template
                                        >
                                        <template slot="action"
                                            >{{ cstats.currency }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-3">
                                    <inner-dash-card
                                        :title="$t('stats.%_budget')"
                                        icon="far fa-percent"
                                        ><template slot="default"
                                            ><span class="text-default"
                                                >{{
                                                    $n(cstats.perc_usage)
                                                }}
                                                %</span
                                            ></template
                                        >
                                        <template slot="action"> </template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <!-- End of boxes -->
                            <!-- Graph -->
                            <!-- Billable and non billable time (amount of hrs) stacked per day for a given period -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="btn-group mb-3" role="group">
                                        <base-button
                                            @click="showWeek = true"
                                            type="primary"
                                            size="sm"
                                            :outline="true"
                                            :class="{ active: showWeek }"
                                            >{{
                                                $t("reports.weekly")
                                            }}</base-button
                                        >
                                        <base-button
                                            type="primary"
                                            @click="showWeek = false"
                                            size="sm"
                                            :outline="true"
                                            :class="{ active: !showWeek }"
                                            >{{
                                                $t("reports.monthly")
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 v-hours-week__graph"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                >
                                    <div style="display: block">
                                        <apexchart
                                            v-if="showWeek"
                                            height="400px"
                                            :options="{
                                                chart: {
                                                    width: '100%',
                                                    type: 'bar',
                                                    stacked: true,
                                                }

                                            }"
                                            :series="[
                                                {
                                                    name: $t(
                                                        'inv_stats.billable_time'
                                                    ),
                                                    data:
                                                        stats.graph.series
                                                            .billable_time,
                                                },
                                                {
                                                    name: $t(
                                                        'inv_stats.non_billable_time'
                                                    ),
                                                    data:
                                                        stats.graph.series
                                                            .non_billable_time,
                                                },
                                            ]"
                                        ></apexchart>

                                        <apexchart
                                            v-else
                                            height="400px"
                                            :options="{
                                                chart: {
                                                    width: '100%',
                                                    type: 'bar',
                                                    stacked: true,
                                                }

                                            }"
                                            :series="[
                                                {
                                                    name: $t(
                                                        'inv_stats.billable_time'
                                                    ),
                                                    data:
                                                        stats.graph.yseries
                                                            .billable_time,
                                                },
                                                {
                                                    name: $t(
                                                        'inv_stats.non_billable_time'
                                                    ),
                                                    data:
                                                        stats.graph.yseries
                                                            .non_billable_time,
                                                },
                                            ]"
                                        ></apexchart>

                                    </div>
                                </div>
                                <div class="col-12 v-hours-week__graph" v-else>
                                    <div style="display: block">
                                        <apexchart
                                            v-if="true"
                                            height="400px"
                                            :options="{
                                                chart: {
                                                    width: '100%',
                                                    type: 'bar',
                                                    stacked: true,
                                                },
                                                xaxis: {
                                                    type: 'datetime',
                                                    labels: {
                                                        format: 'dd/MM',
                                                    },
                                                },
                                            }"
                                            :series="[
                                                {
                                                    name: $t(
                                                        'inv_stats.non_billable_time'
                                                    ),
                                                    data:
                                                        stats.graph.series
                                                            .non_billable_time,
                                                },
                                            ]"
                                        ></apexchart>
                                        <pre v-if="false">{{
                                            stats.graph
                                        }}</pre>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Graph -->
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->

            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activity_distribution") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityBreakdown.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.team_distribution") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: teamBreakdown.labels,
                                    legend: {
                                        position: 'right',
                                    },
                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="teamBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activities") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>
                                    <v-th
                                        class="text-right"
                                        v-if="
                                            project.customer
                                                .internal_customer != 1
                                        "
                                        >{{ $t("inv_stats.amount") }}</v-th
                                    >
                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>
                                <v-td
                                    class="text-right"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                    >{{
                                        $n(u.total_amount, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td
                                >
                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.activityBreakdown }}</pre>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.team_members") }}
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.user") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>
                                    <v-th
                                        class="text-right"
                                        v-if="
                                            project.customer
                                                .internal_customer != 1
                                        "
                                        >{{ $t("inv_stats.amount") }}</v-th
                                    >
                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.teamBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.user_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>
                                <v-td
                                    class="text-right"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                    >{{
                                        $n(u.total_amount, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td
                                >
                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.teamBreakdown }}</pre>
                    </card>
                </div>
            </div>
        </div>
        <!-- .container-fluid -->
    </div>
    <!-- .master div -->
</template>

<style lang="scss">
.v-stats-rp {
    flex: 0 0 300px;
}
</style>
