<script>
import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import InnerDashCard from "@/components/Cards/InnerDashCard";

import TaskService from "@/services/TaskService";
import { EventBus } from "@/libraries/EventBus";

export default {
    name: "VHoursWeek",
    components: { InnerDashCard },

    data() {
        return {
            loading: true,
            selectedDate: null,
            stats: null,
            showTarget: false,
            options: {
                chart: {
                    id: "mainchart",
                    width: "100%",
                    type: "bar",
                    stacked: true,
                },
                xaxis: {
                    categories: [
                        this.$t("calendar.mon"),
                        this.$t("calendar.tue"),
                        this.$t("calendar.wed"),
                        this.$t("calendar.thu"),
                        this.$t("calendar.fri"),
                        this.$t("calendar.sat"),
                        this.$t("calendar.sun"),
                    ],
                },
            },
            series: [],
        };
    },
    computed: {
        today: function () {
            return dayjs().format("YYYY-MM-DD");
        },
        weekStart: function () {
            return dayjs().startOf("isoWeek").format("YYYY-MM-DD");
        },
        ranges: function () {
            let range = this.dateRange;
            return [
                {
                    highlight: {
                        color: "gray",
                        fillMode: "solid",
                    },
                    key: "se",
                    dates: {
                        start: range.startDate,
                        span: 7,
                    },
                },
            ];
        },
        dateRange: function () {
            if (!this.selectedDate) {
                return {
                    startDate: dayjs().format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                };
            }

            let startDate = dayjs(this.selectedDate)
                .startOf("isoWeek")
                .format("YYYY-MM-DD");
            let endDate = dayjs(this.selectedDate)
                .endOf("isoWeek")
                .format("YYYY-MM-DD");

            return {
                startDate,
                endDate,
            };
        },
    },
    watch: {
        selectedDate: function (nDate) {
            this.fetchData();
        },
    },
    created() {
        this.selectedDate = this.today;
        this.fetchData();
        EventBus.$on("task-created", this.fetchData);
    },
    methods: {
        goalCount(total, goal) {
            return parseInt(goal) - parseInt(total);
        },
        /**
         * Fetch period stats
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.dateRange;
                const r = await TaskService.GET_periodStats(params);
                this.stats = r.data.data;

                this.series = [
                    {
                        name: this.$t("dashboard.customer_related"),
                        data: this.stats.series.ext_time,
                    },
                    {
                        name: this.$t("dashboard.internal_time"),
                        data: this.stats.series.int_time,
                    },
                    {
                        name: this.$t("dashboard.other_time"),
                        data: this.stats.series.other_time,
                    },
                ];
            } catch (e) {
                console.log("fetchData exception", e);
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="v-hours-week row">
        <div class="col-12">
            <card body-classes="px-0">
                <div class="px-4">
                    <div class="d-flex align-items-center mb-3">
                        <h3 class="mb-0">
                            <i class="far fa-calendar-week"></i>
                            {{ $t("dashboard.weekly_overview") }}
                        </h3>
                        <div class="ml-3">
                            <date-picker
                                :attributes="ranges"
                                show-weeknumbers
                                class="v-range-picker"
                                v-model="selectedDate"
                                :model-config="{
                                    type: 'string',
                                    mask: 'YYYY-MM-DD',
                                }"
                                :is-required="true"
                                :popover="{
                                    positionFixed: true,
                                    visibility: 'focus',
                                    keepVisibleOnInput: false,
                                    transition: 'none',
                                    showDelay: 1,
                                    hideDelay: 1,
                                }"
                            >
                                <template v-slot="{ togglePopover }">
                                    <div
                                        class="v-hours-week__datepicker m-click"
                                        @click="
                                            togglePopover({
                                                placement: 'bottom',
                                            })
                                        "
                                    >
                                        {{
                                            $d(
                                                new Date(dateRange.startDate),
                                                "short"
                                            )
                                        }}
                                        -
                                        {{
                                            $d(
                                                new Date(dateRange.endDate),
                                                "short"
                                            )
                                        }}
                                        <i class="ml-1 fas fa-caret-down"></i>
                                    </div>
                                </template>
                            </date-picker>
                        </div>
                        <div class="v-dash-close-btn" v-if="false">
                            <i class="far fa-times fa-lg"></i>
                        </div>
                    </div>
                    <v-loader :loading="loading" v-if="loading" />
                    <div class="row v-hours-week__statboxes" v-else>
                        <div
                            class="col-12 col-md-6 col-lg-3"
                            @mouseenter="showTarget = true"
                            @mouseleave="showTarget = false"
                        >
                            <inner-dash-card
                                :title="$t('dashboard.statbox_total_time')"
                                icon="far fa-stopwatch"
                                ><template slot="default"
                                    ><span class="text-default">{{
                                        stats.total_time | toTime
                                    }}</span></template
                                >
                                <template slot="action">
                                    <template
                                        v-if="parseInt(stats.period_goal) > 0"
                                    >
                                        <span
                                            v-if="!showTarget"
                                            :class="{
                                                'text-warning':
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) > 0,
                                                'text-success':
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) <= 0,
                                            }"
                                        >
                                            {{ $t("dashboard.goal") }}:
                                            <template
                                                v-if="
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) == 0
                                                "
                                            >
                                                {{ 0 | toTime }}
                                            </template>
                                            <template
                                                v-else-if="
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) < 0
                                                "
                                            >
                                                +{{
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) | toTime
                                                }}
                                            </template>
                                            <template v-else>
                                                -{{
                                                    goalCount(
                                                        stats.total_time,
                                                        stats.period_goal
                                                    ) | toTime
                                                }}
                                            </template>
                                        </span>
                                        <span v-else
                                            >{{ $t("dashboard.weekly_goal") }}:
                                            {{
                                                stats.period_goal | toTime
                                            }}</span
                                        ></template
                                    >
                                </template></inner-dash-card
                            >
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="
                                    $t(
                                        'dashboard.statbox_customer_related_time'
                                    )
                                "
                                icon="far fa-building"
                                ><template slot="default"
                                    ><span class="text-default">{{
                                        stats.total_ext_time | toTime
                                    }}</span></template
                                >
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{ $n(stats.total_ext_time_perc) }}%
                                        {{ $t("dashboard.of_total") }}</span
                                    >
                                </template></inner-dash-card
                            >
                        </div>
                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="$t('dashboard.statbox_internal_time')"
                                icon="far fa-house"
                                ><template slot="default"
                                    ><span class="text-default">{{
                                        stats.total_int_time | toTime
                                    }}</span></template
                                >
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{ $n(stats.total_int_time_perc) }}%
                                        {{ $t("dashboard.of_total") }}</span
                                    >
                                </template></inner-dash-card
                            >
                        </div>

                        <div class="col-12 col-md-6 col-lg-3">
                            <inner-dash-card
                                :title="$t('dashboard.other_time')"
                                icon="far fa-user-slash"
                            >
                                <template slot="default">
                                    <span class="text-default">{{
                                        stats.total_other_time | toTime
                                    }}</span>
                                </template>
                                <template slot="action">
                                    <span class="text-muted m-click"
                                        >{{ $n(stats.total_other_time_perc) }}%
                                        {{ $t("dashboard.of_total") }}</span
                                    >
                                </template>
                            </inner-dash-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 v-hours-week__graph">
                            <div style="display: block">
                                <apexchart
                                    height="400px"
                                    :options="options"
                                    :series="series"
                                ></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>

<style lang="scss">
</style>
