<script>
export default {
    name: "OuterDashCard",
    props: {
        title: {
            default: "",
        },
        icon: {
            default: "far fa-star",
        },
        small: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<template>
    <div class="card outer-dash-card">
        <!-- Card body -->
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                        {{ title }}
                    </h5>                    
                </div>
                <div class="col-auto outer-dash-card__icon">
                    <i class="fa-fw" :class="icon"></i>
                </div>
            </div>
            <div class="row outer-dash-card__slots" >
                <div class="col outer-dash-card__left" :class="{'outer-dash-card__left--small': small}"><slot name="default"></slot></div>                
            </div>            
            <div class="row outer-dash-card__subtitle" v-if="$slots.action">
                <div class="col"><slot name="action"></slot></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.outer-dash-card {

    box-shadow: none;
    border: 1px solid white; 
    border-radius: 6px;

    &__slots {
        display: flex;
        align-items: baseline;        
    }

    &__left {
        //border: 1px dotted red;
        font-size: 30px; 
        font-weight: 600;

        &--small {
            margin-top: 8px;
            margin-bottom: 4px;
            font-size:23px;
        }
    }    

    &__subtitle {
        //border: 1px solid red;
        color: $gray-600;
        font-size: 12px;
        
    }

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }

    &__icon {        
        font-size: 20px;
    }
}
</style>