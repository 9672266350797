var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"currency-modal",attrs:{"size":"lg","show":true,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[(_vm.editing)?[_vm._v(" "+_vm._s(_vm.$t("project.edit_member"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("project.add_member"))+" ")]],2),_c('template',{slot:"default"},[_c('FormulateForm',{attrs:{"errors":_vm.errors},on:{"submit":_vm.saveForm}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('FormulateInput',{attrs:{"name":"user_id","type":"multiselect","options":_vm.users,"label":_vm.$t('project.user_name'),"validation-name":_vm.$t('project.user_name'),"validation":"required","help":_vm.editing == false
                                ? _vm.$t(
                                      'project.help_only_users_with_hour_price'
                                  )
                                : ''},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1)]),(_vm.user_id > 0)?[(_vm.innerLoading)?_c('v-loader',{attrs:{"loading":_vm.innerLoading}}):[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('FormulateInput',{attrs:{"type":"currency","currency":_vm.currency,"name":"default_hour_price","label":_vm.$t('project.default_hour_price'),"validation-name":_vm.$t('project.default_hour_price'),"help":_vm.converted
                                        ? _vm.$t(
                                              'project.help_hour_price_is_currency_converted'
                                          )
                                        : ''},model:{value:(_vm.default_hour_price),callback:function ($$v) {_vm.default_hour_price=$$v},expression:"default_hour_price"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('FormulateInput',{attrs:{"type":"groupdecimal","name":"discount_percent","append":"%","label":_vm.$t('project.discount_percent'),"validation-name":_vm.$t('project.discount_percent'),"validation":"required|number|between:-1,101"},model:{value:(_vm.discount_percent),callback:function ($$v) {_vm.discount_percent=$$v},expression:"discount_percent"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.editing)?_c('div',{staticClass:"text-warning"},[_c('i',{staticClass:"far fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.$t( "project.text_when_editing_user_hour_rate_applies_in_the_future" ))+" ")]):_vm._e(),_c('hr')]),_c('div',{staticClass:"col-12 col-md-6"},[_c('FormulateInput',{attrs:{"type":"currency","currency":_vm.currency,"name":"hour_price","label":_vm.$t('project.effective_hour_price'),"validation-name":_vm.$t('project.effective_hour_price'),"disabled":true,"help":_vm.$t('project.help_effective_hour_price')},model:{value:(_vm.hour_price),callback:function ($$v) {_vm.hour_price=$$v},expression:"hour_price"}})],1)]),_c('div',{staticClass:"text-right mt-4"},[_c('base-button',{attrs:{"type":"secondary","outline":true},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('base-button',{attrs:{"type":"primary","loading":_vm.loading,"native-type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))])],1)]]:_vm._e()],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }