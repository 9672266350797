<script>
import TaskService from "@/services/TaskService";

export default {
    name: "ConnectedTasks",
    props: {
        invoiceLineId: {
            description: "Invoice line ID to check if any tasks are there",
        },
        emptyNotice: {
            type: Boolean,
            default: false,
            description: "Show empty notice if no related elements found",
        },
    },
    data() {
        return {
            loading: false,
            tasks: [],
            meta: null,
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await TaskService.GET({
                    ...params,
                    invoice_line_id: this.invoiceLineId,
                });
                this.tasks = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="connected-tasks">
        <v-loader :loading="loading" v-if="loading" />
        <div v-else-if="tasks.length > 0">
            <h3>{{ $t("tasks.tasks_connected_to_an_invoice_line") }}</h3>
            <div class="table-responsive">
                <v-table class="table table-mg table-sm">
                    <v-thead>
                        <v-tr>
                            <v-th>{{ $t("task.date") }}</v-th>
                            <v-th>{{ $t("task.activity") }}</v-th>
                            <v-th>{{ $t("task.description") }}</v-th>
                            <v-th>{{ $t("task.user") }}</v-th>
                            <v-th class="text-right">{{
                                $t("task.hour_rate")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("task.task_price")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("task.used_time")
                            }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="task in tasks" :key="task.id">
                            <v-td>{{
                                $d(new Date(task.task_date), "short")
                            }}</v-td>
                            <v-td>{{ task.activity.name }}</v-td>
                            <v-td>{{ task.details }}</v-td>
                            <v-td>{{ task.user.name }}</v-td>
                            <v-td class="text-right">{{
                                $n(task.hour_price, {
                                    style: "currency",
                                    currency: task.currency,
                                    currencyDisplay: "code",
                                })
                            }}</v-td>
                            <v-td class="text-right">{{
                                $n(task.task_price, {
                                    style: "currency",
                                    currency: task.currency,
                                    currencyDisplay: "code",
                                })
                            }}</v-td>
                            <v-td class="text-right">{{
                                task.time_reg | toTime
                            }}</v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </div>
            <div class="mt-5">
                <div class="paginator d-flex justify-content-center">
                    <base-pagination
                        :page-count="meta.pagination.total_pages"
                        :per-page="meta.pagination.per_page"
                        :value="meta.pagination.current_page"
                        :total="meta.pagination.total"
                        @input="$handlePagination($event)"
                    />
                </div>
            </div>
        </div>
        <div class="alert alert-info alert-outline" v-else-if="emptyNotice">
            {{
                $t(
                    "invoice.message_invoice_line_has_no_connected_elements_custom_line"
                )
            }}
        </div>
    </div>
</template>


<style>
</style>
