<script>
import dayjs from "dayjs";
import VCloseMultipleForm from "@/components/Forms/VCloseMultipleForm";
import TaskService from "@/services/TaskService";

export default {
    name: "VOpenDays",
    components: { VCloseMultipleForm },
    data() {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        return {
            masks: {
                weekdays: "WWW"
            },
            lastSelected: "",
            attrs: [],
            rawData: [],
            showModal: false
        };
    },
    computed: {
        canClose() {
            if (this.rawData.length === 0) {
                return false;
            }
            
            let check = false; 
            
            this.rawData.forEach (e => {
                if (e.is_closed === false && e.total_time > 0) {
                    check = true;
                }
            });

            return check;
        }
    },
    methods: {
        async fetchAttrs(a) {
            this.lastSelected = a;

            try {
                let dt = dayjs(`${a.year}-${a.month}-01`).format("YYYY-MM-DD");
                let params = {
                    startDate: dt,
                    endDate: dayjs(dt)
                        .endOf("month")
                        .format("YYYY-MM-DD")
                };

                const r = await TaskService.GET_daysStatus(params);
                this.attrs = [];
                this.rawData = r.data.data;

                r.data.data.forEach((v, idx) => {
                    let dayObj = {
                        key: idx,
                        customData: {
                            title: "",
                            class: "",
                            link: {
                                name: "Dashboard",
                                query: { showDate: v.task_date }
                            }
                        },
                        dates: new Date(v.task_date)
                    };

                    if (v.is_closed) {
                        // closed day
                        dayObj.customData.title =
                            this.$t("dashboard.closed") +
                            " (" +
                            this.$options.filters.toTime(v.total_time) +
                            ")";
                        dayObj.customData.class =
                            "bg-warning text-white text-semibold text-center";
                    } else {
                        // open day
                        dayObj.customData.title =
                            this.$t("dashboard.open") +
                            " (" +
                            this.$options.filters.toTime(v.total_time) +
                            ")";
                        dayObj.customData.class =
                            "bg-success text-white text-semibold text-center";
                    }

                    this.attrs.push(dayObj);
                });
            } catch (e) {
                console.log("fetchAttrs expcetion", e);
            }

            console.log("TEST", a.month, a.year);
        },
        closeAll() {
            this.showModal = true;
        },
        async closeRefresh() {
            await this.fetchAttrs(this.lastSelected);
            this.showModal = false;
        }
    }
};
</script>

<template>
    <div class="v-open-days row">
        <div class="col-12">
            <card body-classes="px-0">
                <div class="px-4 pt-4">
                    <div class="d-flex flex-row align-items-center mb-3">
                        <h3 class="mb-0">
                            <i class="far fa-calendar-exclamation"></i>
                            {{ $t("dashboard.open_days") }}
                        </h3>
                        <div class="ml-auto d-flex">
                            <base-button
                                :disabled="!canClose"
                                @click="closeAll"
                                :outline="true"
                                type="warning"
                                size="sm"
                                ><i class="far fa-lock"></i>
                                {{
                                    $t("dashboard.close_all_days_with_reg_time")
                                }}</base-button
                            >
                        </div>
                    </div>
                </div>
                <v-calendar
                    class="v-cal-full custom-calendar max-w-full"
                    :masks="masks"
                    :attributes="attrs"
                    disable-page-swipe
                    is-expanded
                    @update:from-page="fetchAttrs"
                >
                    <template v-slot:day-content="{ day, attributes }">
                        <div class="flex flex-col h-full z-10 overflow-hidden">
                            <span class="day-label text-sm">{{ day.day }}</span>
                            <div
                                class="flex-grow overflow-y-auto overflow-x-auto"
                            >
                                <p
                                    v-for="attr in attributes"
                                    :key="attr.key"
                                    class="text-xs text-white rounded-sm p-1 mt-0 mb-1 m-click"
                                    :class="attr.customData.class"
                                    @click="$router.push(attr.customData.link)"
                                >
                                    {{ attr.customData.title }}
                                </p>
                            </div>
                        </div>
                    </template>
                </v-calendar>
            </card>
        </div>
        <portal to="modals">
            <v-close-multiple-form
                @close="showModal = false"
                @close-refresh="closeRefresh"
                :data="rawData"
                v-if="showModal"
            />
        </portal>
    </div>
</template>

<style></style>
