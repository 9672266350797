<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import InnerDashCard from "@/components/Cards/InnerDashCard";

import ProjectService from "@/services/ProjectService";
import DateRangeMixin from "@/mixins/DateRangeMixin";

export default {
    name: "VStats",
    components: { InnerDashCard, DateRangePicker },
    props: {
        project_id: {
            description: "Project ID",
        },
    },
    mixins: [DateRangeMixin],
    data() {
        return {
            loading: false,
            project: null,
            stats: null,
            dateRange: {
                startDate: null,
                endDate: null,
            },
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId"]),
        currentExportUrl: function () {
            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/projects/statistics/" +
                    this.project_id
            );
            url.searchParams.append(
                "dateRange",
                JSON.stringify(this.dateRange)
            );
            url.searchParams.append("csv", 1);

            return url.href;
        },
        hasData: function () {
            if (this.activityBreakdown.series.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        activityBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.activityBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.activity_name);
            });

            return data;
        },
        teamBreakdown: function () {
            let data = {
                series: [],
                labels: [],
            };

            if (this.stats === null) {
                return data;
            }

            this.stats.teamBreakdown.forEach((el) => {
                data.series.push(el.total_time_perc);
                data.labels.push(el.user_name);
            });

            return data;
        },
    },
    watch: {
        dateRange: function (n) {
            this.fetchData();
        },
    },
    async created() {
        await this.fetchProject(true);
        await this.fetchData();
    },
    methods: {
        resetRange() {
            this.dateRange = {
                startDate: null,
                endDate: null,
            };
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = {
                    dateRange: this.serializeDateRange(this.dateRange),
                };

                const r = await ProjectService.GET_statistics(
                    this.project_id,
                    params
                );
                this.stats = r.data.data;
            } catch (e) {
                console.log("fetchData exception", e);
            }
            this.loading = false;
        },
        async fetchProject(loading = false) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("Faker", e);
            }
            this.loading = loading;
        },
    },
};
</script>

<template>
    <div>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <div class="d-flex align-items-center flex-row mb-3">
                        <div class="v-stats-rp">
                            <date-range-picker
                                class="range-picker"
                                ref="picker"
                                opens="right"
                                :timePicker="false"
                                :showWeekNumbers="true"
                                :autoApply="true"
                                v-model="dateRange"
                                :linkedCalendars="true"
                                :locale-data="getDateRangePickerLocale()"
                                :ranges="getDateRangePickerRanges()"
                            >
                                <template #header>
                                    <manual-range
                                        :date-range-manual="dateRangeManual"
                                        :date-range-error="dateRangeError"
                                        @exec="execManualRange('dateRange')"
                                    />
                                </template>
                                <template
                                    v-slot:input="picker"
                                    style="min-width: 350px"
                                >
                                    <template v-if="picker.startDate">
                                        {{ $d(picker.startDate, "short") }}
                                        -
                                        {{ $d(picker.endDate, "short") }}
                                    </template>
                                </template>
                            </date-range-picker>
                        </div>
                        <div
                            class="text-muted small m-click m-link ml-3"
                            @click="resetRange"
                        >
                            <i class="far fa-sync"></i>
                            {{ $t("invoice_line.reset_range") }}
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="loading">
                    <v-loader :loading="true" />
                </div>

                <div class="col-12" v-else-if="!hasData">
                    <div class="alert alert-warning alert-outline">
                        {{ $t("inv_stats.no_data_found_try_different_period") }}
                    </div>
                </div>

                <div class="col-12" v-else>
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activity_distribution") }}
                        </h3>

                        <div class="px-4">
                            <!-- Stat for non-internal customers -->
                            <div
                                class="row v-hours-week__statboxes"
                                v-if="project.customer.internal_customer == 1"
                            >
                                <!-- Box -->
                                <pre v-if="false">{{ stats }}</pre>
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_time')
                                        "
                                        icon="far fa-clock"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .non_billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <div class="row v-hours-week__statboxes" v-else>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="$t('inv_stats.billable_time')"
                                        icon="far fa-stopwatch"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_time')
                                        "
                                        icon="far fa-ban"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                stats.billableStats
                                                    .non_billable_time | toTime
                                            }}</span></template
                                        >
                                        <template slot="action"
                                            >{{ $t("inv_stats.hours") }}
                                        </template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="$t('inv_stats.billable_amount')"
                                        icon="far fa-sack"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                $n(
                                                    stats.billableStats
                                                        .billable_amount,
                                                    {
                                                        style: "decimal",
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                )
                                            }}</span></template
                                        >
                                        <template slot="action">{{
                                            project.currency
                                        }}</template>
                                    </inner-dash-card>
                                </div>
                                <!-- Box -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <inner-dash-card
                                        :title="
                                            $t('inv_stats.non_billable_amount')
                                        "
                                        icon="far fa-ban"
                                        ><template slot="default"
                                            ><span class="text-default">{{
                                                $n(
                                                    stats.billableStats
                                                        .non_billable_amount,
                                                    {
                                                        style: "decimal",
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                )
                                            }}</span></template
                                        >
                                        <template slot="action">{{
                                            project.currency
                                        }}</template>
                                    </inner-dash-card>
                                </div>
                            </div>
                            <!-- End of boxes -->
                            <!-- Graph -->
                            <!-- Billable and non billable time (amount of hrs) stacked per day for a given period -->
                            <div class="row" v-if="true">
                                <div
                                    class="col-12 v-hours-week__graph"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                >
                                    <div style="display: block">
                                        <apexchart
                                            v-if="true"
                                            height="400px"
                                            :options="{
                                                chart: {
                                                    width: '100%',
                                                    type: 'bar',
                                                    stacked: true,
                                                },
                                                xaxis: {
                                                    type: 'datetime',
                                                    labels: {
                                                        format: 'dd.MM.yyyy',
                                                    },
                                                },
                                                tooltip: {
                                                    x: {
                                                        formatter: function (
                                                            timestamp
                                                        ) {
                                                            // Create a date object from the timestamp
                                                            const date =
                                                                new Date(
                                                                    timestamp
                                                                );

                                                            // First day of the year
                                                            const startOfYear =
                                                                new Date(
                                                                    date.getFullYear(),
                                                                    0,
                                                                    1
                                                                );

                                                            // Calculating the number of days between the start of the year and the date
                                                            const days =
                                                                Math.floor(
                                                                    (date -
                                                                        startOfYear) /
                                                                        (24 *
                                                                            60 *
                                                                            60 *
                                                                            1000)
                                                                );

                                                            // Calculating the week number: Adding 1 because the first week is 1, not 0
                                                            const weekNum =
                                                                Math.ceil(
                                                                    (date.getDay() +
                                                                        1 +
                                                                        days) /
                                                                        7
                                                                );

                                                            // Return the formatted string
                                                            return (
                                                                $t(
                                                                    'week_number'
                                                                ) +
                                                                ` ${
                                                                    weekNum < 10
                                                                        ? '0' +
                                                                          weekNum
                                                                        : weekNum
                                                                } ${date.getFullYear()}`
                                                            );
                                                        },
                                                    },
                                                },
                                            }"
                                            :series="[
                                                {
                                                    name: $t(
                                                        'inv_stats.billable_time'
                                                    ),
                                                    data: stats.graph.series
                                                        .billable_time,
                                                },
                                                {
                                                    name: $t(
                                                        'inv_stats.non_billable_time'
                                                    ),
                                                    data: stats.graph.series
                                                        .non_billable_time,
                                                },
                                            ]"
                                        ></apexchart>
                                        <pre v-if="false">{{
                                            stats.graph
                                        }}</pre>
                                    </div>
                                </div>
                                <div class="col-12 v-hours-week__graph" v-else>
                                    <div style="display: block">
                                        <apexchart
                                            v-if="true"
                                            height="400px"
                                            :options="{
                                                chart: {
                                                    width: '100%',
                                                    type: 'bar',
                                                    stacked: true,
                                                },
                                                xaxis: {
                                                    type: 'datetime',
                                                    labels: {
                                                        rotate: -45,
                                                        format: 'dd.MM.yyyy',
                                                    },
                                                },
                                                tooltip: {
                                                    x: {
                                                        formatter: function (
                                                            timestamp
                                                        ) {
                                                            // Create a date object from the timestamp
                                                            const date =
                                                                new Date(
                                                                    timestamp
                                                                );

                                                            // First day of the year
                                                            const startOfYear =
                                                                new Date(
                                                                    date.getFullYear(),
                                                                    0,
                                                                    1
                                                                );

                                                            // Calculating the number of days between the start of the year and the date
                                                            const days =
                                                                Math.floor(
                                                                    (date -
                                                                        startOfYear) /
                                                                        (24 *
                                                                            60 *
                                                                            60 *
                                                                            1000)
                                                                );

                                                            // Calculating the week number: Adding 1 because the first week is 1, not 0
                                                            const weekNum =
                                                                Math.ceil(
                                                                    (date.getDay() +
                                                                        1 +
                                                                        days) /
                                                                        7
                                                                );

                                                            // Return the formatted string
                                                            return (
                                                                $t(
                                                                    'week_number'
                                                                ) +
                                                                ` ${
                                                                    weekNum < 10
                                                                        ? '0' +
                                                                          weekNum
                                                                        : weekNum
                                                                } ${date.getFullYear()}`
                                                            );
                                                        },
                                                    },
                                                },
                                            }"
                                            :series="[
                                                {
                                                    name: $t(
                                                        'inv_stats.non_billable_time'
                                                    ),
                                                    data: stats.graph.series
                                                        .non_billable_time,
                                                },
                                            ]"
                                        ></apexchart>
                                        <pre v-if="false">{{
                                            stats.graph
                                        }}</pre>
                                    </div>
                                </div>
                            </div>
                            <!-- End of Graph -->
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->

            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.activity_distribution") }}
                        </h3>

                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: activityBreakdown.labels,

                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="activityBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4">
                            {{ $t("inv_stats.team_distribution") }}
                        </h3>
                        <div style="display: block; height: 350px">
                            <apexchart
                                height="100%"
                                :options="{
                                    chart: {
                                        type: 'donut',
                                    },
                                    labels: teamBreakdown.labels,
                                    legend: {
                                        position: 'right',
                                    },
                                    responsive: [
                                        {
                                            breakpoint: 480,
                                            options: {
                                                chart: {
                                                    width: '100%',
                                                },
                                                legend: {
                                                    position: 'bottom',
                                                },
                                            },
                                        },
                                    ],
                                }"
                                :series="teamBreakdown.series"
                            ></apexchart>
                        </div>
                    </card>
                </div>
            </div>
            <!-- New stat row -->
            <div class="row card-wrapper" v-if="hasData">
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4 d-flex align-items-center">
                            {{ $t("inv_stats.activities") }}

                            <a
                                :href="currentExportUrl"
                                class="btn btn-primary btn-sm ml-auto"
                                target="_blank"
                                >{{ $t("projects.download_as_csv") }}</a
                            >
                        </h3>

                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.activity") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>
                                    <v-th
                                        class="text-right"
                                        v-if="
                                            project.customer
                                                .internal_customer != 1
                                        "
                                        >{{ $t("inv_stats.amount") }}</v-th
                                    >
                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.activityBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.activity_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>
                                <v-td
                                    class="text-right"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                    >{{
                                        $n(u.total_amount, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td
                                >
                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.activityBreakdown }}</pre>
                    </card>
                </div>
                <div class="col-6">
                    <v-loader :loading="true" v-if="loading" />
                    <card body-classes="px-0" v-else>
                        <h3 class="px-4 mb-4 d-flex align-items-center">
                            {{ $t("inv_stats.team_members") }}

                            <a
                                :href="currentExportUrl"
                                class="btn btn-primary btn-sm ml-auto"
                                target="_blank"
                                >{{ $t("projects.download_as_csv") }}</a
                            >
                        </h3>
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("inv_stats.user") }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("inv_stats.time")
                                    }}</v-th>
                                    <v-th
                                        class="text-right"
                                        v-if="
                                            project.customer
                                                .internal_customer != 1
                                        "
                                        >{{ $t("inv_stats.amount") }}</v-th
                                    >
                                    <v-th class="text-right">%</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tr
                                v-for="(u, index) in stats.teamBreakdown"
                                :key="index"
                            >
                                <v-td>{{ u.user_name }}</v-td>
                                <v-td class="text-right">{{
                                    u.total_time | toTime
                                }}</v-td>
                                <v-td
                                    class="text-right"
                                    v-if="
                                        project.customer.internal_customer != 1
                                    "
                                    >{{
                                        $n(u.total_amount, {
                                            style: "currency",
                                            currency: project.currency,
                                            currencyDisplay: "code",
                                        })
                                    }}</v-td
                                >
                                <v-td class="text-right"
                                    >{{ $n(u.total_time_perc) }}%</v-td
                                >
                            </v-tr>
                            <v-tbody> </v-tbody>
                        </v-table>
                        <pre v-if="false">{{ stats.teamBreakdown }}</pre>
                    </card>
                </div>
            </div>
        </div>
        <!-- .container-fluid -->
    </div>
    <!-- .master div -->
</template>

<style lang="scss">
.v-stats-rp {
    flex: 0 0 300px;
}
</style>
