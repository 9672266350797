<script>
export default {
    name: "ShowChangesModal",
    props: {
        projectId: {},
        changes: {},
    },
    created() {},
    methods: {},
};
</script>

<template>
    <modal
        @close="$emit('close')"
        size="xxl"
        :show="true"
        class=""
        :loading="false"
    >
        <template slot="header">
            {{ $t("project_changes.header") }}
        </template>
        <template slot="default">

            <v-table class="table table-sm">
                <v-thead>
                    <v-tr>
                        <v-th>{{$t('project_changes.hdr_date_user')}}</v-th>
                        <v-th>{{$t('project_changes.hdr_change_type')}}</v-th>
                        <v-th>{{$t('project_changes.hdr_from_customer')}}</v-th>
                        <v-th>{{$t('project_changes.hdr_to_customer')}}</v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="change in changes" :key="change.id">
                        <v-td
                            >{{ $d(new Date(change.created), 'long') }}
                            <div class="small">{{ change.details.user }}</div>
                        </v-td>
                        <v-td>
                            <template v-if="change.details.canChange">
                                {{$t('project_changes.change_without_invoices')}}
                            </template>
                            <template v-else>
                                {{$t('project_changes.change_with_invoices')}}
                            </template>
                        </v-td>
                        <v-td>
                            {{change.from_customer.name}}<br />
                            {{$t('project_changes.customer_number')}}: {{change.from_customer.number}}<br />
                            <span v-if="change.from_customer.internal_customer">{{$t('project_changes.is_internal_customer')}} </span>
                        </v-td>
                        <v-td>
                            {{change.to_customer.name}}<br />
                            {{$t('project_changes.customer_number')}}: {{change.to_customer.number}}<br />
                            <span v-if="change.to_customer.internal_customer">{{$t('project_changes.is_internal_customer')}} </span>
                        </v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
        </template>
    </modal>
</template>

<style>
</style>
