
<script>
import Cloner from "@/libraries/Cloner";
import UserService from "@/services/UserService";
import TaskService from "@/services/TaskService";
import ReopenDayService from "@/services/ReopenDayService";

export default {
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        selectedDate: {},
    },
    data() {
        return {
            loading: false,
            saving: false,
            admins: [],
            dayMeta: null,
            tasks: [],
            reopenDay: {                
                admin_id: null,
                day_date: null,
                reason: "",
            },
            errors: null,
        };
    },
    computed: {
        canOpen: function () {
            if (this.tasks.length == 0) {
                return false;
            }

            let canOpen = true;

            this.tasks.forEach((t) => {
                if (t.is_invoiced) {
                    canOpen = false;
                }
            });

            return canOpen;
        },
        immutableTasks: function () {
            if (this.tasks.length == 0) {
                return [];
            }

            let filtered = [];

            this.tasks.forEach((t) => {
                if (t.is_invoiced) {
                    filtered.push(t);
                }
            });

            return filtered;
        },
    },
    async created() {
        this.loading = true;
        await this.fetchAdmins(true);
        await this.fetchTasks(this.selectedDate, true);
        this.reopenDay.day_date = this.selectedDate;
        this.loading = false;
    },
    methods: {
        async saveForm() {
            try {                
                this.saving = true;
                const r = await ReopenDayService.POST(this.reopenDay);
                this.$emit('save');
            } catch (e) {
                this.errors = e.response.data.errors; 
            }            

            this.saving = false;            
        },
        async fetchTasks(date, loading = false) {
            try {
                this.loading = true;
                const r = await TaskService.GET_dayStats(date);
                this.dayMeta = r.data.meta;
                this.tasks = r.data.data;
            } catch (e) {
                console.log("fetchDay exception", e);
            }

            this.loading = loading;
        },
        async fetchAdmins(loading = false) {
            try {
                this.loading = true;
                const r = await UserService.dropdownAdmins();
                this.admins = r.data.data;
            } catch (e) {
                console.log("fetchAdmins exception", e);
            }

            this.loading = loading;
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="project-modal"
    >
        <template slot="header">
            <template>
                {{
                    $t("reopen.create_reopen_request_for_D", {
                        D: selectedDate,
                    })
                }}
            </template>
        </template>
        <template slot="default" v-if="!loading">
            <template v-if="canOpen">
                <p class="">
                    {{ $t("reopen.intro_text") }}
                </p>

                <FormulateForm
                    v-model="reopenDay"
                    @submit="saveForm"
                    :errors="errors"
                    :debounce="100"
                >
                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                type="multiselect"
                                :options="admins"
                                name="admin_id"
                                :validation-name="$t('reopen.administrator')"
                                :label="$t('reopen.administrator')"
                                :help="$t('reopen.help_administrator')"
                                :placeholder="
                                    $t('reopen.placeholder_administrator')
                                "
                                validation="required"
                            />
                        </div>
                        <div class="col-12">
                            <FormulateInput
                                type="textarea"
                                name="reason"
                                :validation-name="$t('reopen.reason')"
                                :help="$t('reopen.help_reason')"
                                :label="$t('reopen.reason')"
                                validation="required"
                                :placeholder="$t('reopen.placeholder_reason')"
                            />
                        </div>
                    </div>

                    <div class="row" v-if="immutableTasks.length > 0">
                        <div class="col-12">
                            <h3>
                                {{ $t("reopen.header_tasks_cannot_reopen") }}
                            </h3>
                            <ul>
                                <li
                                    class="text-danger"
                                    v-for="it in immutableTasks"
                                    :key="it.id"
                                >
                                    {{ it.customer.name }}:
                                    {{ it.time_reg | toTime }}
                                    <div class="small">
                                        {{ it.activity.name }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="text-right mt-4">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="saving"
                            native-type="submit"
                            >{{ $t("reopen.send_request") }}</base-button
                        >
                    </div>
                </FormulateForm>
            </template>
            <template v-else>
                <div class="alert alert-danger alert-outline">
                    <i class="far fa-exclamation-triangle"></i>
                    {{
                        $t(
                            "reopen.error_the_day_cannot_be_open_only_invoiced_tasks"
                        )
                    }}
                </div>
                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                </div>
            </template>
        </template>
    </modal>
</template>
<style>
</style>